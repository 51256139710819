<ng-container *ngIf="package">
  <ng-container *ngIf="(konamiCode.unlock$ | async) && package.id">
    packageId: {{ package.id }}
  </ng-container>

  <ng-container [ngSwitch]="mode">
    <ng-container
      *ngSwitchCase="'READ'"
      [ngTemplateOutlet]="readMessageTemplate"
    >
    </ng-container>

    <ng-container
      *ngSwitchCase="'EDIT'"
      [ngTemplateOutlet]="editMessageTemplate"
    >
    </ng-container>
  </ng-container>

  <ng-template #readMessageTemplate>
    <div class="messages">
      <ng-container *ngFor="let message of package.messages; let index = index">
        <div class="message" [id]="'msg-id-' + message?.logId">
          <ms-factory
            [ngClass]="message?.direction"
            [matContextMenu]="messageContextMenu"
            [matContextMenuCustomPosition]="position"
            [matContextMenuDisable]="messageContextMenu?.items?.length === 0"
            (mouseenter)="onMouseEnter(message)"
            [message]="message"
            [package]="package"
            [mode]="mode"
          >
          </ms-factory>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #editMessageTemplate>
    <div
      class="messages"
      [matContextMenu]="msSubmenu.matMenu"
      [matContextMenuCustomPosition]="position"
      [matContextMenuDisable]="false"
      [matWithoutChildren]="true"
      cdkDropList
      cdkDropListOrientation="vertical"
      cdkDropListLockAxis="y"
      [cdkDropListDisabled]="false"
      (cdkDropListDropped)="messageDropped($event)"
    >
      <div
        class="message"
        *ngFor="let message of package.messages; let index = index"
        cdkDrag
        [cdkDragDisabled]="package.inTextEditMode"
        [matContextMenu]="messageContextMenu"
        [matContextMenuDisable]="false"
        (contextmenu)="selected = message"
      >
        <ms-factory
          [ngClass]="message?.direction"
          [message]="message"
          [package]="package"
          [mode]="mode"
        >
        </ms-factory>
        <mat-icon class="drag-indicator"> drag_indicator </mat-icon>
      </div>
    </div>
  </ng-template>

  <ms-quick-replies
    *ngIf="package.enableQuickReplies"
    [package]="package"
    [mode]="mode"
  ></ms-quick-replies>

  <div *ngIf="package.isLoading" class="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="menu">
    <mat-menu
      #messageContextMenu="matMenu"
      #menu="elementRef"
      element-ref
      class="tiny-menu"
      xPosition="before"
    >
      <ng-container *ngIf="mode === 'EDIT'">
        <button mat-menu-item (click)="delete()">
          <mat-icon>delete</mat-icon> 刪除
        </button>

        <button
          mat-menu-item
          (click)="swapUpward()"
          [disabled]="isFirstMessage"
        >
          <mat-icon>arrow_upward</mat-icon> 上移
        </button>

        <button
          mat-menu-item
          (click)="swapDownward()"
          [disabled]="isLatestMessage"
        >
          <mat-icon>arrow_downward</mat-icon> 下移
        </button>
      </ng-container>

      <ng-container *ngIf="mode === 'READ' && editable">
        <button
          *ngIf="
            package.enableEditText &&
            selected &&
            (selected.type === 'text' || selected.type === 'reply-text') &&
            selected.direction === 'Send'
          "
          mat-menu-item
          (click)="editMessage($event)"
        >
          <mat-icon>edit</mat-icon> 編輯
        </button>

        <button
          *ngIf="
            package.enableReplyText &&
            selected &&
            (selected.type === 'text' ||
              selected.type === 'edit-text' ||
              selected.type === 'reply-text')
          "
          mat-menu-item
          (click)="replyMessage($event)"
        >
          <mat-icon>reply</mat-icon> 回覆
        </button>
      </ng-container>
    </mat-menu>
  </div>

  <ms-submenu
    [package]="package"
    [toolbars]="package.toolbars"
    #msSubmenu="msSubmenu"
  >
  </ms-submenu>
</ng-container>
