import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { arrayToObject } from '@ay/util';
import { SubscriptionLike } from 'rxjs';
import { BotService } from '../service/bot.service';

type BotPipeParam =
  | number
  | { id: number }
  | { name: string }
  | { botId: number };

@Pipe({
    name: 'bot',
    pure: false,
    standalone: true,
})
export class BotPipe implements PipeTransform, OnDestroy {
  private _latestArg: BotPipeParam[] = null;

  private _latestValue: any = null;
  private _latestReturnedValue: any = null;
  private _subscription: SubscriptionLike;
  private _length: number;

  public constructor(
    private _botService: BotService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnDestroy(): void {
    this._dispose();
  }

  public transform(
    arg: BotPipeParam | BotPipeParam[],
    length: number = Infinity,
  ) {
    this._length = length;
    if (!this._latestArg) {
      if (!(arg instanceof Array)) {
        arg = [arg];
      }
      this._latestArg = arg;
      this._subscribe(arg);
      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (arg.toString() != this._latestArg.toString()) {
      this._dispose();
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  private _subscribe(args: BotPipeParam[]) {
    try {
      const withoutName = args.filter((arg) => !arg['name']);

      if (withoutName.length === 0) {
        return this._updateLatestValue(args.map((arg) => arg['name']));
      }

      const ids = withoutName.map((arg) => {
        if (typeof arg === 'number') return arg;
        else if (arg['id']) return arg['id'];
        else if (arg['botId']) return arg['botId'];
      });

      this._subscription = this._botService
        .getAllByIds(ids)
        .subscribe((bots) => {
          const grouped = arrayToObject(bots, 'id');

          const names = withoutName.map((arg) => {
            if (typeof arg === 'number') return grouped[arg].name;
            else if (arg['name']) return arg['name'];
            else if (arg['id']) return grouped[arg['id']].name;
            else if (arg['botId']) return grouped[arg['botId']].name;
          });

          this._updateLatestValue(names);
        });
    } catch (error) {
      console.error(error);
    }
  }

  private _dispose(): any {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
    this._latestValue = null;
    this._latestReturnedValue = null;
    this._latestArg = null;
  }

  private _updateLatestValue(names: string[]) {
    let _latestValue = '';
    let remaining = $localize`⋯⋯，共${names.length}隻機器人`;

    for (let idx = 0; idx < names.length; idx++) {
      let name = names[idx];

      let needLength = this._length;
      if (idx != names.length - 1) {
        needLength -= remaining.length;
      }

      let afterLength = (_latestValue + name).length;

      if (afterLength > needLength) {
        if (_latestValue === '') {
          _latestValue = $localize`共${names.length}隻機器人`;
        } else {
          _latestValue += remaining;
        }
        break;
      }

      if (_latestValue === '') {
        _latestValue = name;
      } else {
        _latestValue += '、' + name;
      }
    }
    this._latestValue = _latestValue;

    this._changeDetectorRef.markForCheck();
  }
}
