import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'length',
    pure: true,
    standalone: true,
})
export class LengthPipe implements PipeTransform {
  public transform(text: string, length: number): any {
    if ((text + '').length > length) {
      return text.slice(0, length - 3) + '...';
    }
    return text;
  }
}
