import { Injectable } from '@angular/core';
import { MatConnectedDialogConfig } from '@ay-gosu/ui/common/connected-dialog';
import { ExposedPromise } from '@ay/util';
import extend from 'lodash/extend';
import { DialogService } from '../dialog.service';
import { ImagePickerDialog } from './picker/picker.dialog';
import { ImageUploaderDialog } from './uploader/uploader.dialog';

@Injectable({
  providedIn: 'root',
})
export class ImageDialog extends DialogService {
  public async picker(url: string = '', useCode: boolean = false) {
    const exposedPromise = new ExposedPromise();

    this._open(
      ImagePickerDialog,
      {
        data: { url: '', useCode: true },
      },
      exposedPromise,
    );

    return exposedPromise.promise;
  }

  public async uploader(file: File, config: MatConnectedDialogConfig = {}) {
    const exposedPromise = new ExposedPromise();

    this._open(
      ImageUploaderDialog,
      extend({ disableClose: true, data: { file } }, config),
      exposedPromise,
    );

    return exposedPromise.promise;
  }
}
