import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import unescape from 'lodash/unescape';
import { EscapePipe } from '../../pipe/escape.pipe';
import { Nl2brPipe } from '../../pipe/nl2br.pipe';
import { BaseComponent } from '../base/base.component';
import { ReplyTextMessage } from './reply-text.message';

@Component({
  selector: 'ms-reply-text',
  templateUrl: './reply-text.component.html',
  styleUrls: ['./reply-text.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, EscapePipe, Nl2brPipe, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package', 'mode', 'message'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.margin-right.px]': "mode == 'EDIT' ? 24 : 0",
  },
})
export class ReplyTextComponent extends BaseComponent<ReplyTextMessage> {
  @ViewChild('textarea')
  public textarea: ElementRef<HTMLDivElement>;

  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public clickReply() {
    const repliedLogId = this.message.content.repliedLogId;
    const replyElement = document.getElementById(
      'msg-id-' + repliedLogId.toString(),
    );
    if (replyElement) {
      replyElement.scrollIntoView();
      replyElement.classList.add('shake');
      replyElement.addEventListener('animationend', function () {
        replyElement.classList.remove('shake');
      });
    }
  }

  public mousedown($event: MouseEvent) {
    this.package.inTextEditMode = true;
  }

  public blur($event: Event) {
    let content = this.message.content;
    content.content = unescape(this.textarea.nativeElement.innerHTML);
    this.package.inTextEditMode = false;
    this.changeDetectorRef.markForCheck();
  }

  public stop($event: MouseEvent) {
    $event.stopPropagation();
  }
}
