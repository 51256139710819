import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { InstagramPost } from '@ay/bot';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { debounceTime, map, mergeMap, shareReplay } from 'rxjs/operators';
import { LegacyAppearanceDirective } from '../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { getDutchPaginatorIntl } from '../../paginator';
import { FacebookHashTagHtmlPipe } from '../../pipe/facebook-hash-tag-html.pipe';
import { MomentPipe } from '../../pipe/moment.pipe';
import { WrapHtmlPipe } from '../../pipe/wrap-html.pipe';
import { BotService } from '../../service/bot.service';
import { InstagramPostService } from '../../service/instagram-post.service';

export interface InstagramPostSelectorData {
  filterBotIds: number[];
  postIds: string[];
}

@Component({
  selector: 'dl-instagram-post-selector-dialog',
  templateUrl: './instagram-post-selector.dialog.html',
  styleUrls: ['./instagram-post-selector.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    NgIf,
    MatButton,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatTabGroup,
    MatTab,
    MatTabLabel,
    NgTemplateOutlet,
    MatPaginator,
    MatTooltip,
    NgFor,
    MatDivider,
    AsyncPipe,
    FacebookHashTagHtmlPipe,
    MomentPipe,
    WrapHtmlPipe,
    LegacyAppearanceDirective,
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }],
})
export class InstagramPostSelectorDialog {
  public selectedTabIndex: number;
  public isUpdated = false;

  private _selectedPostIdsChange$ = new BehaviorSubject<any>(null);

  private _searchKeyChange$ = new BehaviorSubject<string>('');

  private _updated$ = new BehaviorSubject<void>(null);

  public get searchKey(): string {
    return this._searchKeyChange$.getValue();
  }

  public set searchKey(key: string) {
    if (typeof key === 'string') this._searchKeyChange$.next(key);
  }

  private _publicPageIndexChange$ = new BehaviorSubject<number>(0);

  public get publicPageIndex(): number {
    return this._publicPageIndexChange$.getValue();
  }

  public set publicPageIndex(val: number) {
    if (typeof val === 'number') this._publicPageIndexChange$.next(val);
  }

  public publicList$ = combineLatest([
    this._publicPageIndexChange$,
    this._searchKeyChange$,
    this._updated$,
  ]).pipe(
    debounceTime(333),
    mergeMap(async (e) =>
      this._instagramPostService.loadPostList(
        this._data.filterBotIds,
        this.searchKey,
        this.publicPageIndex,
      ),
    ),
    shareReplay(1),
  );

  public publicPostCount$ = this.publicList$.pipe(
    map((res) => {
      let maxPageIndex = Math.ceil(res.count / res.limit);
      if (res.page > maxPageIndex) {
        this.publicPageIndex = 0;
      }
      return res.count;
    }),
    shareReplay(1),
  );

  public publicPosts$ = this.publicList$.pipe(
    map((res) => {
      if (!res) return [];
      return res.data || [];
    }),
    shareReplay(1),
  );

  public selectedPosts$ = combineLatest(
    this._selectedPostIdsChange$,
    this._searchKeyChange$,
    this._instagramPostService.posts$,
  ).pipe(
    debounceTime(333),
    mergeMap(async (e) =>
      this._instagramPostService.loadPostByIds(this._data.postIds),
    ),
    map((list) => {
      if (!this.searchKey) return list;
      return list.filter((post) => {
        let values = ['postId', 'message'].map((key) => post[key] || '');
        for (let val of values) {
          if (typeof val === 'string' && val.includes(this.searchKey))
            return true;
        }
        return false;
      });
    }),
    shareReplay(1),
  );

  public selectedPostCount$ = this.selectedPosts$.pipe(
    map((r) => r.length),
    shareReplay(1),
  );

  public constructor(
    private _dialogRef: MatDialogRef<InstagramPostSelectorDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: InstagramPostSelectorData,
    private _instagramPostService: InstagramPostService,
    private _botService: BotService,
    private _matSnackBar: MatSnackBar,
  ) {}

  public botsName$ = this._botService.all$.pipe(
    map((bots) => {
      let { filterBotIds: botIds } = this._data;
      let hasFilterBotIds = botIds instanceof Array && botIds.length > 0;
      return bots
        .filter((bot) => {
          if (hasFilterBotIds && !botIds.includes(bot.id)) return false;
          return bot.type == 'Instagram';
        })
        .map((bot) => bot.name)
        .join(', ');
    }),
  );

  public close() {
    this._dialogRef.close(this._data);
  }

  public selectPost(post: InstagramPost) {
    let idx = this._data.postIds.indexOf(post.postId);
    if (idx == -1) {
      this._data.postIds.push(post.postId);
    } else {
      this._data.postIds.splice(idx, 1);
    }
    this._selectedPostIdsChange$.next(null);
  }

  public isSelected(post: InstagramPost) {
    return this._data.postIds.includes(post.postId);
  }

  public openPostTab(post: InstagramPost, $event: MouseEvent) {
    window.open(`${post.link}`, '_blank');
    $event.stopPropagation();
    return false;
  }

  public stopClickEvent($event: MouseEvent) {
    $event.stopPropagation();
    return false;
  }

  public openPostLink(post: InstagramPost, $event: MouseEvent) {
    window.open(post.link, '_blank');
    $event.stopPropagation();
    return false;
  }

  public async updateList() {
    this.isUpdated = true;
    this._matSnackBar.open($localize`貼文更新中`, '', { duration: 2000 });
    await firstValueFrom(
      this._botService.all$.pipe(
        map((bots) =>
          bots
            .filter((bot) => !bot.disabled && bot.type === 'Instagram')
            .map((bot) => bot.id),
        ),
        mergeMap((botIds) =>
          this._instagramPostService.syncInstagramPost(botIds),
        ),
      ),
    );
    this._updated$.next(null);
    this._matSnackBar.open($localize`貼文更新完成`, '', { duration: 5000 });
  }

  public noPostInfos = [
    [
      $localize`沒有符合已選貼文，請更換篩選關鍵字`,
      $localize`尚未選取任何貼文`,
    ],
    [
      $localize`沒有符合的公開貼文，請更換篩選關鍵字`,
      $localize`沒有任何公開貼文，請嘗試 [更新貼文]`,
    ],
  ];
}
