import { Component, Input, SkipSelf, ViewChild } from '@angular/core';
import { Color } from '@ay/util';
import { JunctionComponent } from '../../junction/junction.component';
import { NodeComponent } from '../node.component';

@Component({
  selector: ':svg:g.condition',
  templateUrl: './condition.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.transform]': 'transform',
  },
  standalone: true,
  imports: [JunctionComponent],
})
export class ConditionComponent {
  @ViewChild(JunctionComponent)
  public junction: JunctionComponent = null;

  private _index: number = 0;

  public em = 16;

  @Input()
  public set index(index: number) {
    this.y = index;
    this._index = index;
  }

  public get index(): number {
    return this._index;
  }

  @Input()
  public set y(y: number) {
    this.transform = `translate(${this.em}, ${y * 2.5 * this.em})`;
  }

  @Input()
  public text: string = '';

  @Input()
  public dash: boolean = false;

  private _color: string;

  @Input()
  public set color(color: string) {
    this._color = color;

    let rgba = Color.hexToRgba(this.color);
    const deep = Color.adjustColorLevel(this.color, -8);
    const light = Color.adjustColorLevel(this.color, 3);
    const lighter = Color.adjustColorLevel(this.color, 8);

    if (!Color.isLight(rgba)) {
      this.frontColor = deep;
      this.borderColor = light;
    } else {
      this.frontColor = lighter;
      this.borderColor = light;
    }
  }

  public get color(): string {
    return this._color;
  }

  public transform: string = '';

  public frontColor: string = '#ffffff';

  public borderColor: string = '#ffffff';

  public constructor(@SkipSelf() public nodeComponent: NodeComponent) {
    this.color = '#EEEEEE';
  }
}
