import Debug from 'debug';

const debug = Debug('time-tracker');

export class Job {
  public readonly startAt: number = Date.now();
  public endAt: number = null;
  public spend?: number = null;

  public constructor(
    public readonly name: string,
    public readonly enable = true,
    public readonly threshold = 100,
  ) {}

  public toJSON(): any {
    if (!this.enable) return undefined;
    return {
      name: this.name,
      spend: this.spend,
    };
  }

  public toString(prefix = '', isEnd = false) {
    if (!this.enable) return '';
    const char = isEnd ? '└ ' : '├ ';
    prefix += char;

    return `${prefix}${this.name} : ${(this.spend / 1000).toFixed(2)}s`;
  }

  public end(now = Date.now()) {
    if (!this.enable) return;
    this.endAt = now;
    this.spend = this.endAt - this.startAt;
    debug(`[TimeTracker] ${this.name} end ${this.spend} ms`);
  }
}

export class TimeTracker extends Job {
  public override endAt: number = null;
  public override spend: number = null;

  protected _previousStep: string;
  protected _jobs: Job[] = [];
  protected _isCompleted = false;

  public constructor(
    public override readonly name: string,
    public override enable = true,
    public override threshold = 100,
  ) {
    super(name, enable, threshold);
  }

  public step(jobName: string) {
    if (!this.enable) {
      return () => {};
    }

    if (this._previousStep) {
      this.endJob(this._previousStep);
    }
    this._previousStep = jobName;
    return this.startJob(jobName);
  }

  public deep(jobName: string): TimeTracker | undefined {
    if (!this.enable) {
      return this;
    }

    if (this._previousStep) {
      this.endJob(this._previousStep);
    }

    const exist = this._jobs.find(
      (job) => job.name === jobName && job instanceof TimeTracker,
    ) as TimeTracker;
    if (exist) {
      console.error(`任務 ${jobName} 已經存在`);
      return exist;
    }

    const timeTracker = new TimeTracker(jobName, this.enable, this.threshold);
    this._jobs.push(timeTracker);
    return timeTracker;
  }

  public startJob(jobName: string) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!this.enable) {
      return () => {};
    }

    if (this._jobs.find((job) => job.name === jobName)) {
      console.error(`任務 ${jobName} 已經存在`);
      return () => {};
    }

    this._jobs.push(new Job(jobName, this.enable, this.threshold));

    return () => {
      this.endJob(jobName);
    };
  }

  public endJob(jobName: string) {
    if (!this.enable) return;
    const job = this._jobs.find((job) => job.name === jobName);

    if (!job) {
      console.error(`任務 ${jobName} 不存在`);
      return;
    }

    if (job.endAt !== null) {
      console.error(`任務 ${jobName} 已經結束`);
      return;
    }

    job.end();

    if (jobName === this._previousStep) {
      this._previousStep = null;
    }
  }

  public complete(now: number = Date.now()) {
    if (!this.enable) return;
    if (this._isCompleted) return;
    this._jobs.map((job) => {
      if (job.endAt === null) {
        job.end(now);
      }
      if (job instanceof TimeTracker) {
        job.complete(now);
      }
    });

    super.end(now);
    this._isCompleted = true;
  }

  public override toJSON() {
    if (!this.enable) return undefined;
    return {
      name: this.name,
      jobs: this._jobs.map((job) => job.toJSON()),
      spend: this.spend,
    };
  }

  public override toString(prefix = '', isEnd = false) {
    if (!this.enable) return '';
    const char = isEnd ? '└ ' : '├ ';

    const jobs = this._jobs.filter((job) => job.spend >= this.threshold);

    return [
      `${prefix ? prefix + char : ''}${this.name} : ${(
        this.spend / 1000
      ).toFixed(2)}s`,
      ...jobs.map((job, index) =>
        job.toString(
          prefix + (prefix ? (isEnd ? '  ' : '│ ') : '  '),
          index === jobs.length - 1,
        ),
      ),
    ].join('\n');
  }

  public get isCompleted() {
    return this._isCompleted;
  }

  public static createOrDeep(tracker: TimeTracker | undefined, name: string) {
    if (tracker) {
      return tracker.deep(name);
    }

    return new TimeTracker(name);
  }
}
