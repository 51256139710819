export namespace Flex {
  export class Message {
    public type = 'flex' as const;

    // Max character limit: 400
    public altText: string;

    public contents: Content.Bubble | Content.Carousel;
  }

  export namespace Content {
    export abstract class Content {}

    export class Bubble extends Content {
      public type = 'bubble' as const;

      // The size of the bubble. You can specify one of the following values: nano, micro, kilo, mega, or giga. The default value is mega.
      public size?: BubbleSize = 'mega';

      // The default value is ltr.
      public direction?: 'ltr' | 'rtl' = 'ltr';

      public header?: Block.Box;

      public hero?: Block.Box | Block.Image | Block.Video;

      public body?: Block.Box;

      public footer?: Block.Box;

      public styles?: Style.Bubble;

      // LINE for iOS and Android: 8.11.0 and later
      public action?: ActionObject.Any;
    }

    export class Carousel extends Content {
      public type = 'carousel' as const;

      // Max: 10 bubbles
      public contents: Bubble[];
    }
  }

  export namespace Block {
    export abstract class Base {}

    export class Box extends Base {
      public type = 'box' as const;

      public layout: BoxLayout;

      public contents: (
        | Box
        | Button
        | Image
        | Icon
        | Text
        | Separator
        | Filler
        | Spacer
        | Span
      )[];

      public backgroundColor?: string = '#00000000';

      public borderColor?: string;

      public borderWidth?: BorderWidth | string;

      public cornerRadius?: CornerRadius = 'none';

      public width?: string;

      public height?: string;

      public flex?: number;

      public spacing?: Spacing = 'none';

      public margin?: Margin = 'none';

      public paddingAll?: string;

      public paddingTop?: string;

      public paddingBottom?: string;

      public paddingStart?: string;

      public paddingEnd?: string;

      public position?: Position = 'relative';

      public offsetTop?: string;

      public offsetBottom?: string;

      public offsetStart?: string;

      public offsetEnd?: string;

      // LINE for iOS and Android: 8.11.0 and later
      public action?: ActionObject.Any;

      public justifyContent?: JustifyContent;

      public alignItems?: AlignItems;

      public background?: BackgroundObject.Base;
    }

    export class Button extends Base {
      public type = 'button' as const;

      public action: ActionObject.Any;

      public flex?: number;

      public margin?: string = 'none';

      public position?: Position = 'relative';

      public offsetTop?: string;

      public offsetBottom?: string;

      public offsetStart?: string;

      public offsetEnd?: string;

      public height?: ButtonHeight = 'md';

      public style?: ButtonStyle = 'link';

      public color?: string;

      public gravity?: Gravity = 'top';

      public adjustMode?: AdjustMode;
    }

    export class Image extends Base {
      public type = 'image' as const;

      // Protocol: HTTPS over TLS 1.2 or later
      // Image format: JPEG or PNG
      // Maximum image size: 1024×1024 pixels
      // Maximum data size: 1 MB
      public url: string;

      public flex?: number;

      public margin?: Margin = 'none';

      public position?: Position = 'relative';

      public offsetTop?: string;

      public offsetBottom?: string;

      public offsetStart?: string;

      public offsetEnd?: string;

      public align?: Align = 'center';

      public gravity?: Gravity = 'top';

      public size?: ImageSize = 'md';

      public aspectRatio?: string = '1:1';

      public aspectMode?: AspectMode = 'fit';

      public backgroundColor?: string;

      public animated?: boolean;

      public action?: ActionObject.Any;
    }

    export class Video extends Base {
      public type = 'video' as const;

      public url: string;

      public previewUrl: string;

      public altContent: {
        type: string;
        size: string;
        aspectRatio: string;
        aspectMode: string;
        url: string;
      };

      public aspectRatio?: string = '1:1';

      public action: ActionObject.Any;
    }

    export class Icon extends Base {
      public type = 'icon' as const;

      // Protocol: HTTPS over TLS 1.2 or later
      // Image format: JPEG or PNG
      // Maximum image size: 1024×1024 pixels
      // Maximum data size: 1 MB
      public url: string;

      public margin?: Margin = 'none';

      public position?: Position = 'relative';

      public offsetTop?: string;

      public offsetBottom?: string;

      public offsetStart?: string;

      public offsetEnd?: string;

      public size?: IconSize = 'md';

      public aspectRatio?: string;
    }

    export class Text extends Base {
      public type = 'text' as const;

      public text: string;

      public contents?: Span[];

      public flex?: number;

      public margin?: Margin;

      public position?: Position;

      public offsetTop?: string;

      public offsetBottom?: string;

      public offsetStart?: string;

      public offsetEnd?: string;

      public size?: TextSize = 'md';

      public align?: Align = 'center';

      public gravity?: Gravity = 'top';

      public wrap?: boolean = false;

      // LINE for iOS and Android: 8.11.0 and later
      public maxLines?: number;

      public weight?: TextWeight = 'regular';

      public color?: string;

      public action?: ActionObject.Any;

      public style?: TextStyle = 'normal';

      public decoration?: TextDecoration = 'none';

      public adjustMode?: AdjustMode;
    }

    export class Separator extends Base {
      public type = 'separator' as const;
      public margin?: Margin = 'md';
      public color?: string;
    }

    export class Filler extends Base {
      public type = 'filler' as const;

      public flex?: number;
    }

    export class Spacer extends Base {
      public type = 'spacer' as const;

      public size: SpacerSize = 'md';
    }

    export class Span extends Base {
      public type = 'span' as const;

      public text: string;

      public color?: string;

      public size?: TextSize = 'md';

      public weight?: TextWeight = 'regular';

      public style?: TextStyle = 'normal';

      public decoration?: TextDecoration = 'none';
    }
  }

  export namespace BackgroundObject {
    export abstract class Base {}

    export class LinearGradient extends Base {
      public type: 'linearGradient';
      public angle: string;
      public startColor: string;
      public endColor: string;
      public centerColor: string;
      public centerPosition: string;
    }
  }

  export namespace ActionObject {
    export type Any =
      | Message
      | URI
      | DatetimePicker
      | Camera
      | CameraRoll
      | Location
      | AddThroughPromotion
      | ShareMessageThroughPromotion;

    export abstract class Base {
      // Max character limit: 20
      // is optional on header block
      public label?: string;
    }

    export class Postback extends Base {
      public type = 'postback' as const;

      // Max character limit: 300
      public data?: string;

      // Max character limit: 300
      public displayText?: string;
    }

    export class Message extends Base {
      public type = 'message' as const;

      // Max character limit: 300
      public text: string;
    }

    export class URI extends Base {
      public type = 'uri' as const;

      // Max character limit: 1000
      // The available schemes are http, https, line, and tel
      public uri: string;

      public altUri?: {
        // LINE 5.12.0 or later for macOS and Windows
        desktop?: string;
      };
    }

    export class DatetimePicker extends Base {
      public type = 'datetimepicker' as const;

      public data: string;

      // date: YYYY-MM-DD
      // time: HH:mm
      // datetime: YYYY-MM-DDTHH:mm
      public mode: DatetimePickerMode;

      public initial?: string;

      public max?: string;

      public min?: string;
    }

    export class Camera extends Base {
      public type = 'camera' as const;

      public label: string;
    }
    export class CameraRoll extends Base {
      public type = 'cameraRoll' as const;
    }

    export class Location extends Base {
      public type = 'location' as const;
    }

    export class AddThroughPromotion extends Base {
      public type = 'add-through-promotion' as const;
    }

    export class ShareMessageThroughPromotion extends Base {
      public type = 'share-message-through-promotion' as const;

      public promotionId: number;
    }
  }

  export namespace Style {
    export class Bubble {
      public header?: Block;

      public hero?: Block;

      public body?: Block;

      public footer?: Block;
    }

    export class Block {
      public backgroundColor?: string;

      // true to place a separator above the block. The default value is false.
      public separator?: boolean = false;

      public separatorColor?: string;
    }
  }

  export type DatetimePickerMode = 'date' | 'time' | 'datetime';

  export type AspectMode = 'cover' | 'fit';

  export type ButtonHeight = 'sm' | 'md';

  export type TextStyle = 'normal' | 'italic';

  export type TextDecoration = 'none' | 'underline' | 'line-through';

  export type TextWeight = 'regular' | 'bold';

  export type ImageSize =
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | '3xl'
    | '4xl'
    | '5xl'
    | 'full';

  export type IconSize =
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | '3xl'
    | '4xl'
    | '5xl';

  export type TextSize =
    | 'xxs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | '3xl'
    | '4xl'
    | '5xl';

  export type BubbleSize = 'nano' | 'micro' | 'kilo' | 'mega' | 'giga';

  export type SpacerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  export type Align = 'start' | 'end' | 'center';

  export type Gravity = 'top' | 'bottom' | 'center';

  export type ButtonStyle = 'primary' | 'secondary' | 'link';

  export type BoxLayout = 'horizontal' | 'vertical' | 'baseline';

  export type BorderWidth =
    | 'none'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semi-bold'
    | 'bold'
    | 'none';

  export type CornerRadius = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  export type Spacing = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  export type Margin = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  export type Padding = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  export type Offset = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

  export type Position = 'relative' | 'absolute';

  export type JustifyContent =
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';

  export type AlignItems = 'center' | 'flex-start' | 'flex-end';

  export type AdjustMode = 'shrink-to-fit';
}
