import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { InlineSVGComponent } from './inline-svg.component';
import { InlineSVGConfig, SVGScriptEvalMode } from './inline-svg.config';
import { InlineSVGService } from './inline-svg.service';
import { SVGCacheService } from './svg-cache.service';
import * as SvgUtil from './svg-util';

@Directive({
    selector: '[inlineSVG]',
    providers: [SVGCacheService],
    standalone: true,
})
export class InlineSVGDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  public inlineSVG: string;

  @Input()
  public resolveSVGUrl: boolean = true;

  @Input()
  public replaceContents: boolean = true;

  @Input()
  public prepend: boolean = false;

  @Input()
  public injectComponent: boolean = false;

  @Input()
  public cacheSVG: boolean = true;

  @Input()
  public setSVGAttributes: { [key: string]: any };

  @Input()
  public removeSVGAttributes: Array<string>;

  @Input()
  public forceEvalStyles: boolean = false;

  @Input()
  public evalScripts: SVGScriptEvalMode = SVGScriptEvalMode.ALWAYS;

  @Input()
  public fallbackImgUrl: string;

  @Input()
  public fallbackSVG: string;

  @Input()
  public onSVGLoaded: (svg: SVGElement, parent: Element | null) => SVGElement;

  @Output()
  public svgInserted: EventEmitter<SVGElement> = new EventEmitter<SVGElement>();

  @Output()
  public svgFailed: EventEmitter<any> = new EventEmitter<any>();

  /** @internal */
  public _prevSVG: SVGElement;

  private _supportsSVG: boolean;
  private _prevUrl: string;
  private _svgComp: ComponentRef<InlineSVGComponent>;

  private _subscription: Subscription;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _svgCacheService: SVGCacheService,
    private _renderer2: Renderer2,
    private _inlineSVGService: InlineSVGService,
    @Optional()
    private _inlineSvgConfig: InlineSVGConfig,
    @Inject(PLATFORM_ID)
    private platformId: Object,
  ) {
    this._supportsSVG = SvgUtil.isSvgSupported();

    // Check if the browser supports embed SVGs
    if (!isPlatformServer(this.platformId) && !this._supportsSVG) {
      this._fail('Embed SVG are not supported by this browser');
    }
  }

  public ngOnInit(): void {
    if (!this._isValidPlatform() || this._isSSRDisabled()) {
      return;
    }

    this._insertSVG();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this._isValidPlatform() || this._isSSRDisabled()) {
      return;
    }

    const setSVGAttributesChanged = Boolean(changes['setSVGAttributes']);
    if (changes['inlineSVG'] || setSVGAttributesChanged) {
      this._insertSVG(setSVGAttributesChanged);
    }
  }

  public ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }

    this._destroy$.next();
    this._destroy$.complete();
  }

  private _insertSVG(force = false): void {
    if (!isPlatformServer(this.platformId) && !this._supportsSVG) {
      return;
    }

    // Check if a URL was actually passed into the directive
    if (!this.inlineSVG) {
      this._fail('No URL passed to [inlineSVG]');
      return;
    }

    // Short circuit if SVG URL hasn't changed
    if (!force && this.inlineSVG === this._prevUrl) {
      return;
    }
    this._prevUrl = this.inlineSVG;

    this._subscription = this._svgCacheService
      .getSVG(this.inlineSVG, this.resolveSVGUrl, this.cacheSVG)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (svg: SVGElement) => {
          if (SvgUtil.isUrlSymbol(this.inlineSVG)) {
            const symbolId = this.inlineSVG.split('#')[1];
            svg = SvgUtil.createSymbolSvg(this._renderer2, svg, symbolId);
          }

          this._processSvg(svg);
        },
        error: (err: any) => {
          this._fail(err);
        },
      });
  }

  /**
   * The actual processing (manipulation, lifecycle, etc.) and displaying of the
   * SVG.
   *
   * @param svg The SVG to display within the directive element.
   */
  private _processSvg(svg: SVGElement) {
    if (!svg) {
      return;
    }

    if (this.removeSVGAttributes && isPlatformBrowser(this.platformId)) {
      SvgUtil.removeAttributes(svg, this.removeSVGAttributes);
    }

    if (this.setSVGAttributes) {
      SvgUtil.setAttributes(svg, this.setSVGAttributes);
    }

    if (this.onSVGLoaded) {
      svg = this.onSVGLoaded(svg, this._elementRef.nativeElement);
    }

    this._insertEl(svg);

    if (isPlatformBrowser(this.platformId)) {
      this._inlineSVGService.evalScripts(svg, this.inlineSVG, this.evalScripts);
    }

    // Force evaluation of <style> tags since IE doesn't do it.
    // See https://github.com/arkon/ng-inline-svg/issues/17
    if (this.forceEvalStyles) {
      const styleTags = svg.querySelectorAll('style');
      Array.from(styleTags).forEach((tag) => (tag.textContent += ''));
    }

    this.svgInserted.emit(svg);
  }

  /**
   * Handles the insertion of the directive contents, which could be an SVG
   * element or a component.
   *
   * @param el The element to put within the directive.
   */
  private _insertEl(el: HTMLElement | SVGElement): void {
    if (this.injectComponent) {
      if (!this._svgComp) {
        const factory =
          this._componentFactoryResolver.resolveComponentFactory(
            InlineSVGComponent,
          );
        this._svgComp = this._viewContainerRef.createComponent(factory);
      }

      this._svgComp.instance.context = this;
      this._svgComp.instance.replaceContents = this.replaceContents;
      this._svgComp.instance.prepend = this.prepend;
      this._svgComp.instance.content = el;

      // Force element to be inside the directive element inside of adjacent
      this._renderer2.appendChild(
        this._elementRef.nativeElement,
        this._svgComp.injector.get(InlineSVGComponent)._elementRef
          .nativeElement,
      );
    } else {
      this._inlineSVGService.insertEl(
        this,
        this._elementRef.nativeElement,
        el,
        this.replaceContents,
        this.prepend,
      );
    }
  }

  private _fail(msg: string): void {
    this.svgFailed.emit(msg);

    // Insert fallback image, if specified
    if (this.fallbackImgUrl) {
      const elImg = this._renderer2.createElement('IMG');
      this._renderer2.setAttribute(elImg, 'src', this.fallbackImgUrl);

      this._insertEl(elImg);
    } else if (this.fallbackSVG && this.fallbackSVG !== this.inlineSVG) {
      this.inlineSVG = this.fallbackSVG;
      this._insertSVG();
    }
  }

  private _isValidPlatform(): boolean {
    return (
      isPlatformServer(this.platformId) || isPlatformBrowser(this.platformId)
    );
  }

  private _isSSRDisabled(): boolean {
    return (
      isPlatformServer(this.platformId) &&
      this._inlineSvgConfig &&
      this._inlineSvgConfig.clientOnly
    );
  }
}
