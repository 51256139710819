import { RuleOp } from '@ay-gosu/types';
import { FromJsonOption, Rule } from '../rule';
import { ruleJoin } from '../ruleJoin';

export class NameRule extends Rule<string[]> {
  public op = 'INCLUDE' as RuleOp;

  public constructor(name: string) {
    super('NAME');
  }

  public afterTypeChanged(isUserEvent?: boolean) {}

  public similar(keyword: string): boolean {
    return true;
  }

  public toRuleObject(json: any = {}) {
    super.toRuleObject(json);
    json.value = this.value;
    json.op = 'INCLUDE';
    return json;
  }

  public async fromJSON(json: any, option: FromJsonOption) {
    await super.fromJSON(json, option);
    this.value = json.value;
    this.op = 'INCLUDE';
  }

  public toString() {
    return $localize`名字是${ruleJoin(this.value)}`;
  }

  public static schema: string = 'name';

  public toShortCode(): string {
    return `${this.value.join(',')}`;
  }

  public static async fromShortCode(code: string, option: FromJsonOption) {
    let rule = new NameRule(code);
    return rule;
  }

  public checkError(): boolean {
    return false;
  }
}
