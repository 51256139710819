import { Injectable } from '@angular/core';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Content } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import { CouponService } from '../../service/coupon.service';
import { CouponPickerComponent } from './coupon-picker/coupon-picker.component';
import { CouponMessage } from './coupon.message';

@Injectable({
  providedIn: 'root',
})
export class CouponMessageService {
  public constructor(
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _couponService: CouponService,
  ) {}

  public async createCouponFromPicker(): Promise<CouponMessage> {
    const couponId = await firstValueFrom(
      this._matConnectedDialog.open(CouponPickerComponent).afterClosed(),
    );

    if (!couponId) return;

    const content = new Content.Coupon(couponId);
    const message = new CouponMessage();
    message.couponService = this._couponService;
    await message.loadFromContent(content);
    return message;
  }
}
