import { Injectable } from '@angular/core';
import { FlowModel, ListFlowDto } from '@ay-gosu/server-shared';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { mergeMap, shareReplay } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class FlowListService {
  public readonly reload$ = new BehaviorSubject<null>(null);

  public readonly list$ = combineLatest([
    this._tokenService.rxAccount$,
    this.reload$,
  ]).pipe(
    mergeMap(([account]) =>
      account ? FlowModel.list() : of([] as ListFlowDto[]),
    ),
    shareReplay(1),
  );

  public constructor(private readonly _tokenService: TokenService) {}
}
