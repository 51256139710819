export const OpMap = {
  /// SHORT -> LONG
  LT: 'LESS',
  LE: 'LESS_EQUAL',
  EQ: 'EQUAL',
  GE: 'GREATER_EQUAL',
  GT: 'GREATER',
  NE: 'NOT_EQUAL',
  BT: 'BETWEEN',
  CT: 'INCLUDE',
  IN: 'IN_LIST',
  EX: 'EXISTS',
  NEX: 'NOT_EXISTS',
  /// LONG -> SHORT
  LESS: 'LESS',
  LESS_EQUAL: 'LESS_EQUAL',
  EQUAL: 'EQUAL',
  GREATER_EQUAL: 'GREATER_EQUAL',
  GREATER: 'GREATER',
  NOT_EQUAL: 'NOT_EQUAL',
  BETWEEN: 'BETWEEN',
  INCLUDE: 'INCLUDE',
  NOT_INCLUDE: 'NOT_INCLUDE',
  IN_LIST: 'IN_LIST',
  ALL_IN_LIST: 'ALL_IN_LIST',
  EXISTS: 'EXISTS',
  NOT_EXISTS: 'NOT_EXISTS',
  INCLUDE_ANY: 'INCLUDE_ANY',
};
