import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatConnectedDialog,
  MatConnectedDialogConfig,
} from '@ay-gosu/ui/common/connected-dialog';
import { ExposedPromise } from '@ay/util';
import { EmptyResponseError } from '../../util/empty-response-error';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public constructor(protected readonly connectedDialog: MatConnectedDialog) {}

  protected _open<T>(
    component: ComponentType<T>,
    config: MatConnectedDialogConfig,
    promise: ExposedPromise,
  ) {
    let dialogRef: MatDialogRef<T>;

    dialogRef = this.connectedDialog.open(
      component,
      config as MatConnectedDialogConfig,
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        promise.resolve(result);
      } else {
        promise.reject(new EmptyResponseError());
      }
    });

    return dialogRef;
  }
}
