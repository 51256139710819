// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { FacebookPostColumnDto } from './dto/column.dto';

export class FacebookPostModel {
  static getPostsByIds(postIds: string[]): Promise<FacebookPostColumnDto[]> {
    return wsc.execute('/ws/facebook-post/getPostsByIds', postIds) as any;
  }

  static getPosts(
    botIds: number[] = [],
    keyword: any = '',
    isPublished?: boolean,
    page: any = 1,
    limit: any = 10,
  ): Promise<{
    data: FacebookPostColumnDto[];
    page: number;
    limit: number;
    count: number;
  }> {
    return wsc.execute(
      '/ws/facebook-post/getPosts',
      botIds,
      keyword,
      isPublished,
      page,
      limit,
    ) as any;
  }

  static syncPostsByBotIds(botIds: number[]): Promise<any> {
    return wsc.execute('/ws/facebook-post/syncPostsByBotIds', botIds) as any;
  }
}
// 45e3689aea3ea6a9826b4598081579d2687a1fbd89fa2a859924956a052944cf
