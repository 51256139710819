import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';

export class Affecter {
  public affectedComponents: AffectedComponent<Affecter>[] = [];

  public changed() {
    this.affectedComponents.map((component) => component.markForCheck());
  }
}

@Component({
  selector: 'affected-component',
  template: '',
})
export class AffectedComponent<T extends Affecter> implements OnDestroy {
  public affecter$ = new BehaviorSubject<T>(null);

  protected readonly destroy$ = new Subject<void>();

  protected remove = this.affecter$
    .pipe(startWith(null), pairwise(), takeUntil(this.destroy$))
    .subscribe(([before, after]) => {
      this.removeFromAffecter(before);
      this.addToAffecter(after);
    });

  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {}

  public markForCheck() {
    this.changeDetectorRef.markForCheck();
  }

  public ngOnDestroy() {
    this.affecter$.next(null);

    this.destroy$.next();
    this.destroy$.complete();
  }

  protected addToAffecter(affecter: T) {
    if (!affecter) return;
    let index = affecter.affectedComponents.indexOf(this);
    if (index !== -1) return;
    affecter.affectedComponents.push(this);
  }

  protected removeFromAffecter(affecter: T) {
    if (!affecter) return;
    let index = affecter.affectedComponents.indexOf(this);
    if (index === -1) return;
    affecter.affectedComponents.splice(index, 1);
  }
}
