import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'couponType',
    standalone: true,
})
export class CouponTypePipe implements PipeTransform {
  public textMap = {
    discount: $localize`折扣`,
    free: $localize`免費`,
    gift: $localize`贈品`,
  };
  public transform(value: any): any {
    if (this.textMap[value]) return this.textMap[value];
    return value;
  }
}
