import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { ElementRefDirective } from '@ay-gosu/ui/common/element-ref';
import { FileDirective } from '../../components/file.directive';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { MessageToolbar } from '../factory/message-toolbar';
import { PackageAffectedComponent } from '../package-affected.component';
import { SubmenuComponent } from '../submenu/submenu.component';

@Component({
  selector: 'ms-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    MatIconButton,
    MatTooltip,
    MatIcon,
    FileDirective,
    MatMenuTrigger,
    SubmenuComponent,
    ElementRefDirective,
  ],
})
export class ToolbarComponent extends PackageAffectedComponent {
  @Input()
  public hideToolTypes: string[] = [];

  @Input()
  public disableToolTypes: string[] = [];

  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public async loadSubmenu(toolbar: MessageToolbar) {
    if (toolbar.submenuFn) {
      toolbar.submenu = await toolbar.submenuFn();
    }
  }
}
