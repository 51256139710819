<div class="container">
  <div class="drag-area">
    <input
      type="file"
      #dragUploadInput
      data-multiple-caption
      tabindex="-1"
      (change)="dragUpload()"
    />
    <img class="image-upload" src="assets/image-upload.png" alt="上傳圖片" />
    <img class="pointer" src="assets/upload-pointer.png" alt="拖曳圖片" />
    <mat-icon>cloud_upload</mat-icon>
  </div>

  <button
    mat-raised-button
    color="primary"
    (file)="buttonUpload($event)"
    accept="image/*"
    tabindex="-1"
    i18n
  >
    <mat-icon>attach_file</mat-icon>
    上傳圖片
  </button>

  <span i18n>或拖曳圖片至此</span><br />
  <input
    type="url"
    placeholder="貼上圖片或網址"
    i18n-placeholder="貼上圖片或網址"
    #urlInput
    [value]="data?.url || ''"
    (change)="afterURLChanged($event)"
    (paste)="afterURLPaste($event)"
    tabindex="1"
  />
</div>
