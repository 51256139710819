import { Pipe, PipeTransform } from '@angular/core';
import { ensureIsArray } from '@ay/util';

export const StatusMap = {
  Follow: $localize`成為好友`,
  Unfollow: $localize`被封鎖`,
  Join: $localize`加入群組`,
  Leave: $localize`踢離的群組`,
  Nodding: $localize`群組中的陌生人`,
  Authorize: $localize`授權 LINE LOGIN`,
};

export type StatusType = 'Follow' | 'Unfollow' | 'Join' | 'Leave' | 'Nodding';

export const StatusTypes: StatusType[] = [
  'Follow',
  'Unfollow',
  'Join',
  'Leave',
  'Nodding',
];

@Pipe({
    name: 'status', pure: false,
    standalone: true
})
export class StatusPipe implements PipeTransform {
  public transform(types: any): any {
    types = ensureIsArray(types);
    return types
      .map((type) => StatusMap[type])
      .filter((type) => type)
      .join('、');
  }
}
