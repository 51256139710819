// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { PropertyConfigDto } from './dto/property-config.dto';

export class PropertyConfigModel {
  /**
   * 取得所有的設定檔案
   * - 需要登入
   * - 只取得所屬公司
   * - 回傳值透過 targetType 分類
   */
  static getAll(): Promise<PropertyConfigDto[]> {
    return wsc.execute('/ws/property-config/getAll') as any;
  }

  static get(id: number): Promise<PropertyConfigDto> {
    return wsc.execute('/ws/property-config/get', id) as any;
  }

  /**
   * 更新屬性設定
   * - 需要登入
   * - 檢核 id 存在、並檢驗與存取帳號所屬 company 一至性
   */
  static update(id: number, config: PropertyConfigDto): Promise<boolean> {
    return wsc.execute('/ws/property-config/update', id, config) as any;
  }

  /**
   * 新增屬性設定
   * - 自動將 order 設定為同類 targetType 的最大值 + 1
   * - 登入需要
   * - 自動將帶入創立者的 companyId
   * */
  static create(config: PropertyConfigDto): Promise<number> {
    return wsc.execute('/ws/property-config/create', config) as any;
  }

  /**
   * 刪除指定屬性設定檔
   * - 登入需要
   * - 檢核 id 存在、並檢驗與存取帳號所屬 company 一至性
   * - 軟刪除
   */
  static remove(id: number): Promise<boolean> {
    return wsc.execute('/ws/property-config/remove', id) as any;
  }
}
// 671d56b9ec3424ebf8ffb248ac21a6957a21995b8c8ffa3899cb168c9a5a7d36
