// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class StatusModel {
  static fetchMaintenance(): Promise<boolean> {
    return wsc.execute('/ws/status/fetchMaintenance') as any;
  }

  static toggleMaintenance(maintenance: any = false): Promise<void> {
    return wsc.execute('/ws/status/toggleMaintenance', maintenance) as any;
  }
}
// a4bdbb424abcf061eb88af3fde9559919ba0d6d5643e9b22863a2dea7ad4b9e0
