import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { FlowModel } from '@ay-gosu/server-shared';
import { SubscriptionLike } from 'rxjs';

@Pipe({
    name: 'flow',
    pure: false,
    standalone: true,
})
export class FlowPipe implements PipeTransform, OnDestroy {
  private _latestId: number = null;
  private _latestValue: string = null;
  private _latestReturnedValue: string = null;

  private _subscription: SubscriptionLike;

  public constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    this._dispose();
  }

  public transform(arg: number, length: number = Infinity) {
    if (!this._latestId) {
      this._latestId = arg;
      this._subscribe(arg);
      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (arg.toString() != this._latestId.toString()) {
      this._dispose();
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  private async _subscribe(id: number) {
    try {
      const flows = await FlowModel.fetchFlowNames([id]);
      const name = flows[0].name;

      this._updateLatestValue(name);
    } catch (error) {
      console.error(error);
    }
  }

  private _dispose(): any {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
    this._latestValue = null;
    this._latestReturnedValue = null;
    this._latestId = null;
  }

  private _updateLatestValue(name: string) {
    this._latestValue = name;

    this._changeDetectorRef.markForCheck();
  }
}
