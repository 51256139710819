import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ElementRefDirective } from '@ay-gosu/ui/common/element-ref';
import Bluebird from 'bluebird';
import { MatContextMenuDirective } from '../../material/context-menu';
import { KonamiCode } from '../../service/konami-code.service';
import { Message } from '../base/base.message';
import { FactoryComponent } from '../factory/factory.component';
import { PackageAffectedComponent } from '../package-affected.component';
import { QuickRepliesComponent } from '../quick-replies/quick-replies.component';
import { SubmenuComponent } from '../submenu/submenu.component';
import { TextMessage } from '../text/text.message';

@Component({
  selector: 'ms-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package'],
  exportAs: 'msPreview',
  standalone: true,
  imports: [
    NgIf,
    CdkDropList,
    MatContextMenuDirective,
    NgFor,
    CdkDrag,
    FactoryComponent,
    NgClass,
    ExtendedModule,
    MatIcon,
    QuickRepliesComponent,
    MatProgressBar,
    MatMenu,
    MatMenuItem,
    SubmenuComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    AsyncPipe,
    ElementRefDirective,
  ],
})
export class PreviewComponent
  extends PackageAffectedComponent
  implements AfterViewInit
{
  public selected: Message;

  @Input()
  public mode: 'READ' | 'EDIT' = 'EDIT';

  @Input()
  public editable: boolean = true;

  @Output()
  public editMessageEvent: EventEmitter<EditMessageEvent> = new EventEmitter();

  @Output()
  public replyMessageEvent: EventEmitter<ReplyMessageEvent> =
    new EventEmitter();

  @ViewChild('menu')
  public menu: ElementRef;

  public position: number = 0;

  @ViewChild('dragUploadInput')
  public dragUploadInput: ElementRef<HTMLInputElement>;

  public get selectedIndex() {
    if (!this.package) return -1;
    return this.package.messages.indexOf(this.selected);
  }

  public get isFirstMessage() {
    return this.selectedIndex === 0;
  }

  public get isLatestMessage() {
    if (!this.package) return false;
    return this.selectedIndex === this.package.messages.length - 1;
  }

  public constructor(
    public readonly konamiCode: KonamiCode,
    protected readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    if (this.menu) {
      this.position = this.menu.nativeElement.offsetWidth;
    }
  }

  public messageDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.package.messages,
      event.previousIndex,
      event.currentIndex,
    );
    this.package.changed();
  }

  public async delete() {
    this.package.messages.splice(this.selectedIndex, 1);
    this.package.changed();
  }

  public swapUpward() {
    let index = this.selectedIndex;
    this.package.swap(index, index - 1);
  }

  public swapDownward() {
    let index = this.selectedIndex;
    this.package.swap(index, index + 1);
  }

  public editMessage($event: MouseEvent) {
    let index = this.selectedIndex;
    this.editMessageEvent.emit({
      message: this.package.messages[index] as TextMessage,
      sourceMouseEvent: $event,
    });
  }

  public replyMessage($event: MouseEvent) {
    let index = this.selectedIndex;
    this.replyMessageEvent.emit({
      message: this.package.messages[index] as TextMessage,
      sourceMouseEvent: $event,
    });
  }

  public async onMouseEnter(message: Message) {
    if (this.selected === message) return;
    this.selected = message;
    this.changeDetectorRef.markForCheck();
    await Bluebird.delay(1);
    this.changeDetectorRef.markForCheck();
  }
}

export type EditMessageEvent = {
  message: TextMessage;
  sourceMouseEvent: MouseEvent;
};

export type ReplyMessageEvent = {
  message: TextMessage;
  sourceMouseEvent: MouseEvent;
};
