import { RuleOp } from '@ay-gosu/types';
import { ensureTheElementIsWhitelisted } from '@ay/util';
import { delay } from 'bluebird';
import { ruleJoin } from '../../ruleJoin';
import { PropertyRule } from '../property.class';

export class StringPropertyRule extends PropertyRule<string | string[]> {
  public static op = [
    'EQUAL',
    'NOT_EQUAL',
    'INCLUDE',
    'IN_LIST',
    'EXISTS',
    'NOT_EXISTS',
  ] as RuleOp[];

  public static arrayValueOps = ['IN_LIST'];
  public static strValueOps = ['EQUAL', 'NOT_EQUAL', 'INCLUDE'];
  public static nullValueOps = ['EXISTS', 'NOT_EXISTS'];

  public async afterTypeChanged(isUserEvent = false) {
    this.op = ensureTheElementIsWhitelisted(this.op, ...StringPropertyRule.op);
    await delay(1);
    if (isUserEvent) {
      if (this.component?.property?.propertyTrigger) {
        this.component.property.propertyTrigger.openMenu();
      }
    }
  }

  public toString() {
    switch (this.op) {
      case 'EXISTS':
        return $localize`[${this.property.name}]存在`;
      case 'NOT_EXISTS':
        return $localize`[${this.property.name}]不存在`;
      case 'EQUAL':
        return $localize`[${this.property.name}]完全等於'${this.value}'`;
      case 'NOT_EQUAL':
        return $localize`[${this.property.name}]不等於'${this.value}'`;
      case 'INCLUDE':
        return $localize`[${this.property.name}]包含'${this.value}'`;
      case 'IN_LIST':
        return $localize`[${this.property.name}]在以下清單中，${ruleJoin(
          this.value.toString().split(','),
          '、',
        )}`;
    }
  }
}
