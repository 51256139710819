import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'wrapHtml', pure: true,
    standalone: true
})
export class WrapHtmlPipe implements PipeTransform {
  public constructor() {}

  public transform(content: string): string {
    if (typeof content !== 'string') return content;
    return content.replace(/\n/gi, '<br>');
  }
}
