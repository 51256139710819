import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  Optional,
  WritableSignal,
  effect,
  input,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatProgressSpinnerModule,
  ProgressSpinnerMode,
} from '@angular/material/progress-spinner';

@Component({
  selector: 'gosu-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements AfterContentInit {
  public title = signal('');

  public modeInput = input<ProgressSpinnerMode>('indeterminate', {
    alias: 'mode',
  });

  public mode = signal<ProgressSpinnerMode>('indeterminate');

  public valueInput = input<number, number | string>(0, {
    alias: 'value',
    transform: (value) => Math.max(0, Math.min(100, Number(value))),
  });

  public value = signal<number>(0);

  public isDialog = signal(false);

  public constructor(
    @Optional()
    public readonly dialogRef: MatDialogRef<any>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: {
      title: string;
      mode: ProgressSpinnerMode;
      value?: WritableSignal<number>;
    },
    private readonly _injector: Injector,
  ) {}

  public ngAfterContentInit() {
    const data = this._data;
    if (this.dialogRef && this.dialogRef.componentInstance === this) {
      this.isDialog.set(true);
      if (data) {
        this.title.set(data.title ?? '載入中...');
        this.mode.set(data.mode ?? 'indeterminate');
        if (data.value) this.value = data.value;
      }
    } else {
      this.isDialog.set(false);
      const injector = this._injector;
      const option = {
        injector,
        allowSignalWrites: true,
      };
      effect(() => this.mode.set(this.modeInput()), option);
      effect(() => this.value.set(this.valueInput()), option);
    }
  }
}
