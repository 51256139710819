/**
 * 注意： 不要嘗試把 ExposedPromise 改為 Promisify
 * 因為 PromiseLock 的 lock 的回傳值是一個 Promise<ExposedPromise>
 * 使用時會是:
 * const locker = await PromiseLock.lock()
 * do something...
 * locker.resolve();
 *
 * 如果 ExposedPromise 是 Promisify 在 await PromiseLock.lock() 時就會根據 promise chain 的特性等到 locker 被釋放後才回傳，這樣會把任務卡住
 */
export class ExposedPromise<T = any> {
  public promise: Promise<T>;

  private _resolve: (value?: T | PromiseLike<T>) => void;
  private _reject: (reason?: any) => void;
  private _status: 'pending' | 'resolved' | 'rejected' = 'pending';
  public value?: T;

  public constructor() {
    this.renew();
  }

  public get status() {
    return this._status;
  }

  public isPending() {
    return this._status === 'pending';
  }

  public resolve(value?: T | PromiseLike<T>) {
    if (this._status !== 'pending') {
      return;
    }

    if (value && typeof value === 'object' && 'then' in value) {
      value.then((v) => {
        this._status = 'resolved';
        this.value = v as T;
      });
    } else {
      this._status = 'resolved';
      this.value = value as T;
    }

    return this._resolve(value);
  }

  public reject(reason?: any) {
    if (this._status !== 'pending') {
      return;
    }
    this._status = 'rejected';
    return this._reject(reason);
  }

  public renew() {
    this._status = 'pending';
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public bind(promise: Promise<T>) {
    return promise
      .then((value) => this.resolve(value))
      .catch((error) => this.reject(error));
  }
}
