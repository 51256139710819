import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class EditTextMessage extends Message<Content.EditText> {
  public type = 'edit-text';

  public constructor() {
    super();
    this.content = new Content.EditText('');
  }

  public toString() {
    return this.content.content;
  }
}
