import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { BypassSecurityService } from '../../service/bypass-security.service';
import { BaseComponent } from '../base/base.component';
import { VideoMessage } from './video.message';

@Component({
  selector: 'ms-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package', 'mode', 'message'],
  standalone: true,
  imports: [AsyncPipe],
})
export class VideoComponent
  extends BaseComponent<VideoMessage>
  implements AfterViewInit
{
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  thumbnailUrl$ = new BehaviorSubject<string>('');

  public url$ = this.message$.pipe(
    map((message) =>
      this._bypassSecurityService.bypassSecurityUrl(message.content.content, [
        BypassSecurityService.isBypassWhiteList,
      ]),
    ),
  );

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _bypassSecurityService: BypassSecurityService,
  ) {
    super(changeDetectorRef);
  }

  ngAfterViewInit() {
    const video = this.videoElement.nativeElement;

    // 強制預加載視頻
    video.preload = 'metadata';
    // 在某些移動瀏覽器中可能需要手動加載
    video.load();

    // 添加錯誤處理
    video.onerror = (e) => {
      console.warn('視頻加載錯誤:', e);
    };
  }
}
