import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CannedMessageModel } from '@ay-gosu/server-shared';
import { asyncJob, AsyncJobModule } from '@ay-gosu/ui/common/async-job';
import Bluebird from 'bluebird';
import { MessageFactoryService } from '../../../message/message-factory.service';
import { PackageFactoryService } from '../../../message/package-factory.service';
import { Package } from '../../../message/package.class';
import { PreviewComponent } from '../../../message/preview/preview.component';

@Component({
  selector: 'cs-canned-message-preview',
  templateUrl: './canned-message-preview.component.html',
  standalone: true,
  imports: [PreviewComponent, MatButton, AsyncJobModule],
  host: {
    class: '!flex flex-col w-[600px]',
  },
})
export class CannedMessagePreviewComponent {
  public name: string;

  public package$ = asyncJob(async () => {
    const cannedMessageId = this._data.cannedMessageId;
    const raw = await CannedMessageModel.fetchPackage(cannedMessageId);
    const pkg = await this._packageFactory.createForPreview();
    const messages = await Bluebird.map(raw.records, (record) =>
      this._messageFactory.createFromContent(record),
    );
    pkg.messages = messages;
    return pkg;
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: {
      name: string;
      cannedMessageId: number;
      editor: Package;
    },
    private readonly _matDialogRef: MatDialogRef<any>,
    private readonly _packageFactory: PackageFactoryService,
    private readonly _messageFactory: MessageFactoryService,
  ) {
    this.name = this._data.name;
  }

  public close() {
    this._matDialogRef.close();
  }

  public async use() {
    const editor = this._data.editor;
    const pkg = await this.package$.toPromise();
    const messages = await Bluebird.map(pkg.messages, (message) => {
      const data = message.toJSON();
      data.packageId = undefined;
      data.recordId = undefined;
      return this._messageFactory.createFromContent(data);
    });

    editor.messages = editor.messages.concat(messages);
    editor.removeFirstIfEmpty();
    editor.focusFirstMessage();
    editor.changed();
    this._matDialogRef.close();
  }
}
