import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'moment',
    standalone: true,
})
export class MomentPipe implements PipeTransform {
  public transform(
    value: any,
    format: string = 'YYYY-MM-DD HH:mm:ss',
    ori?: any,
  ): any {
    if (!value) return value;
    try {
      return moment(value, ori).format(format);
    } catch (err) {
      console.error('Error MomentPipe:', err);
      return value;
    }
  }
}
