import { Directive, ElementRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[matTreePickerOutlet]',
    standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MatTreePickerOutlet {
  public constructor(
    public readonly viewContainerRef: ViewContainerRef,
    public readonly elementRef: ElementRef<HTMLElement>,
  ) {}
}
