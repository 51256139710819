import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { BasicDialog } from './dialog/basic';
import { TokenService } from './service/token.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerReminder implements CanDeactivate<any> {
  public isActive = false;
  private _isShowConfirm = false;
  public tokenService: TokenService;

  public constructor(private readonly _basicDialog: BasicDialog) {}

  public async leaveConfirm(): Promise<boolean> {
    this._isShowConfirm = true;
    let res = await this._basicDialog.confirm('是否離開真人客服');
    this._isShowConfirm = false;
    return res;
  }

  public async canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    now: RouterStateSnapshot,
  ): Promise<boolean> {
    if (this._isShowConfirm) return false;
    if (!this.isActive) return true;

    // 如果已經登出，就不用再跳出確認視窗
    const payload = await this.tokenService.payload$.toPromise();
    if (!payload) return true;

    let res = await this.leaveConfirm();
    this.isActive = !res;
    return res;
  }
}
