import { Injectable } from '@angular/core';
import { TicketMachineModel } from '@ay-gosu/server-shared';
import { BehaviorSubject, combineLatest, of, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
} from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  public id$ = new ReplaySubject<number>();

  public reload$ = new BehaviorSubject(1);

  public response$ = combineLatest([this.token.rxAccount$, this.reload$]).pipe(
    mergeMap(([account, reload]) =>
      account ? TicketMachineModel.list() : of({ data: [], total: 0 }),
    ),
    shareReplay(),
  );

  public list$ = this.response$.pipe(map((res) => res.data));

  public selected$ = combineLatest([
    this.token.rxAccount$,
    this.id$.pipe(distinctUntilChanged()),
  ]).pipe(
    mergeMap(([account, id]) => TicketMachineModel.fetch(id)),
    shareReplay(),
  );

  public constructor(private token: TokenService) {}
}
