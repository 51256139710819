import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomizeService {
  public get isCustomize(): boolean {
    if (environment.isCustomize === undefined) return false;

    return environment.isCustomize;
  }
}
