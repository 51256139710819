<div class="container">
  <div>
    <div class="picker-wrap">
      <div
        class="picker"
        #picker
        [style.background]="
          domSanitizer.bypassSecurityTrustStyle(pickerBackground)
        "
        [ngClass]="mode"
      >
        <div
          #thumb
          class="thumb"
          [style.background]="hex"
          [style.borderColor]="thumbBorderColor"
          [style.left.%]="x"
          [style.top.%]="y"
        ></div>
      </div>
    </div>

    <div class="slider">
      <mat-color-picker-slider
        #slider
        [min]="0"
        [max]="255"
        [trackBackground]="sliderBackground"
        [thumbBackground]="hex"
        (dataChange)="afterSliderValueChange()"
      >
      </mat-color-picker-slider>
    </div>

    <div class="alpha-slider">
      <mat-color-picker-slider
        *ngIf="enableAlpha"
        [min]="0"
        [max]="100"
        [trackBackground]="alphaBackground"
        [(data)]="alphaPercentage"
      >
      </mat-color-picker-slider>
    </div>

    <div>
      <div class="color compare">
        <div>
          <div class="label" i18n>目前</div>
          <div class="old" [style.background]="oriColor"></div>
        </div>
      </div>
      <div class="color compare">
        <div>
          <div class="label" i18n>新的</div>
          <div class="new" [style.background]="hex"></div>
        </div>
      </div>

      <mat-form-field class="hex-input" legacyAppearance>
        <mat-label>Hex</mat-label>
        <input matInput type="text" [(ngModel)]="hex" />
        <span matSuffix> </span>
      </mat-form-field>
    </div>
  </div>

  <div fxFlex="1 0 0">
    <div>
      <mat-form-field
        (click)="mode = 'red'"
        [class.active]="mode === 'red'"
        legacyAppearance
      >
        <mat-label i18n>紅色 Red</mat-label>
        <input matInput type="text" [(ngModel)]="red" min="0" max="255" />
        <span matSuffix> </span>
      </mat-form-field>

      <mat-form-field
        (click)="mode = 'green'"
        [class.active]="mode === 'green'"
        legacyAppearance
      >
        <mat-label i18n>綠色 Green</mat-label>
        <input matInput type="text" [(ngModel)]="green" min="0" max="255" />
        <span matSuffix> </span>
      </mat-form-field>

      <mat-form-field
        (click)="mode = 'blue'"
        [class.active]="mode === 'blue'"
        legacyAppearance
      >
        <mat-label i18n>藍色 Blue</mat-label>
        <input matInput type="text" [(ngModel)]="blue" min="0" max="255" />
        <span matSuffix> </span>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field
        (click)="mode = 'hue'"
        [class.active]="mode === 'hue'"
        legacyAppearance
      >
        <mat-label i18n>色調 Hue</mat-label>
        <input matInput type="text" [(ngModel)]="hue" min="0" max="360" />
        <span matSuffix> °</span>
      </mat-form-field>

      <mat-form-field
        (click)="mode = 'saturation'"
        [class.active]="mode === 'saturation'"
        legacyAppearance
      >
        <mat-label i18n>飽和 Saturation</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="saturation"
          min="0"
          max="100"
        />
        <span matSuffix> %</span>
      </mat-form-field>

      <mat-form-field
        (click)="mode = 'brightness'"
        [class.active]="mode === 'brightness'"
        legacyAppearance
      >
        <mat-label i18n>亮度 Brightness</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="brightness"
          min="0"
          max="100"
        />
        <span matSuffix> %</span>
      </mat-form-field>
    </div>

    <div *ngIf="enableAlpha">
      <mat-form-field legacyAppearance>
        <mat-label i18n>透明度 Alpha</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="alphaPercentage"
          min="0"
          max="100"
        />
        <span matSuffix> %</span>
      </mat-form-field>
    </div>

    <div class="palette depth">
      <div class="label" i18n>相近色票</div>
      <button
        mat-button
        class="color"
        *ngFor="let color of depth"
        [style.backgroundColor]="color"
        (click)="hex = color"
      ></button>
    </div>

    <div class="palette base">
      <div class="label" i18n>基本色票</div>
      <button
        mat-button
        class="color"
        *ngFor="let color of palette"
        [style.backgroundColor]="color"
        (click)="hex = color"
      ></button>
    </div>

    <div class="palette history">
      <ng-container *ngIf="history.length">
        <div class="label" i18n>常用色票</div>
        <button
          mat-button
          class="color"
          *ngFor="let color of history"
          [style.background]="
            domSanitizer.bypassSecurityTrustStyle(
              '-webkit-linear-gradient(left, ' +
                color +
                ' 0%, ' +
                color +
                ' 100%), url(' +
                transparentBackgroundImage +
                ')'
            )
          "
          (click)="hex = color"
        ></button>
      </ng-container>
    </div>

    <div class="submit">
      <button mat-button i18n (click)="cancel()">取消</button>
      <button mat-raised-button color="primary" (click)="submit()" i18n>
        確定
      </button>
    </div>
  </div>
</div>
