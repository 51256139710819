import { APP_BASE_HREF } from '@angular/common';
import { ElementRef, Inject, Injectable, Injector } from '@angular/core';
import {
  CannedMessageDto,
  CannedMessageModel,
  InteractiveModel,
  PackageFetchForBroadcast,
  PackageFetchForInteractiveNotification,
  PackageFetchForPromotionChannel,
  PackageFetchForSendNode,
} from '@ay-gosu/server-shared';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Button, Card, Content } from '@ay/bot';
import { arrayToObject } from '@ay/util';
import Bluebird from 'bluebird';
import { firstValueFrom } from 'rxjs';
import urlJoin from 'url-join';
import { CannedMessagePreviewComponent } from '../pages/canned-message/canned-message-preview/canned-message-preview.component';
import { Group } from '../pages/filter/group';
import { BotService } from '../service/bot.service';
import { CompanyService } from '../service/company.service';
import { FilterService } from '../service/filter.service';
import { PageService } from '../service/page.service';
import { ProfileService } from '../service/profile.service';
import { PropertyConfigService } from '../service/property-config.service';
import { TagService } from '../service/tag.service';
import { AudioMessageService } from './audio/audio-message.service';
import { CardsMessageService } from './cards/cards-message.service';
import { CardsMessage } from './cards/cards.message';
import { CouponMessageService } from './coupon/coupon-message.service';
import {
  ClickItem,
  DeepSubmenuItem,
  MessageToolbar,
  UploadFileItem,
} from './factory/message-toolbar';
import { FlexMessageService } from './flex/flex-message.service';
import { ImageMessageService } from './image/image-message.service';
import { MessageFactoryService } from './message-factory.service';
import { Origin, Package } from './package.class';
import { PosterMessageService } from './poster/poster-message.service';
import { VideoMessageService } from './video/video-message.service';

type ToolbarTypes =
  | 'text'
  | 'image'
  | 'cards'
  | 'poster'
  | 'coupon'
  | 'flex'
  | 'audio'
  | 'video'
  | 'cardsWithoutCreate'
  | 'posterWithoutCreate'
  | 'cannedMessage';

@Injectable({
  providedIn: 'root',
})
export class PackageFactoryService {
  public readonly enableCoupon$ =
    this._pageService.hasPermissionForPath$('/coupon');

  public readonly enablePromotionChannel$ =
    this._pageService.hasPermissionForPath$('/promotion-channel');

  public readonly toolbars: {
    [key in ToolbarTypes]: MessageToolbar;
  } = {
    text: new ClickItem({
      type: 'text',
      label: $localize`文字`,
      tooltip: $localize`文字：適用於所有平台`,
      icon: 'font_download',
      onClick: async () => this._messageFactoryService.create('text'),
    }),

    image: new DeepSubmenuItem({
      type: 'image',
      label: $localize`圖片`,
      tooltip: $localize`圖片：適用於所有平台`,
      icon: 'image',
      submenu: [
        new ClickItem({
          type: 'image',
          label: $localize`上傳圖片`,
          icon: 'file_upload',
          onClick: () => this._imageMessageService.createFromUploader(),
        }),
        new ClickItem({
          type: 'image',
          label: $localize`從圖像編輯器中挑選`,
          icon: 'color_lens',
          onClick: () => this._imageMessageService.createFromTemplatePicker(),
        }),
      ],
    }),

    cards: new DeepSubmenuItem({
      type: 'cards',
      label: $localize`卡片`,
      tooltip: $localize`卡片：適用於所有平台`,
      icon: 'style',
      submenu: [
        new ClickItem({
          type: 'cards',
          label: $localize`建立新卡片`,
          icon: 'add',
          onClick: (pkg) => this._cardsMessageService.createFromCreator(pkg),
        }),
        new ClickItem({
          type: 'cards',
          label: $localize`從圖像編輯器中挑選`,
          icon: 'color_lens',
          onClick: () => this._cardsMessageService.createFromTemplatePicker(),
        }),
      ],
    }),

    poster: new DeepSubmenuItem({
      type: 'poster',
      label: $localize`海報`,
      tooltip: $localize`海報：適用於LINE`,
      icon: 'dashboard',
      submenu: [
        new ClickItem({
          type: 'poster',
          label: $localize`建立新海報`,
          icon: 'add',
          onClick: (pkg) => this._posterMessageService.createFromEditor(pkg),
        }),
        new ClickItem({
          type: 'poster',
          label: $localize`從圖像編輯器中挑選`,
          icon: 'color_lens',
          onClick: () => this._posterMessageService.createFromTemplatePicker(),
        }),
      ],
    }),

    coupon: new ClickItem({
      type: 'coupon',
      label: $localize`優惠券`,
      tooltip: $localize`優惠券：適用於LINE`,
      icon: 'card_giftcard',
      onClick: () => this._couponMessageService.createCouponFromPicker(),
      enable$: this.enableCoupon$,
    }),

    flex: new ClickItem({
      type: 'flex',
      label: $localize`Flex 訊息`,
      tooltip: $localize`Flex 訊息：適用於LINE`,
      icon: 'view_quilt',
      onClick: (pkg: Package) => this._flexMessageService.createFromEditor(pkg),
    }),

    audio: new DeepSubmenuItem({
      type: 'audio',
      label: $localize`聲音`,
      tooltip: $localize`聲音：適用於所有平台`,
      icon: 'keyboard_voice',
      submenu: [
        new ClickItem({
          type: 'audio',
          label: $localize`錄音`,
          icon: 'keyboard_voice',
          onClick: () => this._audioMessageService.createFromRecorder(),
        }),
        new UploadFileItem({
          type: 'audio',
          label: $localize`上傳檔案`,
          icon: 'file_upload',
          accept: 'audio/x-m4a,audio/aac',
          tooltip: $localize`目前僅支援 m4a 檔案`,
          onUpload: (fileList: FileList) =>
            this._audioMessageService.createFromFile(fileList.item(0)),
        }),
      ],
    }),

    video: new DeepSubmenuItem({
      type: 'video',
      label: $localize`影片`,
      tooltip: $localize`影片：適用於LINE`,
      icon: 'videocam',
      submenu: [
        new UploadFileItem({
          type: 'video',
          label: $localize`上傳檔案`,
          icon: 'file_upload',
          accept: 'video/mp4',
          tooltip: $localize`目前僅支援 mp4 檔案，100 MB以下`,
          onUpload: (fileList: FileList) =>
            this._videoMessageService.createFromUploader(fileList.item(0)),
        }),
      ],
    }),

    cardsWithoutCreate: new ClickItem({
      type: 'cards',
      label: $localize`卡片`,
      icon: 'style',
      onClick: () => this._cardsMessageService.createFromTemplatePicker(),
    }),

    posterWithoutCreate: new ClickItem({
      type: 'poster',
      label: $localize`海報`,
      icon: 'dashboard',
      onClick: () => this._posterMessageService.createFromTemplatePicker(),
    }),

    cannedMessage: new DeepSubmenuItem({
      type: 'cannedMessage',
      label: $localize`罐頭訊息`,
      tooltip: $localize`罐頭訊息：選用預先建立的訊息`,
      icon: 'comment_bank',
      submenu: async () => {
        const categories = await CannedMessageModel.fetchCategories();
        const sortedCategories = categories.sort((a, b) => a.order - b.order);
        const submenuItems = sortedCategories.map(
          (category) =>
            new DeepSubmenuItem({
              type: 'cannedMessage',
              label: category.name,
              submenu: async () => {
                const sortedCannedMessages = category.cannedMessages.sort(
                  (a, b) => a.order - b.order,
                );
                const clickItems = sortedCannedMessages.map(
                  (message) =>
                    new ClickItem({
                      type: 'cannedMessage',
                      label: message.name,
                      onClick: async (pkg: Package, elementRef: ElementRef) =>
                        this._onCannedMessageClick(message, pkg),
                    }),
                );

                if (clickItems.length === 0) {
                  clickItems.push(
                    new ClickItem({
                      type: 'cannedMessage',
                      label: $localize`建立新罐頭訊息`,
                      onClick: () => {
                        const url = urlJoin(
                          this._baseHref,
                          '/canned-message/',
                          category.cannedMessageCategoryId.toString(),
                        );
                        window.open(url);
                        return null;
                      },
                    }),
                  );
                }
                return clickItems;
              },
            }),
        );

        if (submenuItems.length === 0) {
          submenuItems.push(
            new ClickItem({
              type: 'cannedMessage',
              label: $localize`建立新罐頭訊息`,
              icon: 'add',
              onClick: () => {
                const url = urlJoin(this._baseHref, '/canned-message');
                window.open(url);
                return null;
              },
            }),
          );
        }
        return submenuItems;
      },
    }),
  };

  public constructor(
    private readonly _couponMessageService: CouponMessageService,
    private readonly _pageService: PageService,
    private readonly _tagService: TagService,
    private readonly _messageFactoryService: MessageFactoryService,
    private readonly _flexMessageService: FlexMessageService,
    private readonly _posterMessageService: PosterMessageService,
    private readonly _audioMessageService: AudioMessageService,
    private readonly _cardsMessageService: CardsMessageService,
    private readonly _imageMessageService: ImageMessageService,
    private readonly _videoMessageService: VideoMessageService,
    private readonly _profileService: ProfileService,
    private readonly _propertyConfigService: PropertyConfigService,
    private readonly _botService: BotService,
    private readonly _filterService: FilterService,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _injector: Injector,
    private readonly _companyService: CompanyService,
    @Inject(APP_BASE_HREF)
    private readonly _baseHref: string,
  ) {}

  private _create(origin: Origin): Package {
    const injector = Injector.create({
      providers: [{ provide: 'ORIGIN', useValue: origin }, Package],
      parent: this._injector,
    });

    const pkg = injector.get(Package);

    return pkg;
  }

  public async createForPrivateMessage(): Promise<Package> {
    const company = await firstValueFrom(this._companyService.company$);
    const isCustomPrivateMessage = !!company.customPrivateMessage;
    const pkg = this._create('private-message');

    const toolbars: ToolbarTypes[] = [
      'text',
      'image',
      'cards',
      'poster',
      'coupon',
      'flex',
      'audio',
      ...((isCustomPrivateMessage ? ['video'] : []) as ToolbarTypes[]),
      'cannedMessage',
    ];

    pkg.toolbars = this._loadToolbars(...toolbars);

    pkg.enabledActions = {
      message: true,
      next: false,
      shareMessageThroughPromotion: true,
      addThroughPromotion: false,
      dateTimePicker: false,
      uri: true,
      postback: false,
    };

    pkg.enableQuickReplies = false;

    return pkg;
  }

  public async createForNotification(
    data?: PackageFetchForInteractiveNotification,
    type?: string,
    interactiveType?: string,
    ticketType?: string,
  ): Promise<Package> {
    const pkg = this._create('notification');

    pkg.toolbars = this._loadToolbars('text', 'image', 'cards', 'poster');

    pkg.enabledActions = {
      message: true,
      next: false,
      shareMessageThroughPromotion: true,
      addThroughPromotion: false,
      dateTimePicker: false,
      uri: true,
      postback: false,
    };

    pkg.enableQuickReplies = false;

    if (data) {
      pkg.id = data.id;
      if (data.records) pkg.messages = await this.convertMessages(data.records);
      pkg.oriMessages = pkg.messages.slice();
    } else {
      const message = new CardsMessage();
      let card: Card;
      const defaultImage = await InteractiveModel.fetchDefaultImage();

      switch (type) {
        case 'NEARING_COMPLETION':
          card = new Card(
            defaultImage.notification.nearingCompletion,
            '即將達標',
            '您快達成所有的任務了！加油！',
            null,
          );
          break;

        case 'AWARD':
          if (ticketType === 'STORED_VALUE_FIXED') {
            card = new Card(
              defaultImage.notification.award,
              '恭喜你完成指定任務，獲得${ticket.name}',
              '',
              null,
            );
          } else if (ticketType === 'STORED_VALUE_PERCENTAGE') {
            card = new Card(
              defaultImage.notification.award,
              '恭喜你完成指定任務，獲得${ticket.point}元',
              '',
              null,
            );
          } else if (ticketType === 'LINE_POINT_HUB_PERCENTAGE') {
            card = new Card(
              defaultImage.notification.award,
              '恭喜你完成指定任務，獲得${ticket.point}點',
              '請點擊下方領取按鈕，獲得獎品吧！',
              [new Button.Uri('前往領取', '${ticket?.url}')],
            );
          } else if (ticketType === 'LINE_POINT_HUB_FIXED') {
            card = new Card(
              defaultImage.notification.award,
              '恭喜你完成指定任務，獲得${ticket.name}',
              '請點擊下方領取按鈕，獲得獎品吧！',
              [new Button.Uri('前往領取', '${ticket?.url}')],
            );
          } else if (ticketType === 'CODE') {
            card = new Card(
              defaultImage.notification.award,
              '恭喜你完成指定任務，獲得${ticket.name}',
              '請點擊下方領取按鈕，獲得獎品吧！',
              [new Button.Uri('前往領取', '${ticket?.url}')],
            );
          }
          break;

        case 'COMPLETION':
          if (interactiveType === 'SLOTS') {
            card = new Card(
              defaultImage.notification.completionSlots,
              '恭喜你完成任務，可以玩遊戲1次',
              '請點擊下方按鈕，前往挑戰吧！',
              [new Button.Uri('前往遊戲', '${url}')],
            );
          }
          if (interactiveType === 'PRIZE_WHEEL') {
            card = new Card(
              defaultImage.notification.completionPrizeWheel,
              '恭喜你完成任務，可以玩遊戲1次',
              '請點擊下方按鈕，前往挑戰吧！',
              [new Button.Uri('前往遊戲', '${url}')],
            );
          }
          break;

        case 'PRIZE':
          if (ticketType === 'STORED_VALUE_FIXED') {
            card = new Card(
              defaultImage.notification.prize,
              '恭喜你完成指定任務，獲得${ticket.name}',
              '',
              null,
            );
          } else if (ticketType === 'STORED_VALUE_PERCENTAGE') {
            card = new Card(
              defaultImage.notification.prize,
              '恭喜你完成指定任務，獲得${ticket.point}}元',
              '',
              null,
            );
          } else if (ticketType === 'LINE_POINT_HUB_PERCENTAGE') {
            card = new Card(
              defaultImage.notification.prize,
              '恭喜你完成指定任務，獲得${ticket.point}點',
              '請點擊下方領取按鈕，獲得獎品吧！',
              [new Button.Uri('前往領取', '${ticket?.url}')],
            );
          } else if (ticketType === 'LINE_POINT_HUB_FIXED') {
            card = new Card(
              defaultImage.notification.prize,
              '恭喜你完成指定任務，獲得${ticket.name}',
              '請點擊下方領取按鈕，獲得獎品吧！',
              [new Button.Uri('前往領取', '${ticket?.url}')],
            );
          } else if (ticketType === 'CODE') {
            card = new Card(
              defaultImage.notification.prize,
              '恭喜你完成指定任務，獲得${ticket.name}',
              '請點擊下方領取按鈕，獲得獎品吧！',
              [new Button.Uri('前往領取', '${ticket?.url}')],
            );
          } else if (ticketType === null) {
            card = new Card(
              defaultImage.notification.lose,
              '很可惜！這次沒中獎',
              '下次幸運之神一定會眷顧您！',
              [],
            );
          } else {
            card = new Card(
              defaultImage.notification.prize,
              '恭喜你完成指定任務，獲得${ticket.name}',
              '請點擊下方領取按鈕，獲得獎品吧！',
              [new Button.Uri('前往領取', '${ticket?.url}')],
            );
          }

          break;
      }

      message.content.cards.push(card);
      pkg.add(message);
    }

    return pkg;
  }

  private _loadToolbars(...types: ToolbarTypes[]): MessageToolbar[] {
    return types.map((type) => this.toolbars[type]);
  }

  public async createForPreview() {
    const pkg = this._create('preview');

    pkg.toolbars = this._loadToolbars();

    pkg.enabledActions = {
      message: false,
      next: false,
      shareMessageThroughPromotion: false,
      addThroughPromotion: false,
      dateTimePicker: false,
      uri: false,
      postback: false,
    };

    pkg.enableQuickReplies = false;

    return pkg;
  }

  public async createForEmbeddedPrivateMessage() {
    const pkg = this._create('embedded-private-message');

    pkg.toolbars = this._loadToolbars(
      'text',
      'image',
      'cardsWithoutCreate',
      'posterWithoutCreate',
    );

    pkg.enabledActions = {
      message: true,
      next: false,
      shareMessageThroughPromotion: false,
      addThroughPromotion: false,
      dateTimePicker: false,
      uri: true,
      postback: false,
    };

    pkg.enableQuickReplies = false;

    return pkg;
  }

  public async createForFlow(data?: PackageFetchForSendNode): Promise<Package> {
    const pkg = this._create('flow');

    pkg.toolbars = this._loadToolbars(
      'text',
      'image',
      'cards',
      'poster',
      'coupon',
      'flex',
      'audio',
      'cannedMessage',
    );

    pkg.enabledActions = {
      message: true,
      next: true,
      shareMessageThroughPromotion: true,
      addThroughPromotion: false,
      dateTimePicker: true,
      uri: true,
      postback: true,
    };

    if (data) {
      pkg.id = data.id;
      pkg.name = data.name;
      pkg.tags = await this.convertTags(data.tagIds);
      pkg.quickReplies = data.quickReplies;
      pkg.messages = await this.convertMessages(data.records);
      pkg.oriMessages = pkg.messages.slice();
    }

    return pkg;
  }

  public async createForBroadcast(
    detail?: PackageFetchForBroadcast,
  ): Promise<Package> {
    const pkg = this._create('broadcast');

    pkg.toolbars = this._loadToolbars(
      'text',
      'image',
      'cards',
      'poster',
      'coupon',
      'flex',
      'audio',
      'cannedMessage',
    );

    pkg.enabledActions = {
      message: true,
      next: false,
      shareMessageThroughPromotion: true,
      addThroughPromotion: false,
      dateTimePicker: true,
      uri: true,
      postback: true,
    };

    pkg.enableQuickReplies = false;

    if (detail) {
      pkg.id = detail.id;
      pkg.name = detail.name;
      pkg.source = detail;
      pkg.tags = await this.convertTags(detail.tagIds);
      pkg.quickReplies = detail.quickReplies;
      pkg.messages = await this.convertMessages(detail.records);
      pkg.oriMessages = pkg.messages.slice();
      pkg.reservation = detail.reservation;
      pkg.status = detail.status;

      const group = await Group.fromJSON(detail.condition, {
        tagService: this._tagService,
        profileService: this._profileService,
        propertyConfigService: this._propertyConfigService,
        botService: this._botService,
        filterService: this._filterService,
      });

      pkg.rules = group.rules;
    }

    return pkg;
  }

  public async createForPromotionChannel(
    data?: PackageFetchForPromotionChannel,
  ) {
    const pkg = this._create('promotion-channel');

    pkg.toolbars = this._loadToolbars(
      'text',
      'image',
      'cards',
      'poster',
      'coupon',
      'flex',
      'audio',
      'cannedMessage',
    );

    pkg.enabledActions = {
      message: true,
      next: false,
      shareMessageThroughPromotion: true,
      addThroughPromotion: true,
      dateTimePicker: true,
      uri: true,
      postback: true,
    };

    if (data) {
      pkg.id = data.id;
      pkg.messages = await this.convertMessages(data.records);
      pkg.oriMessages = pkg.messages.slice();
    }

    return pkg;
  }

  public async createForTicketMessage() {
    const pkg = this._create('ticket-message');

    pkg.toolbars = this._loadToolbars(
      'text',
      'image',
      'cards',
      'poster',
      'flex',
      'cannedMessage',
    );

    pkg.enableQuickReplies = false;

    const message = new CardsMessage();

    const card = new Card(
      null,
      $localize`恭喜你獲得獎品`,
      $localize`請點擊下方連結進行兌換`,
      [new Button.Uri($localize`兌換`, '${url}')],
    );
    message.content.cards.push(card);
    pkg.add(message);
    return pkg;
  }

  public createForCustomerService() {
    const pkg = this._create('customer-service');

    pkg.toolbars = this._loadToolbars('text', 'image', 'cannedMessage');

    pkg.enabledActions = {
      message: true,
      next: false,
      shareMessageThroughPromotion: false,
      addThroughPromotion: false,
      dateTimePicker: false,
      uri: true,
      postback: false,
    };

    pkg.enableEditText = true;
    pkg.enableReplyText = true;
    pkg.enableQuickReplies = false;

    return pkg;
  }

  public createForCannedMessage() {
    const pkg = this._create('canned-message');

    pkg.toolbars = this._loadToolbars(
      'text',
      'image',
      'cards',
      'poster',
      'coupon',
      'flex',
      'audio',
    );

    pkg.enabledActions = {
      message: true,
      next: false,
      shareMessageThroughPromotion: false,
      addThroughPromotion: false,
      dateTimePicker: false,
      uri: true,
      postback: false,
    };

    pkg.enableQuickReplies = false;

    return pkg;
  }

  protected async convertMessages(records: Content.Any[]) {
    return await Bluebird.map(records, (record) =>
      this._messageFactoryService.createFromContent(record),
    );
  }

  protected async convertTags(tagIds: number[]) {
    const tags = await firstValueFrom(this._tagService.all$);
    const tagMap = arrayToObject(tags, 'id');
    return tagIds.map((tagId) => tagMap[tagId]);
  }

  private _onCannedMessageClick(message: CannedMessageDto, pkg: Package) {
    this._matConnectedDialog.open(CannedMessagePreviewComponent, {
      backdropClass: 'cs-message-preview-backdrop',
      panelClass: 'cs-message-preview',
      data: {
        name: message.name,
        cannedMessageId: message.cannedMessageId,
        editor: pkg,
      },
    });
    return null;
  }
}
