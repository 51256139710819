import {
  effect,
  Signal,
  signal,
  TemplateRef,
  WritableSignal,
} from '@angular/core';
import { ExposedPromise } from '@ay/util';
import Bluebird from 'bluebird';
import { injectorRef } from './injector-ref';

export enum AsyncJobStatusEnum {
  LOADING = 0,
  SUCCESS = 1,
  EMPTY = 2,
  ERROR = 3,
}

export const { LOADING, SUCCESS, EMPTY, ERROR } = AsyncJobStatusEnum;

// 如果 fn 回傳的是 undefined ，狀態是 LOADING
// 如果回傳的是 null / 空字串 / 空陣列，狀態是 EMPTY
// ** 如果回傳是 0 狀態是 SUCCESS
export function asyncJob<RESULT, ARGS extends readonly unknown[]>(
  fn: (...args: ARGS) => null | undefined | PromiseLike<RESULT | null> | RESULT,
  ...signals: [...SignalArray<ARGS>]
) {
  // status 的 LOADING 實作了 300ms 的防抖動機制，但這會讓 toPromise 不精確，所以多一個 realStatus，用來記錄真正的狀態
  const realStatus$ = signal<AsyncJobStatusEnum>(LOADING);
  const status$ = signal<AsyncJobStatusEnum>(LOADING);
  const data$ = signal<RESULT>(undefined as any);
  const error$ = signal<any>(undefined);
  const version = signal(0);
  const asyncJob = new AsyncJob(
    realStatus$,
    status$,
    data$,
    error$,
    () => effectRef?.destroy(),
    version,
  );

  let jonId = 0;
  const effectRef = effect(
    async () => {
      version();
      const statusList = convertToStatusList<ARGS>(signals);

      // 如果有任何一個 AsyncJob 的參數是 ERROR，就直接回傳第一個 ERROR
      const withError = statusList.includes(ERROR);
      if (withError) {
        const error = findFirstError<ARGS>(signals);
        realStatus$.set(ERROR);
        status$.set(ERROR);
        error$.set(error);
        return;
      }

      // 如果有任何一個 AsyncJob 的參數是 LOADING，就直接回傳 LOADING
      const withLoading = statusList.includes(LOADING);
      if (withLoading) {
        realStatus$.set(LOADING);
        status$.set(LOADING);
        return;
      }

      const args = convertToArgs<ARGS>(signals);

      // 如果有任何一個參數是 undefined，就直接回傳 LOADING
      const withUndefined = args.includes(undefined);
      if (withUndefined) {
        realStatus$.set(LOADING);
        status$.set(LOADING);
        return;
      }

      // 紀錄當前的 job id，如果回應時的 job id 不一樣，就不要更新
      const currentJobId = ++jonId;

      let timer: number = 0;
      try {
        // 300ms 之後才顯示 LOADING，避免閃爍
        realStatus$.set(LOADING);
        timer = window.setTimeout(() => status$.set(LOADING), 300);
        let result$ = fn(...(args as any));

        if (result$ instanceof AsyncJob) {
          result$ = result$.data();
        }
        let result = await result$;

        window.clearTimeout(timer);

        if (currentJobId !== jonId) {
          return;
        }

        // 如果回傳的結果是 undefined ， 代表還在載入中
        if (result$ === undefined) {
          realStatus$.set(LOADING);
          status$.set(LOADING);
          data$.set(undefined as RESULT);
          return;
        }

        // 如果回傳的結果是 null、空字串、空陣列 ，就直接回傳 EMPTY
        if (isEmpty(result)) {
          realStatus$.set(EMPTY);
          status$.set(EMPTY);
          data$.set(result!);
          return;
        }

        realStatus$.set(SUCCESS);
        status$.set(SUCCESS);
        data$.set(result!);
      } catch (error: any) {
        if (timer !== 0) {
          window.clearTimeout(timer);
        }

        if (currentJobId !== jonId) {
          return;
        }

        // if (asyncJob.errorHandler) {
        realStatus$.set(ERROR);
        status$.set(ERROR);
        error$.set(error);
        // } else {
        //   console.error('error1', error);
        //   throw error;
        // }
      }
    },
    { allowSignalWrites: true, injector: injectorRef.injector },
  );

  return asyncJob;
}

export class AsyncJob<R> implements Promise<R> {
  public errorHandler: TemplateRef<any> | null = null;

  public [Symbol.toStringTag]: string;

  private _ep: ExposedPromise<R> | undefined;

  public constructor(
    public readonly realStatus: WritableSignal<AsyncJobStatusEnum>,
    public readonly status: WritableSignal<AsyncJobStatusEnum>,
    public readonly data: Signal<R>,
    public readonly error: Signal<any>,
    public destroy: () => void,
    private readonly _version: WritableSignal<number>,
  ) {
    effect(
      () => {
        const status = this.realStatus();
        if (!this._ep || !this._ep.isPending()) return;

        if (status === SUCCESS || status === EMPTY) {
          this._ep.resolve(this.data());
        } else if (status === ERROR) {
          this._ep.reject(this.error());
        }
      },
      { allowSignalWrites: true },
    );
  }

  public reload() {
    this.realStatus.set(LOADING);
    this.status.set(LOADING);
    this._version.update((version) => version + 1);
  }

  public async toPromise() {
    // 處理當 AsyncJob 的 args 被 set value 時，立刻 toPromise 時會有時序不同的錯誤
    await Bluebird.delay(0);

    const status = this.realStatus();

    if (status === SUCCESS || status === EMPTY) {
      return this.data();
    } else if (status === ERROR) {
      throw this.error();
    } else {
      if (!this._ep) {
        this._ep = new ExposedPromise();
        this._ep.promise.finally(() => (this._ep = undefined));
      }

      return this._ep.promise;
    }
  }

  // 如果當下是 SUCCESS 或 EMPTY，會立刻執行 onfulfilled
  // 如果當下是 LOADING，會等到 LOADING 結束後才執行 onfulfilled
  // 如果當下是 ERROR，會立刻執行 onrejected
  public then<TResult1 = R, TResult2 = never>(
    onfulfilled?: (value: R) => TResult1 | PromiseLike<TResult1>,
    onrejected?: (reason: any) => TResult2 | PromiseLike<TResult2>,
  ): Promise<TResult1 | TResult2> {
    return this.toPromise().then(onfulfilled, onrejected);
  }

  public catch<TResult = never>(
    onrejected?: (reason: any) => TResult | PromiseLike<TResult>,
  ): Promise<R | TResult> {
    return this.toPromise().catch(onrejected);
  }

  public finally(onfinally?: () => void): Promise<R> {
    return this.toPromise().finally(onfinally);
  }
}

export type SignalArray<T> = {
  [K in keyof T]: Signal<T[K]> | AsyncJob<T[K]> | ISignal<T[K]>;
};

function convertToArgs<ARGS extends readonly unknown[]>(
  signals: [...SignalArray<ARGS>],
) {
  return signals.map((arg) => (arg instanceof AsyncJob ? arg.data() : arg()));
}

function convertToStatusList<ARGS extends readonly unknown[]>(
  signals: [...SignalArray<ARGS>],
) {
  return signals.map((signal) =>
    signal instanceof AsyncJob ? signal.status() : SUCCESS,
  );
}

function findFirstError<ARGS extends readonly unknown[]>(
  signals: [...SignalArray<ARGS>],
) {
  return signals.find((arg) =>
    arg instanceof AsyncJob ? arg.error() : undefined,
  );
}

function isEmpty<RESULT>(result: RESULT) {
  return (
    result === null ||
    result === '' ||
    (result instanceof Array && result.length === 0)
  );
}

export interface ISignal<T> {
  (): T;
}
