<div class="search-wrap">
  <mat-form-field legacyAppearance>
    <mat-label i18n>
      篩選<span *ngIf="targetType === 'package'; else tag">分類</span>
    </mat-label>
    <mat-icon matSuffix>search</mat-icon>
    <input matInput [(ngModel)]="keyword" />
  </mat-form-field>
</div>

<mat-flat-tree-picker [dataSource]="tags$" side="left">
  <mat-tree-picker-node
    *matTreePickerNodeDef="let tag"
    (selected)="selectTag(tag)"
    [class.active]="exists(tag)"
  >
    @if (onlyOneTag) {
      {{ tag.name }}
    } @else {
      <mat-checkbox color="primary" [ngModel]="exists(tag)">
        {{ tag.name }}
      </mat-checkbox>
    }
  </mat-tree-picker-node>
</mat-flat-tree-picker>

<mat-divider> </mat-divider>

<button mat-button (click)="createTag.next($event)" i18n>
  <mat-icon>add</mat-icon>
  <span>建立新</span>
  <span *ngIf="targetType === 'package'; else tag">分類</span>
</button>

<ng-template #tag><span i18n>標籤</span></ng-template>
