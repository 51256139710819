<div class="wrap" [style.width.px]="size" [style.height.px]="(size / 240) * 34">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 164 22"
    [ngClass]="theme"
  >
    <path
      class="text"
      d="M163.728 20.1192L155.346 11.7368H156.243C159.273 11.7368 162.017 9.27124 162.208 6.24779C162.423 2.84135 159.713 0 156.352 0H149.864C149.368 0 148.965 0.401778 148.965 0.898941V5.56997H137.873L135.337 0.497164C135.185 0.192217 134.873 0 134.533 0C134.193 0 133.881 0.192217 133.73 0.497164L126.223 15.5104H120.092C119.972 13.1199 118.414 11.1038 116.268 10.306C117.618 9.13827 118.432 7.36785 118.286 5.41822C118.054 2.3384 115.404 0 112.315 0H105.948C105.452 0 105.049 0.401778 105.049 0.898941V20.7797C105.049 21.2754 105.451 21.6786 105.948 21.6786H114.232C117.368 21.6786 119.936 19.2073 120.092 16.1101H125.922L123.799 20.3548C123.614 20.7262 123.682 21.1887 123.999 21.4575C124.176 21.6093 124.386 21.6786 124.59 21.6786C124.919 21.6786 125.237 21.4965 125.393 21.1815L134.53 2.90638L143.667 21.1815C143.888 21.6252 144.429 21.8044 144.872 21.5833C145.316 21.3621 145.497 20.8216 145.274 20.3779L138.169 6.1683H148.962V20.7465C148.962 21.1786 149.251 21.5746 149.675 21.6599C150.253 21.7755 150.759 21.3376 150.759 20.7797V11.7368H152.802L162.48 21.4142C162.655 21.589 162.884 21.6772 163.114 21.6772C163.392 21.6772 163.668 21.55 163.85 21.2957C164.093 20.9546 164.02 20.4155 163.724 20.1192H163.728ZM106.847 1.79644H112.341C114.46 1.79644 116.298 3.37753 116.491 5.48759C116.71 7.90259 114.807 9.94038 112.435 9.94038H106.847V1.79644ZM114.138 19.8808H106.847V11.7368H114.232C116.602 11.7368 118.507 13.7732 118.286 16.1896C118.093 18.2997 116.257 19.8808 114.136 19.8808H114.138ZM150.763 1.79644H156.258C158.377 1.79644 160.215 3.37753 160.407 5.48759C160.627 7.90259 158.723 9.94038 156.353 9.94038H150.765V1.79644H150.763Z"
    />
    <path
      class="text"
      d="M134.855 13.3454C133.229 13.1401 131.862 14.5073 132.067 16.1332C132.208 17.2403 133.101 18.1349 134.209 18.2751C135.835 18.4803 137.202 17.1131 136.997 15.4872C136.857 14.3802 135.964 13.4855 134.855 13.3454Z"
    />
    <path
      class="text"
      d="M78.6298 21.6786C74.0238 21.6786 70.2763 17.9311 70.2763 13.3251V0.898941C70.2763 0.401778 70.6781 0 71.1738 0C71.6695 0 72.0727 0.401778 72.0727 0.898941V13.3251C72.0727 16.9411 75.0138 19.8822 78.6298 19.8822C82.2457 19.8822 85.1868 16.9411 85.1868 13.3251V0.898941C85.1868 0.403223 85.5886 0 86.0858 0C86.5829 0 86.9847 0.401778 86.9847 0.898941V13.3251C86.9847 17.9311 83.2372 21.6786 78.6312 21.6786H78.6298Z"
    />
    <path
      class="text"
      d="M58.6927 10.8335C58.4629 10.8335 58.2331 10.7454 58.0568 10.5705L55.4105 7.92427C54.5333 7.04701 54.0506 5.88214 54.0506 4.64212C54.0506 3.4021 54.5333 2.23724 55.4105 1.35997C56.2878 0.482711 57.4527 0 58.6927 0C59.9327 0 61.0976 0.482711 61.9748 1.35997L64.6211 4.00766C64.9722 4.35885 64.9722 4.92683 64.6211 5.27803C64.2699 5.62922 63.7019 5.62922 63.3507 5.27803L60.7045 2.63034C60.1668 2.09271 59.4529 1.79644 58.6927 1.79644C57.9325 1.79644 57.2185 2.09271 56.6809 2.63034C56.1433 3.16797 55.847 3.88192 55.847 4.64212C55.847 5.40232 56.1433 6.11627 56.6809 6.6539L59.3271 9.30159C59.6783 9.65278 59.6783 10.2208 59.3271 10.572C59.1523 10.7468 58.9225 10.835 58.6912 10.835L58.6927 10.8335Z"
    />
    <path
      class="text"
      d="M58.6927 21.6787C57.2532 21.6787 55.899 21.1179 54.8816 20.1005L51.705 16.9238C51.3538 16.5726 51.3538 16.0046 51.705 15.6534C52.0561 15.3023 52.6241 15.3023 52.9753 15.6534L56.152 18.8301C56.8312 19.5094 57.733 19.8822 58.6927 19.8822C59.6523 19.8822 60.5556 19.5079 61.2334 18.8301C61.9127 18.1508 62.2856 17.249 62.2856 16.2894C62.2856 15.3297 61.9112 14.4264 61.2334 13.7486L58.0568 10.572C57.7056 10.2208 57.7056 9.6528 58.0568 9.3016C58.408 8.95041 58.976 8.95041 59.3271 9.3016L62.5038 12.4782C64.6052 14.5796 64.6052 17.9991 62.5038 20.1019C61.4863 21.1194 60.1321 21.6801 58.6927 21.6801V21.6787Z"
      fill="#1A1A1A"
    />
    <path
      class="text"
      d="M10.8393 21.6786C9.37669 21.6786 7.95602 21.3925 6.61918 20.826C5.32858 20.2797 4.1695 19.4992 3.17373 18.5035C2.17796 17.5091 1.39753 16.3486 0.851226 15.058C0.286138 13.7211 -0.00146484 12.3019 -0.00146484 10.8393C-0.00146484 9.37674 0.284693 7.95606 0.851226 6.61921C1.39753 5.32861 2.17796 4.16953 3.17373 3.17375C4.1695 2.17798 5.32858 1.39755 6.61918 0.851248C7.95747 0.286158 9.37669 0 10.8393 0C12.3019 0 13.7211 0.286158 15.0594 0.852694C16.35 1.399 17.5091 2.17943 18.5048 3.1752C18.856 3.52639 18.856 4.09437 18.5048 4.44557C18.1536 4.79676 17.5857 4.79676 17.2345 4.44557C16.4034 3.61455 15.4366 2.96275 14.3599 2.5075C13.2456 2.03635 12.0619 1.79644 10.8407 1.79644C9.6195 1.79644 8.43584 2.0349 7.32156 2.5075C6.24486 2.96275 5.278 3.61455 4.44698 4.44557C3.61597 5.27658 2.96417 6.24345 2.50892 7.32016C2.03777 8.43444 1.7993 9.61809 1.7993 10.8393C1.7993 12.0606 2.03777 13.2442 2.50892 14.3585C2.96417 15.4352 3.61597 16.4021 4.44698 17.2331C5.278 18.0641 6.24486 18.7159 7.32156 19.1712C8.43584 19.6423 9.6195 19.8808 10.8407 19.8808C12.0619 19.8808 13.2456 19.6423 14.3599 19.1712C15.4366 18.7159 16.4034 18.0641 17.2345 17.2331C18.0655 16.4021 18.7173 15.4352 19.1725 14.3585C19.5266 13.5203 19.7506 12.6415 19.8388 11.7368H14.1532C13.6575 11.7368 13.2543 11.335 13.2543 10.8379C13.2543 10.3407 13.656 9.93894 14.1532 9.93894H20.7796C21.2754 9.93894 21.6786 10.3407 21.6786 10.8379C21.6786 12.3005 21.3924 13.7197 20.8259 15.0565C20.2796 16.3471 19.4992 17.5062 18.5034 18.502C17.5091 19.4963 16.3485 20.2782 15.0579 20.8245C13.7211 21.3896 12.3019 21.6772 10.8393 21.6772V21.6786Z"
      fill="#1A1A1A"
    />
    <path
      class="text"
      d="M37.0097 21.6786C31.0337 21.6786 26.1705 16.8169 26.1705 10.8393C26.1705 4.8618 31.0322 0 37.0097 0C42.9873 0 47.849 4.8618 47.849 10.8393C47.849 16.8169 42.9873 21.6786 37.0097 21.6786ZM37.0097 1.79644C32.0237 1.79644 27.9669 5.85324 27.9669 10.8393C27.9669 15.8254 32.0237 19.8822 37.0097 19.8822C41.9958 19.8822 46.0526 15.8254 46.0526 10.8393C46.0526 5.85324 41.9958 1.79644 37.0097 1.79644Z"
    />
    <defs>
      <clipPath id="clip0_622_86748">
        <rect width="164" height="21.6786" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
