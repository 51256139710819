// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class ModuleChannelModel {
  static fetchAuthorizationUrl(
    type: any = 'GOSU BAR',
    redirectUri?: string,
    basicSearchId?: string,
  ): Promise<string> {
    return wsc.execute(
      '/ws/module-channel/fetchAuthorizationUrl',
      type,
      redirectUri,
      basicSearchId,
    ) as any;
  }

  static attach(state: string, code: string): Promise<string> {
    return wsc.execute('/ws/module-channel/attach', state, code) as any;
  }
}
// 033e6708b15ad771523f18c34d87544b85461ec761196e0ed9cfe39d128ecaba
