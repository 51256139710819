import { RuleOp } from '@ay-gosu/types';
import { ensureTheElementIsWhitelisted } from '@ay/util';
import { delay } from 'bluebird';
import { ruleJoin } from '../../ruleJoin';
import { PropertyRule } from '../property.class';

export class ListPropertyRule extends PropertyRule<string | string[]> {
  public static op = [
    'INCLUDE_ANY',
    'INCLUDE',
    'NOT_INCLUDE',
    'IN_LIST',
    'ALL_IN_LIST',
    'EXISTS',
    'NOT_EXISTS',
  ] as RuleOp[];

  public static arrayValueOps = ['IN_LIST', 'ALL_IN_LIST'];
  public static strValueOps = ['INCLUDE', 'NOT_INCLUDE', 'INCLUDE_ANY'];
  public static nullValueOps = ['EXISTS', 'NOT_EXISTS'];

  public constructor() {
    super();
    this.afterTypeChanged();
  }

  public async afterTypeChanged(isUserEvent = false) {
    this.op = ensureTheElementIsWhitelisted(this.op, ...ListPropertyRule.op);

    if (isUserEvent) {
      await delay(1);
    }
  }

  public toString() {
    switch (this.op) {
      case 'EXISTS':
        return $localize`[${this.property.name}]存在`;
      case 'NOT_EXISTS':
        return $localize`[${this.property.name}]不存在`;
      case 'INCLUDE':
        return $localize`[${this.property.name}]包含(完全比對)'${this.value}'`;
      case 'NOT_INCLUDE':
        return $localize`[${this.property.name}]不包含(完全比對)'${this.value}'`;

      case 'IN_LIST':
        return $localize`[${
          this.property.name
        }]有任一項在以下清單中，${ruleJoin(this.value as string[], '、')}`;

      case 'ALL_IN_LIST':
        return $localize`[${this.property.name}]全部都在以下清單中，${ruleJoin(
          this.value as string[],
          '、',
        )}`;
      case 'INCLUDE_ANY':
        return $localize`[${this.property.name}]任一項目中包含'${this.value}'`;
    }
  }
}
