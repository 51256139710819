import { APP_BASE_HREF, AsyncPipe } from '@angular/common';
import { Component, ElementRef, Inject, Input } from '@angular/core';
import { ElementRefDirective } from '@ay-gosu/ui/common/element-ref';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import urlJoin from 'url-join';
import { BypassSecurityService } from '../../service/bypass-security.service';
import { ConnectionService } from '../../service/connection.service';
import { ProfileService } from '../../service/profile.service';

@Component({
  selector: 'gosu-avatar',
  templateUrl: './avatar.component.html',
  standalone: true,
  imports: [ElementRefDirective, AsyncPipe],
  host: {
    class: 'inline-block w-8 h-8',
  },
})
export class AvatarComponent {
  private _errorTimes = 0;
  private _id: number = 0;

  public id$ = new ReplaySubject<number>(1);

  public get id(): number {
    return this._id;
  }

  @Input()
  public set id(profileId: number) {
    if (this._id === profileId) return;
    this._id = profileId;
    this.id$.next(profileId);
  }

  public profile$ = this.id$.pipe(
    switchMap((id) => this._profileService.get(id)),
    map((profile) => ({
      ...profile,
      picture: this._bypassSecurityService.bypassSecurityUrl(profile?.picture, [
        BypassSecurityService.isBypassSecurityUrl,
      ]),
    })),
  );

  public constructor(
    private readonly _profileService: ProfileService,
    private readonly _connectionService: ConnectionService,
    private readonly _bypassSecurityService: BypassSecurityService,
    @Inject(APP_BASE_HREF)
    private readonly _baseHref: string,
  ) {}

  public onProfilePictureError(elementRef: ElementRef) {
    let url = elementRef.nativeElement.src;
    this._errorTimes++;
    if (
      this._errorTimes < 3 &&
      !url.startsWith(this._connectionService.server)
    ) {
      url = urlJoin(
        this._connectionService.server,
        `profile/picture/${this.id}`,
      );
      elementRef.nativeElement.src = url.toString();
    } else {
      elementRef.nativeElement.src = urlJoin(this._baseHref, 'assets/user.png');
    }
  }

  public onProfilePictureLoaded(elementRef: ElementRef) {
    elementRef.nativeElement.style.opacity = '1';
  }
}
