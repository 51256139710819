// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { ResourceField } from './dto/field.dto';
import { ResourceDto } from './dto/resource.dto';

export class ResourceModel {
  /**
   * 建立外部資源
   * - 需要登入
   * - 檢核識別碼是否重複
   * - 回傳編號
   */
  static create(
    /** 外部資源的識別碼 */
    key: string,
    /** 外部資源的名稱 */
    name: string,
    /** 類型 */
    type?: 'csv' | 'api',
  ): Promise<number> {
    return wsc.execute('/ws/resource/create', key, name, type) as any;
  }

  /**
   * 更新外部資源資料
   * - 需要登入
   * - 檢測是否屬於同公司
   * - 刪除沒有傳送 key 過來的欄位
   * - 若 token 為 true ， 後端產生一組唯一的 token 給該 API 使用，並在 get 時會回傳
   * @param {number} id 外部資源的編號
   */
  static update(
    id: number,
    key?: string,
    name?: string,
    token?: boolean,
    fields?: ResourceField,
    /** 類型 */
    type?: 'csv' | 'api',
    /** api 網址 */
    url?: string,
    /** api method */
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD',
    /** api 的存取類型 */
    apiType?: 'schedule' | 'realtime',
    /** schedule （apiType 為 schedule 才會出現） */
    schedule?: string,
    /** 快取秒數 （apiType 為 realtime 才會出現） */
    cache?: number,
    /** api 參數 */
    properties?: { name: string; key: string; default: string }[],
    /** api header */
    headers?: { [key: string]: string },
    /** api GET method 設定 */
    get?: { [key: string]: string },
    /** api POST method 設定 */
    post?: { [key: string]: string },
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/resource/update',
      id,
      key,
      name,
      token,
      fields,
      type,
      url,
      method,
      apiType,
      schedule,
      cache,
      properties,
      headers,
      get,
      post,
    ) as any;
  }

  /**
   * 外部資源列表
   */
  static list(): Promise<ResourceDto[]> {
    return wsc.execute('/ws/resource/list') as any;
  }

  /**
   * 移除 Resource
   * @param id resource id
   */
  static remove(id: number): Promise<boolean> {
    return wsc.execute('/ws/resource/remove', id) as any;
  }

  /**
   * 後端存取使用，免權限
   * 如果是 realtime & 有 resource.cache , 則儲存 cache 到 Redis
   **/
  static exec(id: number, customizeProperties: any = {}): Promise<any> {
    return wsc.execute('/ws/resource/exec', id, customizeProperties) as any;
  }

  /**
   * 單一外部資源詳細資訊（不包含資料）
   */
  static get(id: number): Promise<ResourceDto> {
    return wsc.execute('/ws/resource/get', id) as any;
  }
}
// c440a1b2aee26233e188bf9ce380eaead8812820a43b476bcda06bca7a20aaa6
