// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { InteractiveType } from './interactive-type.type';
import { QualificationDto } from './qualification.dto';

export class InteractiveDto {
  /** 任務抽獎遊戲編號，如果是 Fetch 時會有資料 */
  public id?: number;

  // 機器人編號
  public botId: number;

  // 名稱
  public name: string;

  // 開始時間
  public startAt: string;

  // 結束時間
  public endAt: string;

  // 活動類型
  public type: InteractiveType;

  // 任務設定
  public qualifications: QualificationDto[];

  /*
   * 每人抽獎次數上限
   */
  public limitTimesPerProfile: number;

  public hasRecord?: boolean;

  public isApproved?: boolean;
}
// 07d03f0c05ecab8d2f6c16962a9c658c2092fd80977d9c2dfd4d32c42b134a5e
