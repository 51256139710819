import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { SERVICE_NAME } from '@ay-gosu/ui/login/login.service';

@Injectable({ providedIn: 'root' })
export class BarTitleStrategy extends TitleStrategy {
  constructor(
    @Inject(SERVICE_NAME) private serviceName: string,
    private titleService: Title,
  ) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      this.titleService.setTitle(`${title}｜${this.serviceName}`);
    } else {
      this.titleService.setTitle(this.serviceName);
    }
  }
}
