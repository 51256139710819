import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'dl-reset-password-dialog',
    templateUrl: './reset-password.dialog.html',
    styleUrls: ['./reset-password.dialog.scss'],
    standalone: true,
    imports: [MatIcon, MatButton],
})
export class ResetPasswordDialog {
  public title: string = $localize`密碼設定不安全`;
  public content: string = $localize`您目前的密碼不符合密碼規則，請重新設定`;
  public confirm: string = $localize`重設密碼`;
  public companyId: number;

  public constructor(
    private _router: Router,
    private _matDialogRef: MatDialogRef<ResetPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { companyId: number },
  ) {
    this.companyId = data.companyId;
  }

  public submit() {
    this._router.navigateByUrl(
      `/reset-password/loggedIn?companyId=${this.companyId}`,
    );
    this._matDialogRef.close();
  }
}
