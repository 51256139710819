// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { PromotionChannelCreateRequest } from './dto/create-request.type';
import { PromotionChannelCreateSubRequest } from './dto/create-sub-request.type';
import { PromotionChannelFetchRankResponse } from './dto/fetch-rank-response.type';
import { PromotionChannelFetchResponse } from './dto/fetch-response.type';
import { FetchStatisticResponse } from './dto/fetch-statistic-response.type';
import { PromotionChannelListResponse } from './dto/list-response.type';
import { PromotionChannelUpdateRequest } from './dto/update-request.type';
import { PromotionChannelUpdateSubRequest } from './dto/update-sub-request.type';

export class PromotionChannelModel {
  static create(data: PromotionChannelCreateRequest): Promise<number> {
    return wsc.execute('/ws/promotion-channel/create', data) as any;
  }

  static list(
    page: any = 1,
    pageSize: any = 20,
  ): Promise<PromotionChannelListResponse> {
    return wsc.execute('/ws/promotion-channel/list', page, pageSize) as any;
  }

  static fetch(
    promotionChannelId: number,
  ): Promise<PromotionChannelFetchResponse> {
    return wsc.execute(
      '/ws/promotion-channel/fetch',
      promotionChannelId,
    ) as any;
  }

  static update(
    promotionChannelId: number,
    data: PromotionChannelUpdateRequest,
  ): Promise<void> {
    return wsc.execute(
      '/ws/promotion-channel/update',
      promotionChannelId,
      data,
    ) as any;
  }

  static createSub(
    promotionChannelId: number,
    data: PromotionChannelCreateSubRequest,
  ): Promise<number> {
    return wsc.execute(
      '/ws/promotion-channel/createSub',
      promotionChannelId,
      data,
    ) as any;
  }

  static updateSub(
    promotionChannelId: number,
    subPromotionChannelId: number,
    data: PromotionChannelUpdateSubRequest,
  ): Promise<void> {
    return wsc.execute(
      '/ws/promotion-channel/updateSub',
      promotionChannelId,
      subPromotionChannelId,
      data,
    ) as any;
  }

  static fetchRank(
    promotionChannelId: number,
    start: string,
    end: string,
    page: number,
    pageSize: number,
    filter:
      | { name: string }
      | { profileId: number }
      | { tagIds: number[] }
      | { subPromotionIds: number[] }
      | {},
  ): Promise<PromotionChannelFetchRankResponse> {
    return wsc.execute(
      '/ws/promotion-channel/fetchRank',
      promotionChannelId,
      start,
      end,
      page,
      pageSize,
      filter,
    ) as any;
  }

  static fetchStatistic(
    promotionChannelId: number,
    start: string,
    end: string,
    filter:
      | { name: string }
      | { profileId: number }
      | { tagIds: number[] }
      | { subPromotionIds: number[] }
      | {},
  ): Promise<FetchStatisticResponse> {
    return wsc.execute(
      '/ws/promotion-channel/fetchStatistic',
      promotionChannelId,
      start,
      end,
      filter,
    ) as any;
  }
}
// 42f21db774fccb84c89fae63d36894100909a4ae079f46c41d6920f84918e4eb
