import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { MomentPipe } from '../../pipe/moment.pipe';
import { RecordPipe } from '../../pipe/record.pipe';
import { BaseComponent } from '../base/base.component';
import { AnnouncementMessage } from './announcement.message';

@Component({
  selector: 'ms-announcement',
  templateUrl: './announcement.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package', 'mode', 'message'],
  standalone: true,
  imports: [MatTooltip, MomentPipe, RecordPipe, AsyncPipe],
  host: {
    class: 'text-center w-full flex flex-row !justify-center',
  },
})
export class AnnouncementComponent extends BaseComponent<AnnouncementMessage> {
  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
}
