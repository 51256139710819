<div class="container">
  <mat-icon>warning</mat-icon>

  <h6 class="title">{{ title }}</h6>

  <p class="content">{{ content }}</p>

  <div class="buttons">
    <button mat-raised-button color="primary" (click)="submit()">
      {{ confirm }}
    </button>
  </div>
</div>
