// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { PackageSendImmediately } from '../package/dto/send-immediately.type';
import { PackageSendProcess } from '../package/dto/send-process.type';
import { wsc } from '../wsc';
import {
  CreateBoxfulTicketDto,
  CreateCodeTicketDto,
  FetchCodeDto,
  FetchSentCodeDto,
  FetchUnusedCodeDto,
  ListProfileTicketRecordResponseDto,
  TicketListResponseBoxfulRowDto,
  TicketListResponseCodeRowDto,
  TicketListResponseDto,
  UpdateBoxfulTicketDto,
  UpdateTicketDto,
} from '@ay-gosu/types';
import { Observable } from 'rxjs';

export class TicketMachineModel {
  static create(
    data: CreateCodeTicketDto | CreateBoxfulTicketDto,
  ): Promise<number> {
    return wsc.execute('/ws/ticket-machine/create', data) as any;
  }

  static list(): Promise<TicketListResponseDto> {
    return wsc.execute('/ws/ticket-machine/list') as any;
  }

  static fetch(
    ticketId: number,
  ): Promise<TicketListResponseCodeRowDto | TicketListResponseBoxfulRowDto> {
    return wsc.execute('/ws/ticket-machine/fetch', ticketId) as any;
  }

  static fetchCodes(params: {
    ticketId: number;
    page?: number;
    pageSize?: number;
  }): Promise<FetchCodeDto> {
    return wsc.execute('/ws/ticket-machine/fetchCodes', params) as any;
  }

  static fetchCode(params: {
    ticketId: number;
    ticketCodeId: number;
  }): Promise<{ code: string; url: string }> {
    return wsc.execute('/ws/ticket-machine/fetchCode', params) as any;
  }

  static update(
    ticketId: number,
    data: UpdateBoxfulTicketDto | UpdateTicketDto,
  ): Promise<any> {
    return wsc.execute('/ws/ticket-machine/update', ticketId, data) as any;
  }

  static createCodes(
    ticketId: number,
    codes: { url: string; code: string }[],
  ): Promise<{
    success: number;
    fail: number;
  }> {
    return wsc.execute(
      '/ws/ticket-machine/createCodes',
      ticketId,
      codes,
    ) as any;
  }

  static delete(ticketId: number): Promise<void> {
    return wsc.execute('/ws/ticket-machine/delete', ticketId) as any;
  }

  static deleteCodes(ticketId: number, ticketCodeIds: number[]): Promise<void> {
    return wsc.execute(
      '/ws/ticket-machine/deleteCodes',
      ticketId,
      ticketCodeIds,
    ) as any;
  }

  static fetchUnusedCodes(ticketId: number): Promise<FetchUnusedCodeDto[]> {
    return wsc.execute('/ws/ticket-machine/fetchUnusedCodes', ticketId) as any;
  }

  static fetchSentCodes(ticketId: number): Promise<FetchSentCodeDto[]> {
    return wsc.execute('/ws/ticket-machine/fetchSentCodes', ticketId) as any;
  }

  static assignCode(
    ticketId: number,
    ticketCodeId: number,
    profileId: number,
  ): Promise<void> {
    return wsc.execute(
      '/ws/ticket-machine/assignCode',
      ticketId,
      ticketCodeId,
      profileId,
    ) as any;
  }

  static fetchTicketListByProfileId(
    profileId: number,
    page: number,
    pageSize: number,
  ): Promise<ListProfileTicketRecordResponseDto> {
    return wsc.execute(
      '/ws/ticket-machine/fetchTicketListByProfileId',
      profileId,
      page,
      pageSize,
    ) as any;
  }

  static multipleAssign(
    packageId: number,
    data: PackageSendImmediately,
    ticketId: number,
  ): Observable<PackageSendProcess> {
    return wsc.subscribe(
      '/ws/ticket-machine/multipleAssign',
      packageId,
      data,
      ticketId,
    ) as any;
  }

  static resendTicketMessage(
    packageId: number,
    data: { profileId: number; code: string; url: string },
  ): Observable<PackageSendProcess> {
    return wsc.subscribe(
      '/ws/ticket-machine/resendTicketMessage',
      packageId,
      data,
    ) as any;
  }
}
// 8adc105584da5e82a5ae2d04012d576d402dba8dc17b2dc77d92d2b8bb82eb8d
