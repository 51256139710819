import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { delay, filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PageLoadingService {
  /** color */
  private _color: string;

  @Output()
  public colorChange: EventEmitter<string> = new EventEmitter<string>();

  public get color(): string {
    return this._color;
  }

  @Input()
  public set color(color: string) {
    if (this._color === color) return;
    this._color = color;
    this.colorChange.emit(this._color);
  }

  /** progress */
  private _progress: number = 50;

  @Output()
  public progressChange: EventEmitter<number> = new EventEmitter<number>();

  public get progress(): number {
    return this._progress;
  }

  @Input()
  public set progress(progress: number) {
    if (this._progress === progress) return;
    this._progress = progress;
    this.isComplete = this.progress >= 100;
    if (this.progress > 0 && !this.isComplete) this.display = true;
    this.progressChange.emit(this._progress);
  }

  /** complete */
  private _isComplete: boolean;

  @Output()
  public completeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get isComplete(): boolean {
    return this._isComplete;
  }

  @Input()
  public set isComplete(isComplete: boolean) {
    if (this._isComplete === isComplete) return;
    this._isComplete = isComplete;
    if (this._isComplete === true) this.progress = 100;
    this.completeChange.emit(this._isComplete);
  }

  /** display */
  private _display: boolean;

  @Output()
  public displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get display(): boolean {
    return this._display;
  }

  @Input()
  public set display(display: boolean) {
    if (this._display === display) return;
    this._display = display;
    this.displayChange.emit(this._display);
  }

  public complete() {
    this.progress = 0;
    this.display = true;
    this.isComplete = true;
  }

  public constructor() {
    this.completeChange
      .pipe(filter((complete) => complete))
      .pipe(delay(1000))
      .pipe(tap((next) => (this.display = false)))
      .pipe(delay(300))
      .subscribe((complete) => (this.progress = 0));
  }

  public expect(ms: number) {
    let i = 0;
    let times = ms / 300;
    let level = (100 - this.progress) / times;
    let lastProgress = this.progress;
    let timer = setInterval(() => {
      if (lastProgress != this.progress || i == times) {
        clearInterval(timer);
        return;
      }
      lastProgress = this.progress = Math.min(this.progress + level, 92);
      i++;
    }, 300);
  }
}
