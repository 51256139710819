import {
  APP_BASE_HREF,
  PlatformLocation,
  registerLocaleData,
} from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import zhHant from '@angular/common/locales/zh-Hant';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  TransferState,
  enableProdMode,
  makeStateKey,
} from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, TitleStrategy, provideRouter } from '@angular/router';
import { COMPANY_SERVICE } from '@ay-gosu/ui/login/company.service';
import {
  BASE_NAVIGATE_PATH,
  ENABLE_THIRD_PARTY_LOGIN,
  LOGIN_SERVICE,
  SERVICE_NAME,
} from '@ay-gosu/ui/login/login.service';
import * as Sentry from '@sentry/angular-ivy';
import {
  AngularSvgIconConfig,
  SvgHttpLoader,
  SvgLoader,
  provideAngularSvgIcon,
} from 'angular-svg-icon';
import 'moment/locale/zh-tw';
import { Observable } from 'rxjs';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { GosuErrorHandler } from './app/error-handler';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from './app/material/tooltip';
import { MAT_TREE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from './app/material/tree-picker/scroll-strategy';
import { registeNodes } from './app/pages/flow/register';
import { getDutchPaginatorIntl } from './app/paginator';
import { PartnerProvider } from './app/provider';
import { CompanyService } from './app/service/company.service';
import { ConnectionService } from './app/service/connection.service';
import { GTM_ID } from './app/service/google-tag-manager.service';
import { I18nService } from './app/service/i18n.service';
import { BackstageLoginService } from './app/service/login.service';
import { TokenService } from './app/service/token.service';
import { BarTitleStrategy } from './app/title.strategy';
import { environment } from './environments/environment';

registerLocaleData(zhHant);

if (environment.production) {
  enableProdMode();
}

export const angularSvgIconConfig: AngularSvgIconConfig = {
  loader: {
    provide: SvgLoader,
    useFactory: svgLoaderFactory,
    deps: [HttpClient, TransferState],
  },
};

export function svgLoaderFactory(
  http: HttpClient,
  transferState: TransferState,
) {
  return new SvgBrowserLoader(transferState, http);
}

export class SvgBrowserLoader implements SvgLoader {
  public constructor(
    private _transferState: TransferState,
    private _http: HttpClient,
  ) {}

  public getSvg(url: string): Observable<string> {
    const key = makeStateKey<number>('transfer-svg:' + url);
    const data = this._transferState.get(key, null);
    // First we are looking for the translations in transfer-state, if none found, http load as fallback
    if (data) {
      return new Observable((observer) => {
        observer.next(data.toString());
        observer.complete();
      });
    } else {
      return new SvgHttpLoader(this._http).getSvg(url);
    }
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(),
    provideAngularSvgIcon(angularSvgIconConfig),

    { provide: TitleStrategy, useClass: BarTitleStrategy },
    TokenService,
    ConnectionService,
    I18nService,

    {
      provide: LOCALE_ID,
      useValue: 'zh-Hant',
    },
    {
      provide: GTM_ID,
      useValue: environment.gtmId,
    },
    {
      provide: BASE_NAVIGATE_PATH,
      useValue: environment.baseNavigatePath,
    },
    {
      provide: ENABLE_THIRD_PARTY_LOGIN,
      useValue: environment.enableThirdPartyLogin,
    },
    {
      provide: SERVICE_NAME,
      useValue: environment.serviceName,
    },
    {
      provide: ErrorHandler,
      useClass: GosuErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        registeNodes();
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    PartnerProvider.landing,
    PartnerProvider.copyright,

    // Material
    provideMomentDateAdapter(),
    MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_TREE_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'zh-tw',
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY/MM/DD',
        },
        display: {
          dateInput: 'YYYY/MM/DD',
          monthLabel: 'MMM',
          monthYearLabel: 'YYYY年 MMM',
          dateA11yLabel: 'YYYY/MM/DD',
          monthYearA11yLabel: 'YYYY年 MMMM',
        },
      },
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) =>
        platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500 },
    },
    {
      provide: MatPaginatorIntl,
      useValue: getDutchPaginatorIntl(),
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: { formFieldAppearance: 'fill' },
    },
    { provide: 'MESSAGE_CONFIG', useValue: { enableNextButton: true } },
    {
      provide: LOGIN_SERVICE,
      useClass: BackstageLoginService,
    },
    {
      provide: COMPANY_SERVICE,
      useClass: CompanyService,
    },
    { provide: 'SERVER_URLS', useValue: environment.serverUrl },
    {
      provide: 'LOCAL_STORAGE_KEY',
      useValue: environment.loginTokenKey,
    },
  ],
}).catch((err) => console.error(err));
