<div class="container">
  <h2 class="mat-h2" i18n>
    你確定要刪除<span class="text-primary px-2">{{ name }}</span>
  </h2>

  <form [formGroup]="form">
    <div class="flex flex-col gap-8">
      <p i18n>
        為了確保資料的安全性，請輸入確認碼
        <span
          class="user-select-none bg-primary py-1 px-2 rounded letter-spacing-1"
        >
          {{ checkCode }}
        </span>
      </p>

      <mat-form-field class="w-full" legacyAppearance>
        <mat-label i18n>確認碼</mat-label>
        <input matInput formControlName="checkCode" />
        <mat-error
          *ngIf="
            checkCodeInputControl.errors &&
            checkCodeInputControl.errors['comparison-string']
          "
          i18n
        >
          輸入的確認碼不符
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="footer flex flex-row !justify-end">
  <button
    mat-flat-button
    type="submit"
    color="warn"
    [disabled]="form.invalid"
    i18n
    (click)="onSubmit()"
  >
    確定刪除
  </button>
</div>
