import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { LogModel } from '@ay-gosu/server-shared';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BotService } from '../../service/bot.service';
import { TokenService } from '../../service/token.service';

@Injectable({ providedIn: 'root' })
export class ActivityRecordService {
  public page$ = new BehaviorSubject<PageEvent>({
    pageSize: 20,
    pageIndex: 0,
    previousPageIndex: 0,
    length: 100,
  });

  public list$ = combineLatest([this.token.rxAccount$, this.page$]).pipe(
    mergeMap(([account, page]) =>
      LogModel.fetchLog(page.pageIndex + 1, page.pageSize, {}),
    ),
    mergeMap(async (list) => {
      const bots = await firstValueFrom(this.botService.all$);
      list.data.map(
        (log) => (log['bot'] = bots.find((bot) => bot.id === log.botId)),
      );
      return list;
    }),
  );

  public constructor(
    public token: TokenService,
    public botService: BotService,
  ) {}
}
