import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Content } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import { FileSizePipe } from '../../pipe/file-size.pipe';
import { VideoRecorderDialog } from './recorder/recorder.dialog';
import { VideoUploaderDialog } from './uploader/uploader.dialog';
import { VideoMessage } from './video.message';

const VIDEO_SIZE_LIMIT = 100 * 1024 * 1024;
@Injectable({
  providedIn: 'root',
})
export class VideoMessageService {
  public constructor(
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _fileSizePipe: FileSizePipe,
    private readonly _matSnackBar: MatSnackBar,
  ) {}

  public async createFromRecorder(): Promise<VideoMessage | null> {
    const dialogRef = this._matConnectedDialog.open(VideoRecorderDialog, {});
    const src = await firstValueFrom(dialogRef.afterClosed());

    if (!src) return null;

    const content = new Content.Video(src);
    const message = new VideoMessage();
    await message.loadFromContent(content);

    return message;
  }

  public async createFromUploader(file: File): Promise<VideoMessage | null> {
    if (file.size > VIDEO_SIZE_LIMIT) {
      const size = this._fileSizePipe.transform(VIDEO_SIZE_LIMIT, 'Byte');
      this._matSnackBar.open($localize`檔案大小最大只能 ${size}`);
      return;
    }

    const dialogRef = this._matConnectedDialog.open(VideoUploaderDialog, {
      disableClose: true,
      data: file,
    });

    const src = await firstValueFrom(dialogRef.afterClosed());

    if (!src) return null;

    const content = new Content.Video(src);
    const message = new VideoMessage();
    await message.loadFromContent(content);
    return message;
  }
}
