@if (mode === 'EDIT') {
  <div
    contenteditable
    class="textarea edit"
    [ngClass]="{ receive: message.direction === 'Receive' }"
    #textarea
    [innerHTML]="message.content.content | escape"
    (blur)="blur($event)"
    (mousedown)="mousedown($event)"
    (contextmenu)="stop($event)"
  ></div>
} @else {
  <div
    class="reply-text"
    [ngClass]="{ receive: message.direction === 'Receive' }"
    (click)="clickReply()"
  >
    <div class="top">
      <div class="info">
        <mat-icon>reply</mat-icon>
        <div
          class="textarea"
          #textarea
          [innerHTML]="message.content.repliedContent | nl2br"
        ></div>
      </div>
    </div>
    <div
      class="textarea"
      #textarea
      [innerHTML]="message.content.content | nl2br"
    ></div>
  </div>
}

<div
  class="arrow"
  [ngClass]="{ receive: message.direction === 'Receive' }"
></div>
