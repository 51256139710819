import { NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ElementRefDirective } from '@ay-gosu/ui/common/element-ref';

@Component({
  selector: 'gosu-landing-brand',
  templateUrl: './landing-brand.component.html',
  styleUrls: ['./landing-brand.component.scss'],
  standalone: true,
  imports: [NgIf, MatIcon, MatButton, ElementRefDirective],
})
export class LandingBrandComponent {
  @Input()
  public title: string = '';

  @Input()
  public description: string = '';

  @Input()
  public image: string = null;

  @Input()
  public icon: string = null;

  @Input()
  public callToActionIcon = 'add';

  @Input()
  public callToActionLabel: string = '';

  @Output()
  public callToAction = new EventEmitter<CallToActionClickEvent>();

  public clickCallToActionButton(event: MouseEvent, elementRef: ElementRef) {
    this.callToAction.next({ ...event, elementRef });
  }
}

export type CallToActionClickEvent = MouseEvent & { elementRef: ElementRef };
