import { NgIf } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { ElementRefDirective } from '@ay-gosu/ui/common/element-ref';
import { firstValueFrom } from 'rxjs';
import { EmptyResponseError } from '../../../../../util/empty-response-error';
import { ImagePickerDialog } from '../../../../dialog/image';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { FormComponent } from '../form.component';
import { ReplyNode } from './class';

@Component({
  selector: 'flow-reply-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatOption,
    MatCheckbox,
    FlexModule,
    MatInput,
    NgIf,
    ElementRefDirective,
    LegacyAppearanceDirective,
  ],
})
export class ReplyFormComponent extends FormComponent<ReplyNode> {
  public width = 600;

  public constructor(
    public elementRef: ElementRef,
    private _matConnectedDialog: MatConnectedDialog,
  ) {
    super(elementRef);
  }

  public async pickImage(elementRef: ElementRef) {
    try {
      const dialog = this._matConnectedDialog.open(ImagePickerDialog, {
        elementRef,
        data: { url: this.node.imgUrl, useCode: true },
      });
      const instance = dialog.componentInstance;
      let res = await firstValueFrom(dialog.afterClosed());
      if (res === undefined) return null;
      this.node.imgUrl = res;
    } catch (error) {
      if (error instanceof EmptyResponseError) return;
      throw error;
    }
  }
}
