<!-- 只有在組織選擇頁面，才會顯示登入的帳號資訊 -->
@if (!matDialogRef) {
  <div class="user-info">
    <div class="header">
      <gosu-logo theme="yellow" [size]="280"></gosu-logo>
      <p class="mat-h2" i18n>選擇要登入的組織，或免費建立</p>
    </div>
    <div class="flex flex-row !align-center !justify-center">
      <h1 class="flex-1" *asyncJob="account$; let account">
        {{ account.accountName }}
      </h1>

      <div class="wrap">
        <button mat-icon-button class="logout" (click)="tokenService.logout()">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </div>
    </div>
  </div>
}

<div class="title">
  <mat-label i18n>選擇組織</mat-label>
</div>

<mat-action-list class="select-company min-h-[200px]">
  <ng-container *asyncJob="companies$; let companies">
    @for (company of companies; track company.id) {
      <button mat-button (click)="selectCompany(company)">
        <div class="wrap">
          <div class="name">{{ company.name }}</div>
          <div class="ext">
            <div class="id">#{{ company.id }}</div>
            <p class="created-at" i18n>{{ company.createdAt | fromNow }}建立</p>
          </div>
        </div>
      </button>
    }
  </ng-container>
</mat-action-list>

<button
  mat-button
  color="primary"
  class="create"
  (click)="createCompany()"
  i18n
>
  <mat-icon>add</mat-icon>
  建立組織
</button>
