import { NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { ExtraValidatorError } from '../basic-dialog.service';

@Component({
  selector: 'dl-rename-dialog',
  templateUrl: './rename.dialog.html',
  styleUrls: ['./rename.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    NgFor,
    MatError,
    NgIf,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class RenameDialog implements OnDestroy, OnInit {
  //#region name
  private _name: string = '';

  public name$ = new Subject<string>();

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    if (this._name === name) return;
    this._name = name;
    this.name$.next(this._name);
  }
  //#endregion name

  @Input()
  public required: string = $localize`請輸入名稱`;

  // 預設false
  @Input()
  public hasExtraValidator: boolean = false;

  @Input()
  public extraValidator: ValidatorFn[] = [];

  @Input()
  public extraValidatorError: ExtraValidatorError[] = [];

  public nameInputControl = new FormControl(this.name, Validators.required);

  public form = new FormGroup({ name: this.nameInputControl });

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private _matDialogRef: MatDialogRef<RenameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string },
  ) {
    this.name = data.name;
  }

  public ngOnInit(): void {
    if (this.hasExtraValidator) {
      this.nameInputControl.addValidators(this.extraValidator);
    }

    this.extraValidatorError.push({ name: 'required', text: this.required });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _updateInputControlWhenOriNameChanged = this.name$
    .pipe(takeUntil(this._destroy$))
    .subscribe((name) => this.nameInputControl.setValue(name));

  public onSubmit() {
    this._matDialogRef.close(this.nameInputControl.value);
  }
}
