<div class="w-full h-full rounded-full bg-primary/30">
  @if (profile$ | async; as profile) {
    <img
      class="bg-white/30 transition-all duration-300 w-full h-full rounded-full"
      [src]="profile.picture"
      element-ref
      #elementRef="elementRef"
      (load)="onProfilePictureLoaded(elementRef)"
      (error)="onProfilePictureError(elementRef)"
      alt="使用者頭像"
    />
  }
</div>
