<ng-container *ngIf="service.list$ | async; let list">
  <mat-list>
    <mat-list-item *ngFor="let log of (service.list$ | async)?.data">
      <span class="level" [style.background-color]="colors[log.level]">
        {{ levels[log.level] }}
      </span>
      <div fxFlex="1 0 0">
        <h3 class="message">{{ log.msg }}</h3>

        <div class="info">
          <span *ngIf="log.accountId" class="account">
            <mat-icon>recent_actors</mat-icon> {{ log.accountId | account }}
          </span>
          <span *ngIf="log.botId" class="bot">
            <ng-container [ngSwitch]="log['bot'].type">
              <fa-icon class="platform" [icon]="faLine" *ngSwitchCase="'Line'">
              </fa-icon>
              <fa-icon
                class="platform"
                [icon]="faFacebook"
                *ngSwitchCase="'Facebook'"
              >
              </fa-icon>
              <fa-icon class="platform" [icon]="faStar" *ngSwitchCase="'MDBS'">
              </fa-icon>
            </ng-container>
            {{ log['bot'].name }}
          </span>
          <span *ngIf="log.profileId" class="profile">
            <mat-icon>people</mat-icon>
            {{ log.profileId | profile }}
          </span>
          <span
            class="flow"
            [routerLink]="['/flow/', log.flowId]"
            *ngIf="log.flowId"
          >
            <mat-icon>call_split</mat-icon> {{ log.flowId | flow }}
          </span>
          <span class="node" *ngIf="log.nodeId">
            <mat-icon>linear_scale</mat-icon> {{ log.nodeId | node }}
          </span>
        </div>
        <div
          class="created-at"
          [matTooltip]="log.createdAt | date: 'yyyy/MM/dd HH:mm:ss'"
        >
          {{ log.createdAt | fromNow }}
        </div>
      </div>
    </mat-list-item>
    <mat-list-item>
      <button
        mat-button
        id="show-all-activity-record"
        [routerLink]="['/log']"
        i18n
      >
        顯示全部
      </button>
    </mat-list-item>
  </mat-list>
</ng-container>
