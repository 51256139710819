const RGBA_REGEXP =
  /rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d\.]+)\s*\)/;

const RGB_REGEXP = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/;

const HSL_REGEXP = /^hsl\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/;

const HSLA_REGEXP =
  /^hsla\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d\.]+)\s*\)/;

export type Rgb = {
  /** 紅色，0 ~ 255 */
  red: number;
  /** 綠色，0 ~ 255 */
  green: number;
  /** 藍色，0 ~ 255 */
  blue: number;
  /** 透明度，0 ~ 1，null 表示不支援透明度 */
  alpha: number | null;
};

/** 色相、飽和度、明度 */
export type Hsb = {
  /** 色相，0 ~ 360 */
  hue: number;
  /** 飽和度，0 ~ 100 */
  saturation: number;
  /** 明度 ~ 100 */
  brightness: number;
  /** 透明度，0 ~ 1，null 表示不支援透明度 */
  alpha: number | null;
};

/** 色相、飽和度、亮度 */
export type Hsl = {
  /** 色相，0 ~ 360 */
  hue: number;
  /** 飽和度，0 ~ 100 */
  saturation: number;
  /** 亮度，0 ~ 100 */
  lightness: number;
  /** 透明度，0 ~ 1，null 表示不支援透明度 */
  alpha: number | null;
};

export class Color {
  public static toHex(anyType: string | Rgb | Hsb | Hsl): string {
    switch (Color.judgeFormat(anyType)) {
      case 'hex':
        return anyType as string;

      case 'rgb':
        return Color.rgbaToHex(anyType as Rgb);

      case 'hsb':
        return Color.hsbToHex(anyType as Hsb);

      case 'hsl':
        return Color.rgbaToHex(Color.hslToRgba(anyType as any));

      case 'rgb-string': {
        const rgba = Color.rgbaStringToRgba(anyType as string);
        return Color.rgbaToHex(rgba);
      }

      case 'hsl-string': {
        const rgba = Color.hslStringToRgba(anyType as string);
        return Color.rgbaToHex(rgba);
      }

      default:
        return '';
    }
  }

  /** 判斷顏色格式，回傳格式名稱，如果是 undefined 表示無法判斷 */
  public static judgeFormat(
    anyType: string | Rgb | Hsb | Hsl,
  ): 'rgb' | 'hsb' | 'hsl' | 'hex' | 'rgb-string' | 'hsl-string' | undefined {
    if (typeof anyType === 'string') {
      if (Color.isHex(anyType)) return 'hex';
      else if (Color.isRgbString(anyType)) return 'rgb-string';
      else if (Color.isHslString(anyType)) return 'hsl-string';
    } else if (anyType instanceof Object) {
      if ('red' in anyType) return 'rgb';
      else if ('brightness' in anyType) return 'hsb';
      else if ('light' in anyType) return 'hsl';
    } else {
      return undefined;
    }
  }

  /** 判斷是否為 rgb 或 rgba 格式 */
  public static isRgbString(str: string) {
    return RGBA_REGEXP.test(str) || RGB_REGEXP.test(str);
  }

  /** 判斷是否為 Hex 格式 - #RRGGBB 或 #RRGGBBAA */
  public static isHex(str: string) {
    return /^#[A-Z0-9]{6,8}$/gi.test(str);
  }

  /** 判斷是否為 hsla 格式 - hsla(色相, 飽和度, 亮度, 透明度) 或 hsl(色相, 飽和度, 亮度) */
  public static isHslString(str: string) {
    return HSLA_REGEXP.test(str) || HSL_REGEXP.test(str);
  }

  /**
   * 將 rgba 或 rgb 字串 (rgba(255, 255, 255, 0.5) 或 rgb(255, 255, 255)) 轉換為 rgba 物件
   * 如果是 rgb(255, 255, 255) 則回傳的物件 alpha 值為 null
   * 如果是格式錯誤，則回傳的物件 red, green, blue 值為 0，alpha 值為 null
   */
  public static rgbaStringToRgba(rgbString: string): Rgb {
    if (RGBA_REGEXP.test(rgbString)) {
      const [, red, green, blue, alpha] = RGBA_REGEXP.exec(rgbString);
      return {
        red: parseInt(red, 10),
        green: parseInt(green, 10),
        blue: parseInt(blue, 10),
        alpha: parseFloat(alpha),
      };
    } else if (RGB_REGEXP.test(rgbString)) {
      const [, red, green, blue] = RGB_REGEXP.exec(rgbString);
      return {
        red: parseInt(red, 10),
        green: parseInt(green, 10),
        blue: parseInt(blue, 10),
        alpha: null,
      };
    } else {
      return {
        red: 0,
        green: 0,
        blue: 0,
        alpha: null,
      };
    }
  }

  public static hslStringToRgba(hslString: string): Rgb {
    if (HSLA_REGEXP.test(hslString)) {
      const [, hue, saturation, lightness, alpha] = HSLA_REGEXP.exec(hslString);
      return Color.hslToRgba({
        hue: Number(hue),
        saturation: Number(saturation),
        lightness: Number(lightness),
        alpha: parseFloat(alpha),
      });
    } else if (HSL_REGEXP.test(hslString)) {
      const [, hue, saturation, lightness] = HSL_REGEXP.exec(hslString);
      return Color.hslToRgba({
        hue: Number(hue),
        saturation: Number(saturation),
        lightness: Number(lightness),
        alpha: null,
      });
    }
  }

  /**
   * 將 rgba 物件轉換為 Hex 字串
   * 如果 alpha 為 null，則回傳的字串不包含 alpha 值 #RRGGBB
   * 如果 alpha 不為 null，則回傳的字串包含 alpha 值 #RRGGBBAA
   */
  public static rgbaToHex(rgba: Rgb) {
    let { red, green, blue } = rgba;
    const { alpha } = rgba;
    red = Math.max(0, Math.min(255, Math.round(red)));
    green = Math.max(0, Math.min(255, Math.round(green)));
    blue = Math.max(0, Math.min(255, Math.round(blue)));

    let alphaValue =
      alpha !== null
        ? Math.max(0, Math.min(255, Math.round(alpha * 255)))
            .toString(16)
            .padStart(2, '0')
        : '';

    if (alphaValue === 'ff') alphaValue = '';

    return (
      '#' +
      red.toString(16).padStart(2, '0') +
      green.toString(16).padStart(2, '0') +
      blue.toString(16).padStart(2, '0') +
      alphaValue
    ).toUpperCase();
  }

  /**
   * 將 Hex 字串轉換為 rgba 物件
   * 支援 #RRGGBBAA, #RRGGBB, #RGB
   * 如果是 #RRGGBB 或 #RGB 則回傳的物件 alpha 值為 null
   * 如果是格式錯誤，則回傳的物件 red, green, blue 值為 0，alpha 值為 null
   */
  public static hexToRgba(hex: string): Rgb {
    if (!hex) {
      return { red: 0, green: 0, blue: 0, alpha: null };
    }

    if (hex.match(/^\#[A-Z0-9]{6,8}$/gi)) {
      let alpha = null;
      if (hex.length === 9) {
        alpha = parseInt(hex.substring(7, 9), 16) / 255;
      }

      return {
        red: parseInt(hex.substring(1, 3), 16),
        green: parseInt(hex.substring(3, 5), 16),
        blue: parseInt(hex.substring(5, 7), 16),
        alpha,
      };
    } else if (hex.match(/^\#[A-Z0-9]{3}$/gi)) {
      return {
        red: parseInt(hex.substring(1, 2).repeat(2), 16),
        green: parseInt(hex.substring(2, 3).repeat(2), 16),
        blue: parseInt(hex.substring(3, 4).repeat(2), 16),
        alpha: null,
      };
    } else {
      return { red: 0, green: 0, blue: 0, alpha: null };
    }
  }

  /**
   * 將 HSB 物件轉換為 RGB 物件
   * 如果 alpha 為 null，則回傳的物件 alpha 值為 null
   */
  public static hsbToRgba(hsb: Hsb) {
    let { hue, saturation, brightness } = hsb;
    const { alpha } = hsb;
    saturation /= 100;
    brightness /= 100;

    hue = (hue % 360) / 60;
    const c = brightness * saturation;
    const x = c * (1 - Math.abs((hue % 2) - 1));
    let r = brightness - c;
    let g = r;
    let b = r;

    hue = ~~hue;
    r += [c, x, 0, 0, x, c][hue];
    g += [x, c, c, x, 0, 0][hue];
    b += [0, 0, x, c, c, x][hue];

    return {
      red: Math.max(0, Math.min(255, Math.round(r * 255))),
      green: Math.max(0, Math.min(255, Math.round(g * 255))),
      blue: Math.max(0, Math.min(255, Math.round(b * 255))),
      alpha,
    };
  }

  /**
   * 將 RGB 物件轉換為 HSB 物件
   * 如果 alpha 為 null，則回傳的物件 alpha 值為 null
   */
  public static rgbToHsb(rgba: Rgb): Hsb {
    let { red, green, blue } = rgba;
    const { alpha } = rgba;
    red /= 255;
    green /= 255;
    blue /= 255;

    // 計算明度
    let brightness = Math.max(red, green, blue);

    // 計算色相
    const C = brightness - Math.min(red, green, blue);
    let hue =
      C === 0
        ? null
        : brightness === red
          ? (green - blue) / C
          : brightness === green
            ? (blue - red) / C + 2
            : (red - green) / C + 4;
    hue = ((hue + 360) % 6) * 60;

    // 計算飽和度
    let saturation = C === 0 ? 0 : C / brightness;

    saturation *= 100;
    brightness *= 100;

    return { hue, saturation, brightness, alpha };
  }

  /** 將 HSL 物件轉換為 RGB 物件 */
  public static hslToRgba(hsl: Hsl): Rgb {
    let { saturation, lightness } = hsl;
    const { hue, alpha } = hsl;

    // Normalize saturation and lightness
    saturation /= 100;
    lightness /= 100;

    const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation;
    const x = chroma * (1 - Math.abs(((hue / 60) % 2) - 1));
    const m = lightness - chroma / 2;

    let r = 0,
      g = 0,
      b = 0;

    if (0 <= hue && hue < 60) {
      [r, g, b] = [chroma, x, 0];
    } else if (60 <= hue && hue < 120) {
      [r, g, b] = [x, chroma, 0];
    } else if (120 <= hue && hue < 180) {
      [r, g, b] = [0, chroma, x];
    } else if (180 <= hue && hue < 240) {
      [r, g, b] = [0, x, chroma];
    } else if (240 <= hue && hue < 300) {
      [r, g, b] = [x, 0, chroma];
    } else if (300 <= hue && hue < 360) {
      [r, g, b] = [chroma, 0, x];
    }

    return {
      red: Math.round((r + m) * 255),
      green: Math.round((g + m) * 255),
      blue: Math.round((b + m) * 255),
      alpha,
    };
  }

  /** 將 hsb 物件轉換為 HSL 物件 */
  public static hsbToHsl(hsb: Hsb): Hsl {
    const { hue, saturation, brightness, alpha } = hsb;

    // Convert saturation and brightness to decimals
    const s = saturation / 100;
    const v = brightness / 100;

    // Calculate lightness
    const l = v * (1 - s / 2);

    // Calculate HSL saturation
    let hslSaturation: number;
    if (l === 0 || l === 1) {
      hslSaturation = 0;
    } else {
      hslSaturation = (v - l) / Math.min(l, 1 - l);
    }

    return {
      hue,
      saturation: Math.round(hslSaturation * 100),
      lightness: Math.round(l * 100),
      alpha,
    };
  }

  /** 將 Hex 字串轉換為 HSB 物件 */
  public static hexToHsb(hex: string) {
    return Color.rgbToHsb(Color.hexToRgba(hex));
  }

  /** 將 HSB 物件轉換為 Hex 字串 */
  public static hsbToHex(hsb: Hsb) {
    return Color.rgbaToHex(Color.hsbToRgba(hsb));
  }

  /** 調整 RGB 的亮度 */
  public static setBrightness(rgba: Rgb, level: number): Rgb {
    let { red, green, blue } = rgba;
    const { alpha } = rgba;

    if (level < 0) {
      red = red - (red / 100) * -level;
      green = green - (green / 100) * -level;
      blue = blue - (blue / 100) * -level;
    } else {
      red = ((255 - red) / 100) * level + red;
      green = ((255 - green) / 100) * level + green;
      blue = ((255 - blue) / 100) * level + blue;
    }

    return { red, green, blue, alpha };
  }

  /**
   * 判斷 RGB 是否為淺色
   * 先將 RGB 轉換為灰色，再判斷是否大於 threshold (160)
   */
  public static isLight(rgba: Rgb, threshold = 160) {
    return (
      Math.round((299 * rgba.red + 587 * rgba.green + 114 * rgba.blue) / 1000) >
      threshold
    );
  }

  /**
   * 調整顏色亮度
   * 先將 Hex 轉換為 RGB，再調整亮度，最後轉換為 Hex
   */
  public static adjustColorLevel(hex: string, level: number) {
    const numberOfRows = 20;
    const step = 100 / Math.ceil(numberOfRows / 2);
    const startVal = step * Math.floor(numberOfRows / 2);
    const rgba = Color.hexToRgba(hex);
    level += 10;
    const E = Color.setBrightness(rgba, startVal - level * step);
    return Color.rgbaToHex(E);
  }
}
