import { Injectable } from '@angular/core';
import {
  FilterListResult,
  FilterModel,
  GroupColumn,
} from '@ay-gosu/server-shared';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { Group } from '../pages/filter/group';
import { CacheableService } from './cacheable.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService extends CacheableService<any> {
  public reloadList$ = new BehaviorSubject(null);

  public list$: Observable<FilterListResult[]> = combineLatest([
    this._tokenService.rxAccount$,
    this.reloadList$,
  ]).pipe(
    switchMap(([account]) =>
      account === null ? of([]) : FilterModel.getList(),
    ),
    shareReplay(1),
  );

  public constructor(private readonly _tokenService: TokenService) {
    super();
  }

  protected async _get(ids: number[]): Promise<GroupColumn[]> {
    let detail = await FilterModel.getDetail(ids[0]);
    return [detail];
  }

  public async save(group: Group) {
    if (typeof group.id === 'number') {
      return await FilterModel.update(group.id, group.toGroupObject() as any);
    } else {
      return await FilterModel.create(group.toGroupObject() as any);
    }
  }

  public async delete(id: number) {
    await FilterModel.delete(id);
    this.reloadList$.next(null);
  }

  public async rename(id: number, name: string) {
    await FilterModel.rename(id, name);
    this.reloadList$.next(null);
  }

  public async estimate(group: Group) {
    let result = FilterModel.filter(group.toGroupObject());
    return result;
  }
}
