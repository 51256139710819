import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LogStatusMap } from '@ay/bot';
import { delay } from 'bluebird';
import { ReadableError } from '../../../util/readable-error';
import { AvatarComponent } from '../../components/avatar/avatar.component';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { FromNowPipe } from '../../pipe/from-now.pipe';
import { MessageStatusPipe } from '../../pipe/message-status.pipe';
import { MomentPipe } from '../../pipe/moment.pipe';
import { AnnouncementComponent } from '../announcement/announcement.component';
import { AudioComponent } from '../audio/audio.component';
import { BaseComponent } from '../base/base.component';
import { Message } from '../base/base.message';
import { CardsComponent } from '../cards/cards.component';
import { CouponComponent } from '../coupon/coupon.component';
import { EditTextComponent } from '../edit-text/edit-text.component';
import { FileComponent } from '../file/file.component';
import { FlexEditorService } from '../flex/editor/editor.service';
import { FlexComponent } from '../flex/flex.component';
import { ImageComponent } from '../image/image.component';
import { LocationComponent } from '../location/location.component';
import { Package } from '../package.class';
import { PosterComponent } from '../poster/poster.component';
import { ReplyTextComponent } from '../reply-text/reply-text.component';
import { TextComponent } from '../text/text.component';
import { VideoComponent } from '../video/video.component';
import { FactoryContainerComponent } from './factory-container.component';

@Component({
  selector: 'ms-factory',
  templateUrl: './factory.component.html',
  providers: [FlexEditorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AvatarComponent,
    FactoryContainerComponent,
    MatTooltip,
    MatIcon,
    FromNowPipe,
    MomentPipe,
    MessageStatusPipe,
    NgClass,
    MatProgressSpinner,
  ],
  host: {
    class: 'flex flex-row w-full mr-8 mb-4 group',
  },
})
export class FactoryComponent implements AfterViewInit {
  public static types = [
    'text',
    'image',
    'cards',
    'poster',
    'flex',
    'audio',
    'video',
    'coupon',
    'location',
    'announcement',
    'file',
    'edit-text',
    'reply-text',
  ];

  public static typeMap: {
    [type: string]: { new (...args: any[]): BaseComponent<any> };
  } = {
    text: TextComponent,
    poster: PosterComponent,
    audio: AudioComponent,
    cards: CardsComponent,
    image: ImageComponent,
    video: VideoComponent,
    coupon: CouponComponent,
    location: LocationComponent,
    announcement: AnnouncementComponent,
    flex: FlexComponent,
    file: FileComponent,
    'edit-text': EditTextComponent,
    'reply-text': ReplyTextComponent,
  };

  public componentInstance: BaseComponent<any>;

  @Input()
  public message: Message;

  @Input()
  public package: Package;

  @Input()
  public mode: 'READ' | 'EDIT' = 'READ';

  @Output()
  public viewInitEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('container', { read: FactoryContainerComponent })
  public container: FactoryContainerComponent;

  public logStatus = LogStatusMap;

  public constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public static getComponent(type: string) {
    if (FactoryComponent.typeMap[type] === undefined) {
      throw new ReadableError($localize`不支援的訊息類型 ${type}`, {
        types: FactoryComponent.typeMap,
        type,
      });
    }

    return FactoryComponent.typeMap[type];
  }

  public get type() {
    try {
      const Class = this.message['__proto__'].constructor;
      return Class.type;
    } catch (error) {
      return null;
    }
  }

  public async ngAfterViewInit() {
    await delay(1);
    const Component = FactoryComponent.getComponent(this.message.type);
    const componentRef =
      this.container.viewContainerRef.createComponent(Component);
    componentRef.instance.message = this.message;
    componentRef.instance.mode = this.mode;
    componentRef.instance.package = this.package;
    this.componentInstance = componentRef.instance;
    this.container.viewContainerRef.insert(componentRef.hostView, 0);
    this._changeDetectorRef.markForCheck();
    this.viewInitEmitter.emit(true);
  }
}
