import { Pipe, PipeTransform } from '@angular/core';
import { GroupRelation } from '@ay-gosu/server-shared';
import { ReadableError } from '../../util/readable-error';

export const RelationMap = {
  AND: '且',
  OR: '或',
};

@Pipe({
    name: 'relation',
    standalone: true,
})
export class RelationPipe implements PipeTransform {
  public transform(type: GroupRelation): any {
    try {
      if (RelationMap[type] === undefined) {
        throw new ReadableError($localize`未知的關係 ${type}`);
      }
      return RelationMap[type];
    } catch (error) {
      console.error(error);
      return '、';
    }
  }
}
