<div #background class="time-picker-bg" [class.hidden]="!isOpened.value"></div>

@if (isOpened.value) {
  <mat-card>
    <mat-form-field [color]="color" legacyAppearance>
      <mat-label i18n>時</mat-label>
      <mat-select [(ngModel)]="hour">
        @for (i of numArray(24); track i) {
          <mat-option [value]="i" [disabled]="isDisabled(i, 'hour')">
            {{ i < 10 ? '0' + i : i }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <span>：</span>

    <mat-form-field [color]="color" legacyAppearance>
      <mat-label i18n>分</mat-label>
      <mat-select [(ngModel)]="minute">
        @for (i of numArray(60); track i) {
          <mat-option [value]="i" [disabled]="isDisabled(i, 'minute')">
            {{ i < 10 ? '0' + i : i }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    @if (withSecond) {
      <span>：</span>

      <mat-form-field [color]="color" legacyAppearance>
        <mat-label i18n>秒</mat-label>
        <mat-select [(ngModel)]="second">
          @for (i of numArray(60); track i) {
            <mat-option [value]="i" [disabled]="isDisabled(i, 'second')">
              {{ i < 10 ? '0' + i : i }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  </mat-card>
}
