<ng-container *ngIf="package">
  <ng-container *ngFor="let toolbar of package.toolbars">
    <ng-container
      [ngSwitch]="toolbar.mode"
      *ngIf="!hideToolTypes.includes(toolbar.type)"
    >
      <ng-template ngSwitchCase="CLICK">
        <button
          mat-icon-button
          [matTooltip]="toolbar.tooltip"
          element-ref
          #elementRef="elementRef"
          (click)="package.insert(toolbar.onClick(package, elementRef))"
          [disabled]="disableToolTypes.includes(toolbar.type)"
        >
          <mat-icon>{{ toolbar.icon }}</mat-icon>
        </button>
      </ng-template>

      <ng-template ngSwitchCase="UPLOAD_FILE">
        <button
          mat-icon-button
          (file)="package.insert(toolbar.onUpload($event))"
          [accept]="toolbar.accept"
          [matTooltip]="toolbar.label + ' - ' + toolbar.tooltip"
          matTooltipPosition="after"
          [disabled]="disableToolTypes.includes(toolbar.type)"
        >
          <mat-icon>{{ toolbar.icon }}</mat-icon>
        </button>
      </ng-template>

      <ng-template ngSwitchCase="SUBMENU">
        <button
          mat-icon-button
          [matTooltip]="toolbar.tooltip"
          [matMenuTriggerFor]="menu.matMenu"
          (click)="loadSubmenu(toolbar)"
          [disabled]="disableToolTypes.includes(toolbar.type)"
        >
          <mat-icon>{{ toolbar.icon }}</mat-icon>
        </button>
        <ms-submenu
          #menu
          [toolbars]="toolbar.submenu"
          [package]="package"
        ></ms-submenu>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
