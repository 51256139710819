import { Component, ContentChild, OnDestroy, ViewChild } from '@angular/core';
import { NodeData } from './node-data';
import { MatTreePicker } from './tree-picker';
import { MatTreePickerNodeDef } from './tree-picker-node';
import { MatTreePickerNodeList } from './tree-picker-node-list';

@Component({
    selector: 'mat-flat-tree-picker',
    template: `
    <div class="mat-tree-picker-panel" role="listbox">
      <mat-tree-picker-node-list
        #list
        [dataSource]="dataSource"
        [nodeDef]="matTreePickerNode"
        [side]="side"
      ></mat-tree-picker-node-list>
    </div>
    <ng-template #panel></ng-template>
  `,
    styleUrls: ['tree-picker.scss'],
    exportAs: 'matFlatTreePicker',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'mat-flat-tree-picker',
        role: 'tree',
    },
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['dataSource', 'panelWidth', 'side'],
    queries: {
        matTreePickerNode: new ContentChild(MatTreePickerNodeDef, { static: true }),
        list: new ViewChild(MatTreePickerNodeList),
    },
    standalone: true,
    imports: [MatTreePickerNodeList],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MatFlatTreePicker<T extends NodeData>
  extends MatTreePicker<T>
  implements OnDestroy
{
  public ngOnDestroy() {
    this.list.closePanel();
  }
}
