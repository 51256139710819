import { NgFor } from '@angular/common';
import { Component, ElementRef, Inject } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { ElementRefDirective } from '@ay-gosu/ui/common/element-ref';
import { firstValueFrom } from 'rxjs';
import { ColorPickerDialog } from '../../material/color-picker';

@Component({
  selector: 'dl-basic-color-palette-dialog',
  templateUrl: './basic-color-palette.dialog.html',
  styleUrls: ['./basic-color-palette.dialog.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatIconButton,
    MatDivider,
    MatButton,
    MatIcon,
    ElementRefDirective,
  ],
})
export class BasicColorPaletteDialog {
  public colors: string[] = [
    '#E53935',
    '#e67e22',
    '#f1c40f',
    '#1abc9c',
    '#2ecc71',
    '#34495e',
    '#3498db',
    '#9b59b6',
    '#CCCCCC',
    '#333333',
  ];

  public constructor(
    private _matConnectedDialog: MatConnectedDialog,
    private _matDialogRef: MatDialogRef<BasicColorPaletteDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: { color: string },
  ) {}

  public setColor(color: string) {
    this._matDialogRef.close(color);
  }

  public async openAdvancedPalette(elementRef: ElementRef<HTMLElement>) {
    const dialogRef = this._matConnectedDialog.open(ColorPickerDialog, {
      elementRef,
      data: {
        enableAlpha: false,
        alpha: 100,
        hex: this.data.color || '',
      },
    });

    const color = await firstValueFrom(dialogRef.afterClosed());

    if (color) {
      this._matDialogRef.close(color.hex);
    }
  }
}
