import { Directive, ElementRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[element-ref], [elementRef]',
  exportAs: 'elementRef',
})
export class ElementRefDirective extends ElementRef {
  public constructor(protected elementRef: ElementRef) {
    super(elementRef.nativeElement);
  }
}
