import { Injectable, Injector, Type } from '@angular/core';
import { CustomerServiceMessageDto } from '@ay-gosu/types';
import { Content, ContentType, PrivateMessage } from '@ay/bot';
import { AccountService } from '../service/account.service';
import { CouponService } from '../service/coupon.service';
import { AnnouncementMessage } from './announcement/announcement.message';
import { AudioMessage } from './audio/audio.message';
import { Message } from './base/base.message';
import { CardsMessage } from './cards/cards.message';
import { CouponRecordMessage } from './coupon/coupon-record/coupon-record.message';
import { CouponMessage } from './coupon/coupon.message';
import { EditTextMessage } from './edit-text/edit-text.message';
import { FileMessage } from './file/file.message';
import { FlexMessage } from './flex/flex.message';
import { ImageMessage } from './image/image.message';
import { LocationMessage } from './location/location.message';
import { PosterMessage } from './poster/poster.message';
import { ReplyTextMessage } from './reply-text/reply-text.message';
import { TextMessage } from './text/text.message';
import { VideoMessage } from './video/video.message';

@Injectable({
  providedIn: 'root',
})
export class MessageFactoryService {
  private readonly _messageFactories: {
    [type: string]: Type<Message>;
  } = {
    text: TextMessage,
    poster: PosterMessage,
    audio: AudioMessage,
    card: CardsMessage,
    cards: CardsMessage,
    image: ImageMessage,
    video: VideoMessage,
    coupon: CouponMessage,
    'coupon-record': CouponRecordMessage,
    location: LocationMessage,
    announcement: AnnouncementMessage,
    unfollow: AnnouncementMessage,
    follow: AnnouncementMessage,
    leave: AnnouncementMessage,
    join: AnnouncementMessage,
    file: FileMessage,
    memberJoined: AnnouncementMessage,
    memberLeft: AnnouncementMessage,
    postback: AnnouncementMessage,
    action: AnnouncementMessage,
    'add-reaction': AnnouncementMessage,
    'add-comment': AnnouncementMessage,
    'remove-comment': AnnouncementMessage,
    flex: FlexMessage,
    'edit-text': EditTextMessage,
    'reply-text': ReplyTextMessage,
  };

  public constructor(
    private readonly _accountService: AccountService,
    private readonly _couponService: CouponService,
    private readonly _injector: Injector,
  ) {}

  public create(type: ContentType) {
    const messageFactory = this._messageFactories[type];
    if (messageFactory === undefined) {
      throw $localize`不支援的訊息類型 ${type}`;
    }
    const injector = Injector.create({
      providers: [{ provide: messageFactory, useClass: messageFactory }],
      parent: this._injector,
    });
    const instance = injector.get(messageFactory);
    if (instance instanceof CouponMessage) {
      instance.couponService = this._couponService;
    }
    return instance;
  }

  public async createFromContent(
    content: Content.Any,
  ): Promise<Message<Content.Any>> {
    let type = content.type;

    if (content.type === 'template') {
      type = content.targetType;
    }

    const messageFactory = this._messageFactories[type];
    if (messageFactory === undefined) {
      throw $localize`不支援的訊息類型 ${type}`;
    }

    const injector = Injector.create({
      providers: [{ provide: messageFactory, useClass: messageFactory }],
      parent: this._injector,
    });
    const message = injector.get(messageFactory);
    if (message instanceof CouponMessage) {
      message.couponService = this._couponService;
    }
    await message.loadFromContent(content);
    return message;
  }

  public async createFromPrivateMessage(pm: PrivateMessage) {
    const message = await this.createFromContent(pm.record);
    message.sender = await this._accountService.fetchName(pm.accountId);
    message.accountId = pm.accountId;
    message.direction = pm.type;
    message.createdAt = new Date(pm.timestamp);
    message.status = pm.status;
    message.reason = pm.reason;
    message.logId = pm.logId;

    message.userProfileId = pm.userProfileId;
    if (message.direction === 'Receive' && !pm.userProfileId) {
      message.userProfileId = pm.profileId;
    }

    return message;
  }

  public async createFromCustomerMessage(dto: CustomerServiceMessageDto) {
    const message = await this.createFromContent(dto.content);
    message.sender = await this._accountService.fetchName(dto.accountId);
    message.accountId = dto.accountId;
    message.direction = dto.type;
    message.createdAt = new Date(dto.timestamp);
    message.status = dto.status;
    message.reason = dto.reason;
    message.logId = dto.logId;
    if (message.direction === 'Receive') {
      message.userProfileId = dto.profileId;
    }
    return message;
  }
}

export class MessageFactory {
  public createInstance: () => Message;
  public createManual?: () => Promise<Message>;
}
