import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ensureTextLength',
    standalone: true
})
export class EnsureTextLengthPipe implements PipeTransform {
  public transform(content: string, max: number, suffix = '…'): any {
    return this._ensureTextLength(content, max, suffix);
  }

  private _ensureTextLength<T = string | any[]>(
    input: T,
    max: number,
    suffix = '…',
  ): T {
    if (typeof input == 'string' && input.length > max) {
      if (max <= suffix.length) {
        return input.slice(0, max) as any;
      } else {
        return `${input.slice(0, max - suffix.length)}${suffix}` as any;
      }
    }
    if (input instanceof Array && input.length > max) {
      return input.slice(0, max) as any;
    }
    return input;
  }
}
