// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { PrivateMessage } from '@ay/bot';

export class RecordModel {
  static history(profileId: number, page: any = 1): Promise<PrivateMessage[]> {
    return wsc.execute('/ws/record/history', profileId, page) as any;
  }
}
// ae7ac601d230a7408e6697d3bfad3d0be9a11b6f5edd2ceb63d129b821c0a811
