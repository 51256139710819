import { NgIf } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { autoRename } from '../../../../util/auto-rename';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';

@Component({
  selector: 'dl-copy-confirm-dialog',
  templateUrl: './copy-confirm.dialog.html',
  styleUrls: ['./copy-confirm.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    NgIf,
    MatError,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class CopyConfirmDialog implements OnDestroy {
  public nameInputControl = new FormControl('', Validators.required);

  public form = new FormGroup({ name: this.nameInputControl });

  //#region @Two-way() name
  private _name: string = '';

  @Output()
  public name$ = new Subject<string>();

  public get name(): string {
    return this._name;
  }

  @Input()
  public set name(name: string) {
    if (this._name === name) return;
    this._name = name;
    this.name$.next(this._name);
  }
  //#endregion @Two-way() name

  private readonly _destroy$ = new Subject<void>();

  private _updateNameInputControlWhenNameChanged = this.name$
    .pipe(takeUntil(this._destroy$))
    .subscribe((name) => this.nameInputControl.setValue(autoRename(name)));

  public constructor(
    @Optional()
    public dialogRef: MatDialogRef<CopyConfirmDialog>,
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    public data: string = '',
  ) {
    this.name = data;
  }

  public onSubmit() {
    this.dialogRef.close(this.nameInputControl.value);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
