import { Component, Inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { createRandomString } from '@ay/util';

@Component({
  selector: 'gosu-delete',
  templateUrl: './delete.dialog.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogClose,
    CommonModule,
  ],
})
export class DeleteDialog {
  public name = signal('');

  public checkCode = signal(
    createRandomString('ABCDEFGHJKMNPQRSTUVWXYZ23456789', 4),
  );

  public code = new FormControl('', comparisonString(this.checkCode()));

  public form = new FormGroup({});

  public messages = signal<string[]>([]);

  public isDark = signal(false);

  public confirmMessage = signal('');

  public confirmButtonText = signal('');

  public cancelButtonText = signal('');

  public constructor(
    private _matDialogRef: MatDialogRef<DeleteDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string;
      confirmCode: boolean;
      messages: string[];
      isDark: boolean;
      confirmMessage: string;
      confirmButtonText: string;
      cancelButtonText: string;
    },
  ) {
    this.name.set(data.name);

    if (data.confirmCode) {
      this.form.addControl('code', this.code);
    }

    if (data.messages) {
      this.messages.set(data.messages);
    }

    if (data.isDark) {
      this.isDark.set(data.isDark);
    }

    if (data.confirmMessage) {
      this.confirmMessage.set(data.confirmMessage);
    }

    if (data.confirmButtonText) {
      this.confirmButtonText.set(data.confirmButtonText);
    }

    if (data.cancelButtonText) {
      this.cancelButtonText.set(data.cancelButtonText);
    }
  }

  public onSubmit() {
    this._matDialogRef.close(true);
  }

  public cancel() {
    this._matDialogRef.close(false);
  }
}

import { CommonModule } from '@angular/common';
import { comparisonString } from './comparison-string';
