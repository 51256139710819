import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'dl-info-dialog',
    templateUrl: './info.dialog.html',
    styleUrls: ['./info.dialog.scss'],
    standalone: true,
    imports: [
        FlexModule,
        MatIcon,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        MatButton,
        NgSwitchDefault,
    ],
})
export class InfoDialog {
  @Input()
  public content: string = '';

  @Input() public buttons: {
    label: string;
    type: string;
    color: string;
    result: string;
  }[] = [{ label: 'OK', type: 'raised', color: 'primary', result: 'OK' }];

  public constructor(private readonly _matDialogRef: MatDialogRef<any>) {}

  public close(result: string) {
    this._matDialogRef.close(result);
  }
}
