import { Injectable } from '@angular/core';
import { KeywordColumn, KeywordModel } from '@ay-gosu/server-shared';
import { arrayToMap } from '@ay/util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { filterList } from '../../util/filter-list';
import { TagService } from './tag.service';
import { TokenService } from './token.service';

export const KEYWORD_ROOT_TAG_NAME = `*關鍵字`;

@Injectable({
  providedIn: 'root',
})
export class KeywordService {
  private _changeNotify$ = new BehaviorSubject<null>(null);

  public groupList$ = combineLatest([
    this._tokenService.rxAccount$,
    this._changeNotify$,
  ]).pipe(
    switchMap(() => this._tagService.keyword.tree$),
    filterList((tag) => tag.name === KEYWORD_ROOT_TAG_NAME),
    map((tags) => {
      try {
        return tags[0].children;
      } catch (err) {
        return [];
      }
    }),
    shareReplay(1),
  );

  public groupNameMap$ = this.groupList$.pipe(
    map((tags) => arrayToMap(tags, 'id', 'name')),
    shareReplay(1),
  );

  public constructor(
    private _tokenService: TokenService,
    private _tagService: TagService,
  ) {}

  public changeNotify() {
    this._changeNotify$.next(null);
  }

  public async getKeywordById(id: number): Promise<KeywordColumn> {
    if (!id) {
      console.error(
        $localize`Error KeywordModel.getKeywordById: 非法的id:`,
        id,
      );
      return null;
    }
    return KeywordModel.getKeywordsById(id).catch((err) => {
      console.error('Error KeywordModel.getKeywordById:', err);
      return null;
    });
  }
  public async getKeywordsByMainTagId(
    mainTagId: number,
  ): Promise<KeywordColumn[]> {
    if (!mainTagId) return [];

    return KeywordModel.getKeywordsByMainTagId(mainTagId)
      .then((keywords) => {
        for (let keyword of keywords) {
          if (!keyword.tags) keyword.tags = [];
        }
        return keywords;
      })
      .catch((err) => {
        console.error('Error KeywordModel.getKeywordsByMainTagId:', err);
        return [];
      });
  }

  public async create(
    keyword: string,
    mainTagId: number,
    synonyms: string = '',
  ): Promise<number> {
    let res = await KeywordModel.create(keyword, mainTagId, synonyms).catch(
      (err) => {
        console.error('Error KeywordModel.create:', err);
        return null;
      },
    );
    this.changeNotify();
    return res;
  }

  public async update(
    keywordId: number,
    option: {
      keyword?: string;
      mainTagId?: number;
      synonyms?: string;
    },
  ): Promise<boolean> {
    let res = KeywordModel.update(keywordId, option).catch((err) => {
      console.error('Error KeywordModel.update:', err);
      return false;
    });
    this.changeNotify();
    return res;
  }

  public async delete(keywordId: number): Promise<boolean> {
    let res = KeywordModel.delete(keywordId).catch((err) => {
      console.error('Error KeywordModel.delete:', err);
      return false;
    });
    this.changeNotify();
    return res;
  }
}
