export const LogTypes = [
  'Send',
  'Card',
  'CardButton',
  'Poster',
  'PosterArea',
  'Other',
  'Image',
  'Video',
  'Audio',
  'Node',
  'Receive',
  'Resource',
  'OAuth login',
  'OAuth logout',
  'OAuth logged',
  'OAuth error',
  'Read',
] as const;

/*
 * 當類型為 log.type = Send 才會有該值
 * 0  :發送失敗
 * 1  :成功發送
 * 2  :處理中 (Sender 收到任務處理中)
 * 3  :發送中 (API 呼叫前一個步驟)
 */
export enum LogStatus {
  FAILED = 0,
  SUCCESS = 1,
  PROCESSING = 2,
  SENDING = 3,
}

export const LogStatusMap = {
  FAILED: 0,
  SUCCESS: 1,
  PROCESSING: 2,
  SENDING: 3,
};

export type LogType = (typeof LogTypes)[number];

export interface Log {
  id?: number;
  type?: LogType;
  companyId?: number;
  profileId?: number;
  recordId?: number;
  cardId?: number;
  buttonId?: number;
  areaId?: number;
  posterId?: number;
  accountId?: number;
  packageId?: number;
  botId?: number;
  nodeId?: number;
  flowId?: number;
  executorId?: string;
  userProfileId?: number;
  url?: string;
  resourceId?: number;
  extra?: any;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  toJSON?: () => Log;
  get?: (() => any) | Log;
  status?: LogStatus;
  reason?: string;
}
