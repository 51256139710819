// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { PackageFetchForCannedMessage } from '../package/dto/package-fetch-for-canned-message.dto';
import { wsc } from '../wsc';
import { CreateCannedMessageCategoryDto } from './dto/create-canned-message-category.dto';
import { CreateCannedMessageDto } from './dto/create-canned-message.dto';
import { FetchCannedMessageCategoryResponseDto } from './dto/fetch-canned-message-response.dto';
import { RenameCannedMessageDto } from './dto/rename-canned-message.dto';
import { UpdateCannedMessageCategoryDto } from './dto/update-canned-message-category.dto';
import { UpdateCannedMessageDto } from './dto/update-canned-message.dto';

export class CannedMessageModel {
  static createCategory(
    body: CreateCannedMessageCategoryDto,
  ): Promise<{ cannedMessageCategoryId: number }> {
    return wsc.execute('/ws/canned-message/createCategory', body) as any;
  }

  static fetchCategories(): Promise<FetchCannedMessageCategoryResponseDto[]> {
    return wsc.execute('/ws/canned-message/fetchCategories') as any;
  }

  static fetchPackage(
    cannedMessageId: number,
  ): Promise<PackageFetchForCannedMessage> {
    return wsc.execute(
      '/ws/canned-message/fetchPackage',
      cannedMessageId,
    ) as any;
  }

  static updateCategory(body: UpdateCannedMessageCategoryDto): Promise<any> {
    return wsc.execute('/ws/canned-message/updateCategory', body) as any;
  }

  static deleteCategory(cannedMessageCategoryId: number): Promise<any> {
    return wsc.execute(
      '/ws/canned-message/deleteCategory',
      cannedMessageCategoryId,
    ) as any;
  }

  static orderCannedMessageCategory(
    cannedMessageCategories: {
      cannedMessageCategoryId: number;
      order: number;
    }[],
  ): Promise<any> {
    return wsc.execute(
      '/ws/canned-message/orderCannedMessageCategory',
      cannedMessageCategories,
    ) as any;
  }

  static orderMessages(
    cannedMessageCategoryId: number,
    cannedMessages: { cannedMessageId: number; order: number }[],
  ): Promise<any> {
    return wsc.execute(
      '/ws/canned-message/orderMessages',
      cannedMessageCategoryId,
      cannedMessages,
    ) as any;
  }

  static create(
    body: CreateCannedMessageDto,
  ): Promise<{ cannedMessageId: number }> {
    return wsc.execute('/ws/canned-message/create', body) as any;
  }

  static rename(body: RenameCannedMessageDto): Promise<any> {
    return wsc.execute('/ws/canned-message/rename', body) as any;
  }

  static update(body: UpdateCannedMessageDto): Promise<any> {
    return wsc.execute('/ws/canned-message/update', body) as any;
  }

  static delete(cannedMessageId: number): Promise<any> {
    return wsc.execute('/ws/canned-message/delete', cannedMessageId) as any;
  }
}
// 9b61e3c1cfb3364aabb2cb9c6e01b0f4243a46e3bbbbd9fe664902e7eda36a39
