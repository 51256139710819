// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import {
  CreateExchangeDto,
  FetchExchangeDetailDto,
  FetchExchangeListResponseDto,
  UpdateExchangeDto,
} from '@ay-gosu/types';
import { UseExchangeFlowAndNodeIdsDto } from './dto/used-flow-node-ids.dto';

export class ExchangeModel {
  static create(data: CreateExchangeDto): Promise<number> {
    return wsc.execute('/ws/exchange/create', data) as any;
  }

  static list(
    size: any = 30,
    page: any = 1,
  ): Promise<FetchExchangeListResponseDto> {
    return wsc.execute('/ws/exchange/list', size, page) as any;
  }

  static fetch(exchangeId: number): Promise<FetchExchangeDetailDto> {
    return wsc.execute('/ws/exchange/fetch', exchangeId) as any;
  }

  static update(exchangeId: number, data: UpdateExchangeDto): Promise<void> {
    return wsc.execute('/ws/exchange/update', exchangeId, data) as any;
  }

  static delete(exchangeId: number): Promise<void> {
    return wsc.execute('/ws/exchange/delete', exchangeId) as any;
  }

  static useExchangeFlowAndNodeIds(
    exchangeId: number,
    methodIds?: number[],
  ): Promise<UseExchangeFlowAndNodeIdsDto[]> {
    return wsc.execute(
      '/ws/exchange/useExchangeFlowAndNodeIds',
      exchangeId,
      methodIds,
    ) as any;
  }
}
// 9d8f15d1f2e6aaaedc7a0d609eafb1ab2a5718e8fcd4a83ac036d6e84e07eda6
