import { Pipe, PipeTransform } from '@angular/core';
import escape from 'lodash/escape';

@Pipe({
  name: 'escape',
  standalone: true,
})
export class EscapePipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    return escape(value);
  }
}
