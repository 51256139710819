import { Component, ElementRef, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import { JsonViewerComponent } from '../../../../components/json-viewer/json-viewer.component';
import { FormComponent } from '../form.component';
import { CustomerServiceNode } from './class';

@Component({
  selector: 'flow-customer-service-form',
  templateUrl: './form.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatHint,
    JsonViewerComponent,
    MatSelect,
    MatOption,
    MatInput,
    FormsModule,
  ],
  host: {
    class: 'flex flex-col gap-4 w-full',
  },
})
export class CustomerServiceFormComponent
  extends FormComponent<CustomerServiceNode>
  implements OnInit
{
  public constructor(public elementRef: ElementRef) {
    super(elementRef);
  }

  public ngOnInit() {
    this.afterResponseVarChanged();
  }

  public allAgentIsBusyDemoValue: any = {};

  public customerWaitingTooLongDemoValue: any = {};

  public customerIdleDemoValue: any = {};

  public notInServiceTimeDemoValue: any = {};

  public finishedDemoValue: any = {};

  public afterResponseVarChanged() {
    this.allAgentIsBusyDemoValue = {
      tmp: {
        [this.node.responseVar]: {
          $waitingCustomerCount: '等待服務的客戶數量',
          waitingCustomerCount: 1,
          $onlineAgentCount: '服務中的客服人員數量',
          onlineAgentCount: 1,
        },
      },
    };

    this.notInServiceTimeDemoValue = {
      tmp: {
        [this.node.responseVar]: {
          $reason: '原因',
          reason: '端午節補假一天/不在客服服務時間',
          $startTime: '特殊休假日時的開始時間',
          startTime: '00:00',
          $endTime: '特殊休假日時的結束時間',
          endTime: '23:59',
        },
      },
    };

    this.customerWaitingTooLongDemoValue = {
      tmp: {
        [this.node.responseVar]: {
          $waitingSecond: '等待秒數',
          waitingSecond: '240',
        },
      },
    };

    this.customerIdleDemoValue = {
      tmp: {
        [this.node.responseVar]: {
          $idleSecond: '閒置秒數',
          idleSecond: '240',
        },
      },
    };

    this.finishedDemoValue = {
      tmp: {
        [this.node.responseVar]: {
          $recordId: '客戶服務紀錄編號',
          recordId: '1',
          $trigger:
            '觸發結束對話的原因 AGENT(客服人員) / CUSTOMER(客戶) / SYSTEM(系統) ',
          trigger: 'AGENT',
        },
      },
    };
  }
}
