import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { AccountDto, AccountFetchOverviewDto } from '@ay-gosu/server-shared';
import { arrayToObject } from '@ay/util';
import { SubscriptionLike } from 'rxjs';
import { AccountService } from '../service/account.service';

@Pipe({
  name: 'account',
  pure: false,
  standalone: true,
})
export class AccountPipe implements PipeTransform, OnDestroy {
  private _latestArg: number | AccountDto | (number | AccountDto)[] | null =
    null;

  private _latestValue: any = null;
  private _latestReturnedValue: any = null;
  private _subscription: SubscriptionLike;
  private _length: number;

  public constructor(
    private _accountService: AccountService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnDestroy(): void {
    this._dispose();
  }

  public transform(
    arg:
      | AccountFetchOverviewDto
      | number
      | (number | AccountFetchOverviewDto)[],
    length: number = Infinity,
  ) {
    this._length = length;
    if (!this._latestArg) {
      if (!(arg instanceof Array)) {
        arg = [arg];
      }

      this._subscribe(arg);
      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (arg.toString() !== this._latestArg.toString()) {
      this._dispose();
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  private _subscribe(args: (number | AccountFetchOverviewDto)[]) {
    let ids = args.filter((arg) => typeof arg === 'number') as number[];
    let hasNumber = ids.length !== 0;

    if (!hasNumber) {
      return this._updateLatestValue(args as AccountFetchOverviewDto[]);
    }

    this._subscription = this._accountService.all$.subscribe((accounts) => {
      if (accounts === null) accounts = [];
      let grouped = arrayToObject(accounts, 'id');

      args = args.map((arg) => {
        if (typeof arg === 'number') return grouped[arg];
        else return arg;
      });

      this._updateLatestValue(args as AccountFetchOverviewDto[]);
    });
  }

  private _dispose(): any {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
    this._latestValue = null;
    this._latestReturnedValue = null;
    this._latestArg = null;
  }

  private _updateLatestValue(accounts: AccountFetchOverviewDto[]) {
    accounts = accounts.filter((account) => account);
    let _latestValue = '';
    let remaining = $localize`⋯⋯，共${accounts.length}個管理者`;

    for (let idx = 0; idx < accounts.length; idx++) {
      let account = accounts[idx];

      let needLength = this._length;
      if (idx != accounts.length - 1) {
        needLength -= remaining.length;
      }

      let afterLength = (_latestValue + account.name).length;

      if (afterLength > needLength) {
        if (_latestValue === '') {
          _latestValue = `共${accounts.length}個管理者`;
        } else {
          _latestValue += remaining;
        }
        break;
      }

      if (_latestValue === '') {
        _latestValue = account.name;
      } else {
        _latestValue += '、' + account.name;
      }
    }
    this._latestValue = _latestValue;

    this._changeDetectorRef.markForCheck();
  }
}
