import { Component, forwardRef } from '@angular/core';
import { JunctionComponent } from '../../junction/junction.component';
import { ConditionComponent } from '../condition/condition.component';
import { host, NodeComponent } from '../node.component';
import { CustomerServiceNode } from './class';

@Component({
  selector: ':svg:g.customer-service.node',
  templateUrl: './node.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['node'],
  providers: [
    {
      provide: NodeComponent,
      useExisting: forwardRef(() => CustomerServiceNodeComponent),
    },
  ],
  standalone: true,
  imports: [JunctionComponent, ConditionComponent],
})
export class CustomerServiceNodeComponent extends NodeComponent<CustomerServiceNode> {}
