// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { PrivateMessage } from '@ay/bot';
import {
  CustomerServiceMessageDto,
  CustomerServicePong,
  MessageBoardStatus,
} from '@ay-gosu/types';
import { Observable } from 'rxjs';
import { AssignAgentDto } from './dto/assign-agent.dto';
import { CreateProblemCategoryRequestBodyDto } from './dto/create-problem-category-request-body.dto';
import { CreateSpecialServiceTimeDto } from './dto/create-special-service-time.dto';
import { FetchAgentStatusDto } from './dto/fetch-agent-status.dto';
import { FetchAgentsDto } from './dto/fetch-agents.dto';
import { FetchCustomerServiceReportFilterDto } from './dto/fetch-customer-service-report-filter.dto';
import { FetchCustomerServiceReportResponseDto } from './dto/fetch-customer-service-report-response.dto';
import { FetchDurationReportFilterDto } from './dto/fetch-duration-report-filter.dto';
import { FetchDurationReportResponseDto } from './dto/fetch-duration-report-response.dto';
import { FetchEfficiencyReportFilterDto } from './dto/fetch-efficiency-report-filter.dto';
import { FetchEfficiencyReportResponseDto } from './dto/fetch-efficiency-report-response.dto';
import { FetchMessageBoardResponseDto } from './dto/fetch-message-board-response.dto';
import { FetchProblemCategoryDetailFilterDto } from './dto/fetch-problem-category-detail-filter.dto';
import { FetchProblemCategoryDetailResponseDto } from './dto/fetch-problem-category-detail-response.dto';
import { FetchProblemCategoryReportFilterDto } from './dto/fetch-problem-category-report-filter.dto';
import { FetchProblemCategoryReportResponseDto } from './dto/fetch-problem-category-report-response.dto';
import { FetchProblemCategoriesDto } from './dto/fetch-problem-category.dto';
import { FetchSatisfyDetailFilterDto } from './dto/fetch-satisfy-detail-filter.dto';
import { FetchSatisfyDetailResponseDto } from './dto/fetch-satisfy-detail-response.dto';
import { FetchSatisfyReportFilterDto } from './dto/fetch-satisfy-report-filter.dto';
import { FetchSatisfyReportResponseDto } from './dto/fetch-satisfy-report-response.dto';
import { FetchServiceRecordDetailResponseDto } from './dto/fetch-service-record-detail-response.dto';
import { FetchSettingResponseDto } from './dto/fetch-setting-response.dto';
import { FetchSpecialServiceTimeResponseDto } from './dto/fetch-special-service-time-response.dto';
import { CustomerServiceSearchParamsDto } from './dto/search-params.dto';
import { CustomerServiceSearchResponseDto } from './dto/search-response.dto';
import { UpdateMessageBoardDto } from './dto/update-message-board.dto';
import { UpdateProblemCategoryOrderDto } from './dto/update-problem-category-order.dto';
import { UpdateSettingDto } from './dto/update-setting.dto';
import { UpdateSpecialServiceTimeDto } from './dto/update-special-service-time.dto';

export class CustomerServiceModel {
  static subscribe(): Observable<CustomerServiceMessageDto> {
    return wsc.subscribe('/ws/customer-service/subscribe') as any;
  }

  static ping(): Promise<CustomerServicePong> {
    return wsc.execute('/ws/customer-service/ping') as any;
  }

  static online(): Promise<void> {
    return wsc.execute('/ws/customer-service/online') as any;
  }

  static offline(): Promise<void> {
    return wsc.execute('/ws/customer-service/offline') as any;
  }

  static answer(customerServiceRecordId: number): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/answer',
      customerServiceRecordId,
    ) as any;
  }

  static finishByAgent(customerServiceRecordId: number): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/finishByAgent',
      customerServiceRecordId,
    ) as any;
  }

  static archive(
    customerServiceRecordId: number,
    problemCategoryId: number,
    note: any = '',
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/archive',
      customerServiceRecordId,
      problemCategoryId,
      note,
    ) as any;
  }

  static updateRecord(
    customerServiceRecordId: number,
    data: { problemCategoryId?: number; note?: string; source?: string },
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/updateRecord',
      customerServiceRecordId,
      data,
    ) as any;
  }

  static fetchProblemCategories(): Promise<FetchProblemCategoriesDto[]> {
    return wsc.execute('/ws/customer-service/fetchProblemCategories') as any;
  }

  static createProblemCategory(
    data: CreateProblemCategoryRequestBodyDto,
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/createProblemCategory',
      data,
    ) as any;
  }

  static updateProblemCategory(
    id: number,
    data: { name: string },
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/updateProblemCategory',
      id,
      data,
    ) as any;
  }

  static updateProblemCategoryOrder(
    data: UpdateProblemCategoryOrderDto[],
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/customer-service/updateProblemCategoryOrder',
      data,
    ) as any;
  }

  static deleteProblemCategory(id: number): Promise<void> {
    return wsc.execute('/ws/customer-service/deleteProblemCategory', id) as any;
  }

  static search(
    params: CustomerServiceSearchParamsDto,
    page: number,
    pageSize: number,
  ): Promise<CustomerServiceSearchResponseDto> {
    return wsc.execute(
      '/ws/customer-service/search',
      params,
      page,
      pageSize,
    ) as any;
  }

  static fetchHistory(
    customerServiceRecordId: number,
  ): Promise<PrivateMessage[]> {
    return wsc.execute(
      '/ws/customer-service/fetchHistory',
      customerServiceRecordId,
    ) as any;
  }

  static fetchServiceRecordDetail(
    id: number,
  ): Promise<FetchServiceRecordDetailResponseDto> {
    return wsc.execute(
      '/ws/customer-service/fetchServiceRecordDetail',
      id,
    ) as any;
  }

  static fetchSetting(): Promise<FetchSettingResponseDto> {
    return wsc.execute('/ws/customer-service/fetchSetting') as any;
  }

  static updateSetting(body: UpdateSettingDto): Promise<void> {
    return wsc.execute('/ws/customer-service/updateSetting', body) as any;
  }

  static fetchMessageBoard(
    status: MessageBoardStatus | 'ALL',
    page: any = 1,
    pageSize: any = 20,
  ): Promise<{ count: number; data: FetchMessageBoardResponseDto[] }> {
    return wsc.execute(
      '/ws/customer-service/fetchMessageBoard',
      status,
      page,
      pageSize,
    ) as any;
  }

  static updateMessageBoard(
    id: number,
    body: UpdateMessageBoardDto,
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/updateMessageBoard',
      id,
      body,
    ) as any;
  }

  // 客戶服務報表
  static fetchCustomerServiceReport(
    filter: FetchCustomerServiceReportFilterDto,
  ): Promise<FetchCustomerServiceReportResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchCustomerServiceReport',
      filter,
    ) as any;
  }

  static downloadCustomerServiceReport(
    filter: FetchCustomerServiceReportFilterDto,
  ): Promise<Buffer> {
    return wsc.execute(
      '/ws/customer-service/downloadCustomerServiceReport',
      filter,
    ) as any;
  }

  // 文字客服 服務類型 統計表
  static fetchProblemCategoryReport(
    filter: FetchProblemCategoryReportFilterDto,
  ): Promise<FetchProblemCategoryReportResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchProblemCategoryReport',
      filter,
    ) as any;
  }

  static downloadProblemCategoryReport(
    filter: FetchProblemCategoryReportFilterDto,
  ): Promise<Buffer> {
    return wsc.execute(
      '/ws/customer-service/downloadProblemCategoryReport',
      filter,
    ) as any;
  }

  // 文字客服 服務類別 明細表
  static fetchProblemCategoryDetail(
    filter: FetchProblemCategoryDetailFilterDto,
  ): Promise<FetchProblemCategoryDetailResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchProblemCategoryDetail',
      filter,
    ) as any;
  }

  static downloadProblemCategoryDetail(
    filter: FetchProblemCategoryDetailFilterDto,
  ): Promise<Buffer> {
    return wsc.execute(
      '/ws/customer-service/downloadProblemCategoryDetail',
      filter,
    ) as any;
  }

  // 客服人員 滿意度 報表
  static fetchSatisfyReport(
    filter: FetchSatisfyReportFilterDto,
  ): Promise<FetchSatisfyReportResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchSatisfyReport',
      filter,
    ) as any;
  }

  static downloadSatisfyReport(
    filter: FetchSatisfyReportFilterDto,
  ): Promise<Buffer> {
    return wsc.execute(
      '/ws/customer-service/downloadSatisfyReport',
      filter,
    ) as any;
  }

  // 客服人員 滿意度 明細表
  static fetchSatisfyDetail(
    filter: FetchSatisfyDetailFilterDto,
  ): Promise<FetchSatisfyDetailResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchSatisfyDetail',
      filter,
    ) as any;
  }

  static downloadSatisfyDetail(
    filter: FetchSatisfyDetailFilterDto,
  ): Promise<Buffer> {
    return wsc.execute(
      '/ws/customer-service/downloadSatisfyDetail',
      filter,
    ) as any;
  }

  // 客服人員 持續時間 報表
  static fetchDurationReport(
    filter: FetchDurationReportFilterDto,
  ): Promise<FetchDurationReportResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchDurationReport',
      filter,
    ) as any;
  }

  static downloadDurationReport(
    filter: FetchDurationReportFilterDto,
  ): Promise<Buffer> {
    return wsc.execute(
      '/ws/customer-service/downloadDurationReport',
      filter,
    ) as any;
  }

  // 客服人員 時間效率 分析報表
  static fetchEfficiencyReport(
    filter: FetchEfficiencyReportFilterDto,
  ): Promise<FetchEfficiencyReportResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchEfficiencyReport',
      filter,
    ) as any;
  }

  static downloadEfficiencyReport(
    filter: FetchEfficiencyReportFilterDto,
  ): Promise<Buffer> {
    return wsc.execute(
      '/ws/customer-service/downloadEfficiencyReport',
      filter,
    ) as any;
  }

  // 休息日設定
  static fetchSpecialServiceTime(
    start: Date,
    end: Date,
  ): Promise<FetchSpecialServiceTimeResponseDto[]> {
    return wsc.execute(
      '/ws/customer-service/fetchSpecialServiceTime',
      start,
      end,
    ) as any;
  }

  static createSpecialServiceTime(
    body: CreateSpecialServiceTimeDto,
  ): Promise<number> {
    return wsc.execute(
      '/ws/customer-service/createSpecialServiceTime',
      body,
    ) as any;
  }

  static updateSpecialServiceTime(
    id: number,
    body: UpdateSpecialServiceTimeDto,
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/updateSpecialServiceTime',
      id,
      body,
    ) as any;
  }

  static deleteSpecialServiceTime(id: number): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/deleteSpecialServiceTime',
      id,
    ) as any;
  }

  static fetchAgentStatus(): Promise<FetchAgentStatusDto[]> {
    return wsc.execute('/ws/customer-service/fetchAgentStatus') as any;
  }

  static fetchAgents(): Promise<FetchAgentsDto[]> {
    return wsc.execute('/ws/customer-service/fetchAgents') as any;
  }

  static assign(
    customerServiceRecordId: number,
    accountId: number,
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/assign',
      customerServiceRecordId,
      accountId,
    ) as any;
  }

  static login(uuid: string): Promise<any> {
    return wsc.execute('/ws/customer-service/login', uuid) as any;
  }

  static onOtherAgentAssignToYou(): Observable<AssignAgentDto> {
    return wsc.subscribe('/ws/customer-service/onOtherAgentAssignToYou') as any;
  }

  static read(customerServiceRecordId: number): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/read',
      customerServiceRecordId,
    ) as any;
  }

  static check(
    customerServiceRecordId: number,
    checked: boolean,
  ): Promise<void> {
    return wsc.execute(
      '/ws/customer-service/check',
      customerServiceRecordId,
      checked,
    ) as any;
  }

  static scheduledLogout(): Observable<boolean> {
    return wsc.subscribe('/ws/customer-service/scheduledLogout') as any;
  }

  static tick(): Observable<number> {
    return wsc.subscribe('/ws/customer-service/tick') as any;
  }

  static fetchSourceList(): Promise<string[]> {
    return wsc.execute('/ws/customer-service/fetchSourceList') as any;
  }
}
// 397826e386f0c03cc09cfbced9c8c4356301914626206ad9d6f308f20a1587f8
