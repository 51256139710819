// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { UploadResponse } from '@ay-nestjs/share-client';
import { Observable } from 'rxjs';
import { RichMenuDto } from './dto/rich-menu.dto';

export class RichMenuModel {
  static getList(botId: number): Promise<RichMenuDto[]> {
    return wsc.execute('/ws/rich-menu/getList', botId) as any;
  }

  static getRichMenuIdByProfileId(profileId: number): Promise<any> {
    return wsc.execute(
      '/ws/rich-menu/getRichMenuIdByProfileId',
      profileId,
    ) as any;
  }

  static linkRichMenuByProfileId(
    profileId: number,
    richMenuId: string,
  ): Promise<any> {
    return wsc.execute(
      '/ws/rich-menu/linkRichMenuByProfileId',
      profileId,
      richMenuId,
    ) as any;
  }

  static unlinkRichMenuByProfileId(profileId: number): Promise<any> {
    return wsc.execute(
      '/ws/rich-menu/unlinkRichMenuByProfileId',
      profileId,
    ) as any;
  }

  static createRichMenu(
    botId: number,
    richMenuData: RichMenuDto,
    file: File,
    fileType: any = 'png',
    checkDuplicateName: any = true,
  ): Observable<UploadResponse<string>> {
    return wsc.upload(
      '/ws/rich-menu/createRichMenu',
      botId,
      richMenuData,
      file,
      fileType,
      checkDuplicateName,
    ) as any;
  }

  static updateRichMenu(
    botId: number,
    oldRichMenuId: string,
    richMenuData: RichMenuDto,
    file: File,
    fileType: any = 'png',
  ): Observable<UploadResponse<string>> {
    return wsc.upload(
      '/ws/rich-menu/updateRichMenu',
      botId,
      oldRichMenuId,
      richMenuData,
      file,
      fileType,
    ) as any;
  }

  static deleteRichMenu(botId: number, richMenuId: string): Promise<any> {
    return wsc.execute(
      '/ws/rich-menu/deleteRichMenu',
      botId,
      richMenuId,
    ) as any;
  }

  static setDefaultRichMenu(botId: number, richMenuId: string): Promise<any> {
    return wsc.execute(
      '/ws/rich-menu/setDefaultRichMenu',
      botId,
      richMenuId,
    ) as any;
  }

  static removeDefaultRichMenu(botId: number): Promise<any> {
    return wsc.execute('/ws/rich-menu/removeDefaultRichMenu', botId) as any;
  }

  static getDefaultRichMenuId(botId: number): Promise<any> {
    return wsc.execute('/ws/rich-menu/getDefaultRichMenuId', botId) as any;
  }

  static fetchIdsFromRichMenuId(
    botId: number,
    richMenuId: string,
  ): Promise<number[]> {
    return wsc.execute(
      '/ws/rich-menu/fetchIdsFromRichMenuId',
      botId,
      richMenuId,
    ) as any;
  }
}
// e007ce50977a0dea5b3f9159e43de837dcbc8d439a13ad2fee4d74ee425ad79a
