import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatActionList, MatList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AccountModel } from '@ay-gosu/server-shared';
import { AsyncJobModule, asyncJob } from '@ay-gosu/ui/common/async-job';
import { Subject } from 'rxjs';
import { LogoComponent } from '../../components/gosu-logo/logo.component';
import { BasicDialog } from '../../dialog/basic';
import { FromNowPipe } from '../../pipe/from-now.pipe';
import { TokenService } from '../../service/token.service';

/** 這個 Component 同時出現在登入後的組織選擇頁面，也是在 Toolbar 的組織選擇 Dialog，可以用 matDialogRef 來判斷模式 */
@Component({
  selector: 'gosu-select-company',
  templateUrl: './companies-selector-dialog.component.html',
  styleUrls: ['./companies-selector-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.dialog]': 'matDialogRef',
  },
  standalone: true,
  imports: [
    LogoComponent,
    MatIconButton,
    MatIcon,
    MatLabel,
    MatList,
    MatButton,
    AsyncPipe,
    FromNowPipe,
    MatActionList,
    AsyncJobModule,
  ],
})
export class SelectCompanyComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  public account$ = this.tokenService.accountWithoutCompany$;

  public companies$ = asyncJob(
    (token) => (token ? AccountModel.getCompanyList() : []),
    this.tokenService.accountWithoutCompany$,
  );

  public async ngOnInit() {
    // 只有在選擇組織頁面才需要此功能
    if (this.matDialogRef) return;

    const payload = await this.tokenService.payload$.toPromise();

    // 如果沒有登入，導入到登入頁面
    if (!payload) {
      return this._router.navigateByUrl('/login/form');
    }

    const companies = await this.companies$.toPromise();
    // 如果沒有任何組織，導到建立組織頁面
    if (companies.length === 0) {
      return this.createCompany();

      // 如果只有一個組織，則選擇該組織
    } else if (companies.length === 1) {
      return this.selectCompany(companies[0]);
    }
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public constructor(
    public readonly tokenService: TokenService,
    private readonly _router: Router,
    private readonly _matSnackBar: MatSnackBar,
    @Optional()
    public readonly matDialogRef: MatDialogRef<any>,
    private readonly _basicDialog: BasicDialog,
  ) {}

  public async selectCompany(company: {
    id: number;
    name: string;
  }): Promise<void> {
    this.tokenService.isLoading$.next(true);

    try {
      await this.tokenService.selectCompany(company.id);
      if (this.matDialogRef) this.matDialogRef.close();

      this._router.navigate(['']);
      this._matSnackBar.open($localize`成功選擇組織 ` + company.name);
    } catch (error) {
      if (error.code === 'NOT_PASS_PASSWORD_RULES') {
        return this._whenNotPassPasswordRules(company);
      }
      console.error(error);
    }

    this.tokenService.isLoading$.next(false);
  }

  // 沒有符合密碼安全規則
  private _whenNotPassPasswordRules(company: { id: number; name: string }) {
    if (this.matDialogRef) this.matDialogRef.close();

    setTimeout(() => {
      this._basicDialog.resetPassword(company.id);
    }, 1000);
  }

  public createCompany() {
    if (this.matDialogRef) this.matDialogRef.close();

    this._router.navigateByUrl('/login/create-company');
  }
}
