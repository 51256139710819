import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

export class ReplyTextMessage extends Message<Content.ReplyText> {
  public type = 'reply-text';

  public constructor() {
    super();
    this.content = new Content.ReplyText('');
  }

  public toString() {
    return this.content.content;
  }
}
