import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ProfileDto } from '@ay-gosu/server-shared';
import { arrayToObject } from '@ay/util';
import { SubscriptionLike } from 'rxjs';
import { ProfileService } from '../service/profile.service';
@Pipe({
    name: 'profile',
    pure: false,
    standalone: true,
})
export class ProfilePipe implements PipeTransform, OnDestroy {
  private _latestArg: number | ProfileDto | (number | ProfileDto)[] | null =
    null;

  private _latestValue: any = null;
  private _latestReturnedValue: any = null;
  private _subscription: SubscriptionLike;
  private _length: number;

  public constructor(
    private _profileService: ProfileService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnDestroy(): void {
    this._dispose();
  }

  public transform(
    arg: ProfileDto | number | (number | ProfileDto)[],
    length: number = Infinity,
  ) {
    this._length = length;

    if (!this._latestArg) {
      if (!(arg instanceof Array)) {
        arg = [arg];
      }
      this._latestArg = arg;
      this._subscribe(arg);
      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (arg.toString() != this._latestArg.toString()) {
      this._dispose();
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  private async _subscribe(args: (number | ProfileDto)[]) {
    try {
      let ids = args.filter((arg) => typeof arg === 'number') as number[];
      let hasNumber = ids.length !== 0;

      if (!hasNumber) {
        return this._updateLatestValue(args as ProfileDto[]);
      }

      const profiles = await this._profileService.getMulti(ids);
      let grouped = arrayToObject(profiles, 'id');

      args = args.map((arg) => {
        if (typeof arg === 'number') return grouped[arg];
        else return arg;
      });

      this._updateLatestValue(args as ProfileDto[]);
    } catch (error) {
      console.error(error);
    }
  }

  private _dispose(): any {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
    this._latestValue = null;
    this._latestReturnedValue = null;
    this._latestArg = null;
  }

  private _updateLatestValue(profiles: ProfileDto[]) {
    profiles = profiles.filter((profile) => profile);
    let _latestValue = '';
    let remaining = $localize`⋯⋯，共${profiles.length}個使用者`;

    for (let idx = 0; idx < profiles.length; idx++) {
      const profile = profiles[idx];

      let needLength = this._length;
      if (idx != profiles.length - 1) {
        needLength -= remaining.length;
      }

      let afterLength = (_latestValue + profile.name).length;

      if (afterLength > needLength) {
        if (_latestValue === '') {
          _latestValue = $localize`共${profiles.length}個使用者`;
        } else {
          _latestValue += remaining;
        }
        break;
      }

      if (_latestValue === '') {
        _latestValue = profile.name;
      } else {
        _latestValue += '、' + profile.name;
      }
    }
    this._latestValue = _latestValue;

    this._changeDetectorRef.markForCheck();
  }
}
