import { Inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  AccountDto,
  AccountModel,
  CompanyModel,
  CompanyOverviewDto,
  GosuLoginConfigDto,
  GosuLoginModel,
} from '@ay-gosu/server-shared';
import { ConnectionService } from '@ay-gosu/ui/connection.service';
import { DialogService } from '@ay-gosu/ui/dialog';
import {
  BASE_NAVIGATE_PATH,
  IBackstageLoginService,
} from '@ay-gosu/ui/login/login.service';
import { ExposedPromise } from '@ay/util/exposed-promise';
import jwtDecode from 'jwt-decode';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatestWith,
  filter,
  firstValueFrom,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { TokenService } from './token.service';

export enum LoginStatus {
  // 還沒有處理自動登入
  INIT,
  // 未登入(已經處理完自動登入)
  NOT_LOGGED_IN,
  // 已登入，但尚未選擇組織
  NOT_CHOSEN_COMPANY,
  // 已登入，且已選擇組織
  LOGGED,
}

export const INIT = LoginStatus.INIT;
export const NOT_LOGGED_IN = LoginStatus.NOT_LOGGED_IN;
export const NOT_CHOSEN_COMPANY = LoginStatus.NOT_CHOSEN_COMPANY;
export const LOGGED = LoginStatus.LOGGED;

@Injectable({ providedIn: 'root' })
export class BackstageLoginService implements IBackstageLoginService {
  // 儲存登入後的 JWT
  // 如果是 null 則尚未登入
  // 儘管有值時，有可能還沒選擇組織
  public token$ = new ReplaySubject<string | null>(1);

  public isLoading$ = new Subject<boolean>();

  // 如果需要沒有組織的 JWT，可以使用這個
  public payload$ = this.token$.pipe(
    map((token) => {
      if (!token) return null;

      return jwtDecode(token) as AccountDto;
    }),
    shareReplay(1),
  );

  // 登入後 JWT 被解開的內容
  public payload = toSignal(this.payload$, { initialValue: null });

  // 登入後 JWT 被解開的內容
  // 儘管有值時，一定會是已經選擇組織的狀態
  public account$ = this.payload$.pipe(
    map((payload) => {
      if (!payload) return null;
      return payload.companyId ? payload : null;
    }),
    shareReplay(1),
  );

  // 帳號的 signal 版本，有可能是 null
  public account = toSignal(this.account$, { initialValue: null });

  // 登入的狀態
  public status$ = this.payload$.pipe(
    combineLatestWith(this._connectionService.status$),
    map(([account, connectionStatus]) => {
      if (connectionStatus !== '連線至伺服器') return INIT;

      if (!account) return NOT_LOGGED_IN;

      if (!account.companyId) return NOT_CHOSEN_COMPANY;

      return LOGGED;
    }),
    startWith(INIT),
  );

  // 確認是否已經登入的 signal
  public isLogged = toSignal(
    this.status$.pipe(map((status) => status === LOGGED)),
  );

  // 與 GOSU 登入相關的設定
  public config$: Observable<GosuLoginConfigDto> =
    this._connectionService.connected$.pipe(
      switchMap(() => GosuLoginModel.fetchConfig()),
      shareReplay(1),
    );

  public config = toSignal(this.config$, { initialValue: null });

  public companyList$: Observable<CompanyOverviewDto[]> = this.payload$.pipe(
    switchMap((account) => {
      if (!account) return [];

      return AccountModel.getCompanyList();
    }),
  );

  public companyList = toSignal(this.companyList$, { initialValue: [] });

  public afterAutoLogin = new ExposedPromise<void>();

  public constructor(
    private readonly _connectionService: ConnectionService,
    private readonly _router: Router,
    private readonly _dialogService: DialogService,
    @Inject('LOCAL_STORAGE_KEY')
    private readonly _localStorageKey: string,
    @Inject(BASE_NAVIGATE_PATH)
    private readonly _baseNavigatePath: string,
    private readonly _tokenService: TokenService,
  ) {
    this._storeTokenToLocalStorage();

    // 當連線完畢後，處理自動登入機制
    this._connectionService.connected$
      .pipe(
        filter((status) => status),
        map(async () => {
          if (!this.afterAutoLogin.isPending()) {
            this.afterAutoLogin = new ExposedPromise<void>();
          }

          try {
            await this._processAutoLogin();
            this.afterAutoLogin.resolve();
          } catch (error) {
            console.error(error);
            this.afterAutoLogin.reject(error);
          }
        }),
      )
      .subscribe();
  }

  public fetchConfig(): Promise<GosuLoginConfigDto> {
    return firstValueFrom(this.config$);
  }

  public async login(email: string, password: string): Promise<void> {
    const token = await this._tokenService.login(email, password);
    this.token$.next(token);
    await this.afterLogin();
  }

  public async loginViaToken(loginToken: string): Promise<void> {
    const token = await this._tokenService.loginViaToken(loginToken);
    this.token$.next(token);
    await this.afterLogin();
  }

  public async verifyInviteToken(token: string) {
    const { email } = await AccountModel.verifyInviteToken(token);
    return email;
  }

  public async fetchPasswordDefaultRules() {
    const rules = await CompanyModel.fetchPasswordDefaultRules();
    return rules;
  }

  public async register(name: string, email: string, password: string) {
    await AccountModel.register(name, email, password);
  }

  public async registerViaInvite(
    token: string,
    name: string,
    password: string,
  ) {
    await AccountModel.registerViaInvite(token, name, password);
  }

  public async sendVerifyMail() {
    await AccountModel.sendVerifyMail();
  }

  public async changePassword(oldPassword: string, password: string) {
    await AccountModel.changePassword(oldPassword, password);
  }

  public async changePasswordByToken(token: string, password: string) {
    await AccountModel.changePasswordByToken(token, password);
  }

  public async verifyResetPasswordToken(token: string, password: string) {
    await AccountModel.verifyResetPasswordToken(token);
  }

  public async afterLogin() {
    const account = await firstValueFrom(this.payload$);
    const config = await firstValueFrom(this.config$);

    if (config.email.enabled && !account?.emailVerified) {
      this._router.navigate([this._baseNavigatePath, 'login', 'verify-email'], {
        queryParams: { status: 'NOT_YET' },
      });
      return;
    }
    if (config.sms.enabled && !account?.phoneVerified) {
      this._router.navigate([this._baseNavigatePath, 'login', 'phone-verify'], {
        queryParams: { status: 'NOT_YET' },
      });
      return;
    }

    const companyList = await firstValueFrom(this.companyList$);
    switch (companyList.length) {
      // 如果沒有組織，建立組織
      case 0:
        this._router.navigate([
          this._baseNavigatePath,
          'login',
          'create-company',
        ]);
        break;

      // 如果只有一個組織，選擇該組織
      case 1:
        await this.selectCompany(companyList[0].id);
        break;

      // 如果有多個組織，選擇組織
      default:
        this._router.navigate([
          this._baseNavigatePath,
          'login',
          'select-company',
        ]);
        break;
    }
  }

  public async selectCompany(companyId: number) {
    try {
      const token = await this._tokenService.selectCompany(companyId);
      this.token$.next(token);
      this._router.navigate(['']);
      console.log('已選擇組織');
    } catch (error: any) {
      if (error?.code === 'NOT_PASS_PASSWORD_RULES') {
        await this._dialogService.failure('密碼設定不安全', {
          disableClose: true,
          messages: ['您目前的密碼不符合密碼規則，請重新設定'],
          buttons: [
            {
              text: '重設密碼',
              color: 'warn',
              click: (dialogRef) => {
                dialogRef.close();
                this._router.navigate(
                  [this._baseNavigatePath, 'login', 'reset-password'],
                  {
                    queryParams: { companyId },
                  },
                );
              },
            },
          ],
        });
      } else {
        console.error(error);
      }
    }
  }

  public async logout() {
    this._tokenService.logout();
    this.token$.next(null);
    this._router.navigate([this._baseNavigatePath, 'login', 'form']);
  }

  public async refresh() {
    const token = await AccountModel.refresh();
    if (!token) return;
    const renewed = await this._tokenService.loginViaToken(token);
    this.token$.next(renewed);

    // const renewed = await this._tokenService.loginViaToken(token);
    // this.token$.next(renewed);
  }

  public async _processAutoLogin() {
    const token = await this._tokenService.token$.toPromise();

    if (token) {
      try {
        this.token$.next(token);
      } catch (error) {
        this.token$.next(null);
      }
    } else {
      this.token$.next(null);
    }
  }

  public _storeTokenToLocalStorage() {
    // 透過 localStorage 自動登入
    const key = this._localStorageKey;
    const token = localStorage.getItem(key);
    if (token) {
      this.loginViaToken(token);
    } else {
      this.token$.next(null);
    }

    // 將 token 存回 localStorage
    this.token$.subscribe((token) => {
      if (!token) {
        localStorage.removeItem(this._localStorageKey);
      } else {
        localStorage.setItem(this._localStorageKey, token);
      }
    });
  }

  public async sendVerifySms(
    countryCode: string,
    phone: string,
  ): Promise<{ result: boolean; remainingCount: number }> {
    let res = await AccountModel.sendValidateCode(countryCode, phone);
    return res;
  }

  public async verifySms(
    countryCode: string,
    phone: string,
    code: string,
  ): Promise<string> {
    let token = await AccountModel.registerPhone(countryCode, phone, code);
    return token;
  }

  public async updateName(name: string): Promise<void> {
    await AccountModel.update({ name });
  }
}
