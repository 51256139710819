@if (mode === 'EDIT') {
  <div
    contenteditable
    class="textarea edit"
    [ngClass]="{ receive: message.direction === 'Receive' }"
    #textarea
    [innerHTML]="message.content.content | escape"
    (blur)="blur($event)"
    (mousedown)="mousedown($event)"
    (contextmenu)="stop($event)"
  ></div>
}

<div
  class="arrow"
  [ngClass]="{ receive: message.direction === 'Receive' }"
></div>
