import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DMU } from '@ay/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mat-color-picker-slider',
  templateUrl: './color-picker-slider.component.html',
  styleUrls: ['./color-picker-slider.component.scss'],
  standalone: true,
  imports: [MatIcon],
})
export class ColorPickerSliderComponent implements AfterViewInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  @ViewChild('track', { static: true })
  public track: ElementRef;

  @ViewChild('thumb', { static: true })
  public thumb: ElementRef;

  @Input()
  public trackBackground: string = '#C3C3C3';

  @Input()
  public thumbBackground: string = '#C3C3C3';

  // 最小值
  @Input()
  public min: number = 0;

  // 最大值
  @Input()
  public max: number = 100;

  public width: number = 0;

  /** value */
  private _data: number = 0;

  @Output()
  public dataChange: EventEmitter<number> = new EventEmitter<number>();

  public get data(): number {
    return this._data;
  }

  @Input()
  public set data(value: number) {
    value = !this.floatable ? parseInt(value + '') : parseFloat(value + '');
    if (value == this._data) return;
    if (isNaN(value)) value = this.min;
    else if (value > this.max) value = this.max;
    else if (value < this.min) value = this.min;
    this._data = value;
    this.renderer.setStyle(
      this.thumb.nativeElement,
      'left',
      (value / (this.max - this.min)) * 100 + '%',
    );
    this.dataChange.emit(this._data);
  }

  @Input()
  public floatable: boolean = false;

  public constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public domSanitizer: DomSanitizer,
  ) {}

  public ngAfterViewInit() {
    DMU(
      this.elementRef.nativeElement,
      () => true,
      (down) => {
        let rect = this.elementRef.nativeElement.getBoundingClientRect();
        this.data =
          ((down.pageX - rect.left) / rect.width) * (this.max - this.min);
      },
      (down, move) => {
        let rect = this.elementRef.nativeElement.getBoundingClientRect();
        this.data =
          ((move.pageX - rect.left) / rect.width) * (this.max - this.min);
      },
      (down, up) => {
        let rect = this.elementRef.nativeElement.getBoundingClientRect();
        this.data =
          ((up.pageX - rect.left) / rect.width) * (this.max - this.min);
      },
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe();

    setTimeout((f) => {
      this.width = this.thumb.nativeElement.offsetHeight;
      this.renderer.setStyle(
        this.thumb.nativeElement,
        'left',
        (this.data / (this.max - this.min)) * 100 + '%',
      );
    }, 1);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
