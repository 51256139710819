import { Injectable } from '@angular/core';
import { Content } from '@ay/bot';
import { Message } from '../base/base.message';

@Injectable()
export class AnnouncementMessage extends Message<Content.Announcement.Any> {
  public type = 'announcement';

  public async loadFromContent(content: Content.Announcement.Any) {
    this.content = content;
  }

  public toString(): string {
    return this.content.announcementType;
  }

  public toJSON(): Content.Announcement.Any {
    throw new Error('公告類訊息僅顯示用，不會儲存回資料庫');
  }
}
