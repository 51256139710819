// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { InstagramPostColumnDto } from './dto/column.dto';

export class InstagramPostModel {
  static getPostsByIds(postIds: string[]): Promise<InstagramPostColumnDto[]> {
    return wsc.execute('/ws/instagram-post/getPostsByIds', postIds) as any;
  }

  static getPosts(
    botIds: number[] = [],
    keyword: any = '',
    page: any = 1,
    limit: any = 10,
  ): Promise<{
    data: InstagramPostColumnDto[];
    page: number;
    limit: number;
    count: number;
  }> {
    return wsc.execute(
      '/ws/instagram-post/getPosts',
      botIds,
      keyword,
      page,
      limit,
    ) as any;
  }

  static syncPostsByBotIds(botIds: number[]): Promise<any> {
    return wsc.execute('/ws/instagram-post/syncPostsByBotIds', botIds) as any;
  }
}
// eb5307b542c0cd05f43babc1646aeb03f3de84283d46a958f271f9c7eb016244
