<svg:rect
  [attr.width]="width"
  [attr.height]="height"
  [attr.rx]="em"
  [attr.fill]="node.color"
  [attr.stroke]="node.borderColor"
></svg:rect>

<svg:text
  class="mi"
  [attr.x]="0.5 * em"
  [attr.y]="0.5 * em"
  [style.font-size.px]="em"
  alignment-baseline="text-before-edge"
  text-anchor="start"
  [attr.fill]="node.frontColor"
>
  {{ node.icon }}
</svg:text>

<svg:text
  class="name"
  [attr.x]="2 * em"
  [attr.y]="0.25 * em"
  [style.font-size.px]="em"
  alignment-baseline="text-before-edge"
  text-anchor="start"
  [attr.fill]="node.frontColor"
>
  {{ node?.display }}
</svg:text>

<svg:g
  class="junction"
  [index]="0"
  [dx]="0"
  [dy]="1 * em"
  type="input"
  [color]="node.borderColor"
  [node]="node"
></svg:g>

<svg:g
  class="junction"
  [index]="1"
  [dx]="width"
  [dy]="1 * em"
  type="output"
  [color]="node.borderColor"
  [node]="node"
></svg:g>

<svg:g class="conditions">
  @if (node.action === 'ENTER') {
    <svg:g class="condition" [index]="2" [y]="1" text="客服皆在忙碌中"></svg:g>

    <svg:g class="condition" [index]="3" [y]="2" text="客戶等待過久"></svg:g>

    <svg:g class="condition" [index]="4" [y]="3" text="客戶閒置過久"></svg:g>

    <svg:g class="condition" [index]="5" [y]="4" text="結束對話"></svg:g>

    <svg:g class="condition" [index]="6" [y]="5" text="不在服務時間中"></svg:g>

    <svg:g class="condition" [index]="7" [y]="6" text="其他異常狀況"></svg:g>
  } @else if (node.action === 'LEAVE') {
    <svg:g class="condition" [index]="5" [y]="1" text="其他異常狀況"></svg:g>
  }
</svg:g>
