// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Moment } from 'moment';
import { StatisticConfigDto } from './dto/config.dto';

export class StatisticModel {
  static getAll(
    start: string | Moment,
    end: string | Moment,
    botIds: number[],
  ): Promise<any> {
    return wsc.execute('/ws/statistic/getAll', start, end, botIds) as any;
  }

  static create(config: StatisticConfigDto): Promise<number> {
    return wsc.execute('/ws/statistic/create', config) as any;
  }

  static delete(configId: number): Promise<boolean> {
    return wsc.execute('/ws/statistic/delete', configId) as any;
  }

  static getAllConfig(): Promise<any> {
    return wsc.execute('/ws/statistic/getAllConfig') as any;
  }
}
// 7539a884e53e4d7de51b40922a33ea31e6f3d7b2f79162c8e97397dd63af2270
