import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'buttonType',
    standalone: true
})
export class ButtonTypePipe implements PipeTransform {
  public buttonMap = {
    postback: $localize`回傳資料`,
    message: $localize`說話`,
    uri: $localize`連結 / 檔案`,
    next: $localize`下一個流程`,
    'share-message-through-promotion': $localize`分享訊息(推廣通路)`,
    'add-through-promotion': $localize`加入機器人(推廣通路)`,
  };

  public transform(type: any): any {
    if (this.buttonMap[type]) {
      return this.buttonMap[type];
    }

    return type;
  }
}
