import { Injectable } from '@angular/core';
import {
  LingtelliGroupDto,
  LingtelliGroupFrameDto,
  LingtelliModel,
} from '@ay-gosu/server-shared';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { mergeMap, shareReplay } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class LingtelliService {
  private _changeListNotify$ = new BehaviorSubject<any>(null);

  public list$ = combineLatest([
    this._tokenService.rxAccount$,
    this._changeListNotify$,
  ]).pipe(
    mergeMap((e) => {
      return LingtelliModel.getChatbotList();
    }),
    shareReplay(1),
  );

  public constructor(private readonly _tokenService: TokenService) {}

  private _reloadList() {
    this._changeListNotify$.next(null);
  }

  public async createChatbot(
    username: string,
    password: string,
  ): Promise<boolean> {
    if (!username || !password) return false;
    return LingtelliModel.createChatbot(username, password).then((res) => {
      if (res) this._reloadList();
      return res;
    });
  }

  public async createGroup(
    lingtelliId: number,
    question: string,
  ): Promise<boolean> {
    if (!lingtelliId) return false;
    return LingtelliModel.createGroup(lingtelliId, question).then((res) => {
      return res;
    });
  }

  public async createQuestion(
    lingtelliId: number,
    group: number,
    question: string,
  ): Promise<boolean> {
    if (!lingtelliId || !group || !question) return false;
    return LingtelliModel.createQuestion(lingtelliId, group, question);
  }

  public async updateQuestion(
    lingtelliId: number,
    questionId: number,
    content: string,
  ): Promise<boolean> {
    if (!lingtelliId || !questionId || !content) return false;
    return LingtelliModel.updateQuestion(lingtelliId, questionId);
  }

  public async deleteQuestion(
    lingtelliId: number,
    questionId: number,
  ): Promise<boolean> {
    if (!lingtelliId || !questionId) return false;
    return LingtelliModel.deleteQuestion(lingtelliId, questionId);
  }

  public async deleteGroup(
    lingtelliId: number,
    group: number,
  ): Promise<boolean> {
    if (!lingtelliId || !group) return false;
    return LingtelliModel.deleteGroup(lingtelliId, group);
  }

  public async trainChatbot(lingtelliId: number) {
    if (!lingtelliId) return false;
    return LingtelliModel.trainChatbot(lingtelliId);
  }

  public async getGroups(
    lingtelliId: number,
    keyword: string = '',
    page: number = 1,
  ): Promise<LingtelliGroupFrameDto> {
    if (!lingtelliId) return;
    return LingtelliModel.getGroups(lingtelliId, keyword, page);
  }

  public async getGroup(
    lingtelliId: number,
    group: number,
  ): Promise<LingtelliGroupDto> {
    if (!lingtelliId) return;
    return LingtelliModel.getGroup(lingtelliId, group);
  }

  public async verifyAccessToken(lingtelliId: number): Promise<boolean> {
    if (!lingtelliId) return false;
    return LingtelliModel.verifyAccessToken(lingtelliId).catch((err) => false);
  }
}
