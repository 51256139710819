<div class="w-full flex flex-row gap-3">
  <mat-form-field class="flex-1">
    <mat-label>模式</mat-label>
    <mat-select [(ngModel)]="node.action">
      <mat-option value="ENTER">進入客服模式</mat-option>
      <mat-option value="LEAVE">離開客服模式</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="flex-1">
    <mat-label>回傳變數</mat-label>
    <input
      matInput
      [(ngModel)]="node.responseVar"
      (ngModelChange)="afterResponseVarChanged()"
    />
  </mat-form-field>
</div>

@if (node.action === 'ENTER') {
  <div class="w-full flex flex-row gap-3 mb-4">
    <mat-form-field class="flex-1">
      <mat-label>客戶等待過久秒數</mat-label>
      <input
        matInput
        [(ngModel)]="node.waitingTooLongSeconds"
        (ngModelChange)="afterResponseVarChanged()"
      />
      <mat-hint>可以透過逗點分隔，會觸發多次(只能是 10 的倍數)</mat-hint>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>客戶閒置過久秒數</mat-label>
      <input
        matInput
        [(ngModel)]="node.customerIdleSeconds"
        (ngModelChange)="afterResponseVarChanged()"
      />
      <mat-hint>可以透過逗點分隔，會觸發多次(只能是 10 的倍數)</mat-hint>
    </mat-form-field>
  </div>

  <div class="w-full demo">
    <h3>
      當<span class="highlight">客服人員皆在忙碌中</span
      >時將會有以下變數可以使用
    </h3>
    <div class="gosu-json-viewer-wrap" [style.height.px]="140">
      <gosu-json-viewer [json]="allAgentIsBusyDemoValue" [expandedDeep]="3">
      </gosu-json-viewer>
    </div>
  </div>

  <div class="w-full demo">
    <h3>
      當<span class="highlight">客戶等待過久</span>時將會有以下變數可以使用
    </h3>
    <div class="gosu-json-viewer-wrap" [style.height.px]="160">
      <gosu-json-viewer
        [json]="customerWaitingTooLongDemoValue"
        [expandedDeep]="3"
      >
      </gosu-json-viewer>
    </div>
  </div>

  <div class="w-full demo">
    <h3>當<span class="highlight">結束對話</span>時將會有以下變數可以使用</h3>
    <div class="gosu-json-viewer-wrap" [style.height.px]="160">
      <gosu-json-viewer [json]="finishedDemoValue" [expandedDeep]="3">
      </gosu-json-viewer>
    </div>
  </div>

  <div class="w-full demo">
    <h3>
      當<span class="highlight">客戶閒置過久</span>時將會有以下變數可以使用
    </h3>
    <div class="gosu-json-viewer-wrap" [style.height.px]="160">
      <gosu-json-viewer
        [json]="customerIdleDemoValue"
        [expandedDeep]="3"
      ></gosu-json-viewer>
    </div>
  </div>

  <div class="demo">
    <h3>
      當<span class="highlight">不在服務時間中</span>時將會有以下變數可以使用
    </h3>
    <div class="gosu-json-viewer-wrap" [style.height.px]="160">
      <gosu-json-viewer [json]="notInServiceTimeDemoValue" [expandedDeep]="3">
      </gosu-json-viewer>
    </div>
  </div>
}
