import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'facebookHashTagHtml', pure: true,
    standalone: true
})
export class FacebookHashTagHtmlPipe implements PipeTransform {
  private _regexp = new RegExp(/^(#|＃)[\p{L}|\d]+$/, 'giu');

  public constructor() {}

  public transform(content: string): string {
    if (typeof content !== 'string') return content;
    let tags = this._getHashTags(content);
    let res: string = content;
    tags.forEach((tag) => {
      let tagStr = tag.replace(/(#|＃)/gi, '');
      res = res.replace(
        tag,
        `<a class="hash-tag" href="https://www.facebook.com/hashtag/${tagStr}" target="_blank">${tag}</a>`,
      );
    });
    return res;
  }

  private _getHashTags(content: string): string[] {
    return content.match(this._regexp) || [];
  }
}
