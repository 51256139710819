import { DecimalPipe, NgFor } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { limitRange } from '@ay/util';
import { firstValueFrom } from 'rxjs';
import { BasicColorPaletteDialog } from '../../../../dialog/basic-color-palette';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { AddConditionCommand } from '../../command/addCondition';
import { DeleteConditionCommand } from '../../command/deleteCondition';
import { Invoker } from '../../command/invoker';
import { OrderConditionCommand } from '../../command/orderCondition';
import { FlowService } from '../../flow.service';
import { FormComponent } from '../form.component';
import { RandomCondition, RandomNode } from './class';

@Component({
  selector: 'flow-random-form',
  templateUrl: './form.component.html',
  styleUrls: ['form.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    FlexModule,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTooltip,
    MatButton,
    MatIconButton,
    MatIcon,
    DecimalPipe,
    LegacyAppearanceDirective,
  ],
})
export class RandomFormComponent extends FormComponent<RandomNode> {
  public node: RandomNode;

  public parseInt = parseInt;

  public get sum() {
    return this.node.conditions.reduce(
      (prev, condition) => prev + parseInt(condition.weight, 10),
      0,
    );
  }

  public constructor(
    public readonly elementRef: ElementRef,
    private readonly _flowService: FlowService,
    private readonly _invoker: Invoker,
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {
    super(elementRef);
  }

  // 新增條件
  public addCondition() {
    let condition = new RandomCondition();
    condition.name = $localize`情況` + (1 + this.node.conditions.length);
    condition.color = '#ECEFF1';
    this._invoker.do(new AddConditionCommand(this.node, condition));
  }

  public deleteCondition(index: number) {
    this._invoker.do(
      new DeleteConditionCommand(
        this._flowService,
        this.node,
        this.node.conditions[index],
      ),
    );
  }

  public async openColorPicker(
    elementRef: ElementRef,
    condition: RandomCondition,
  ) {
    const dialogRef = this._matConnectedDialog.open(BasicColorPaletteDialog, {
      elementRef,
      panelClass: 'dialog-container-p0',
      data: { color: condition.color },
    });

    const color = await firstValueFrom(dialogRef.afterClosed());
    if (color) {
      condition.color = color;
    }
  }

  public setOrderCondition(index: number, action: number): boolean {
    let tar = limitRange(0, index + action, this.node.conditions.length - 1);
    if (index == tar) return false;
    this._invoker.do(
      new OrderConditionCommand(
        this.node,
        this.node.conditions[index],
        this.node.conditions[tar],
      ),
    );
    return true;
  }
}
