<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p>{{ title }}</p>

    <mat-form-field class="fw" legacyAppearance>
      <mat-label i18n>{{ placeholder }}</mat-label>
      <input matInput formControlName="value" />
      <mat-error
        *ngIf="valueInputControl.errors && valueInputControl.errors['required']"
      >
        {{ required }}
      </mat-error>
    </mat-form-field>
    <div class="buttons">
      <button mat-button i18n>取消</button>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="form.invalid"
      >
        {{ confirm }}
      </button>
    </div>
  </form>
</div>
