import {
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { AfterContentInit, Component, ElementRef } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import {
  DirectusCollectionDto,
  DirectusFieldDto,
} from '@ay-gosu/server-shared';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IconComponent } from '../../../../components/icon/icon.component';
import { LoadingComponent } from '../../../../components/loading/loading.component';
import { LegacyAppearanceDirective } from '../../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatTooltip } from '../../../../material/tooltip/tooltip';
import { DirectusService } from '../../../../service/directus.service';
import { ExchangeService } from '../../../../service/exchange.service';
import { TokenService } from '../../../../service/token.service';
import { FormComponent } from '../form.component';
import { DirectusNode, Filter } from './class';
import { DirectusTranslationPipe } from './directus-translate.pipe';

@Component({
  selector: 'flow-directus-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FlexModule,
    MatCheckbox,
    FormsModule,
    MatIconButton,
    MatTooltip,
    IconComponent,
    MatFormField,
    MatLabel,
    MatSelect,
    MatSelectTrigger,
    NgFor,
    MatOption,
    NgSwitch,
    NgSwitchCase,
    MatInput,
    NgTemplateOutlet,
    MatButton,
    MatHint,
    LoadingComponent,
    MatIcon,
    DirectusTranslationPipe,
    LegacyAppearanceDirective,
  ],
})
export class DirectusFormComponent
  extends FormComponent<DirectusNode>
  implements AfterContentInit
{
  public relations = ['many-to-one', 'one-to-one', 'many-to-many'];
  public noValueOp = ['null', 'empty', 'nnull', 'nempty'];
  public actions = [
    { action: 'READ', icon: 'search', label: $localize`讀取` },
    { action: 'CREATE', icon: 'create', label: $localize`新增` },
    { action: 'UPDATE', icon: 'update', label: $localize`更新` },
    { action: 'DELETE', icon: 'delete', label: $localize`刪除` },
  ];
  public unauthorized = false;

  public notText = $localize`非`;

  public constructor(
    public elementRef: ElementRef,
    public exchangeService: ExchangeService,
    public tokenService: TokenService,
    public directusService: DirectusService,
  ) {
    super(elementRef);
  }

  public async ngAfterContentInit() {
    await this.initialCollections();
    await this.initialFilterField();
  }

  public async initialFilterField() {
    const fields = this.fields;
    this.node.filter.map((filter) => {
      const field = fields.find((field) => field.field === filter.field);
      filter._field = field;
    });
  }

  public collections: DirectusCollectionDto[] = null;

  public collection: DirectusCollectionDto = null;

  private async initialCollections() {
    await firstValueFrom(
      this.tokenService.rxAccount$.pipe(filter((account) => Boolean(account))),
    );

    try {
      this.collections = await this.directusService.fetchCollections();
      if (this.node.collection) {
        this.collection = this.findByCollection(this.node.collection);
      }
    } catch (error) {
      if (error.message === 'Request failed with status code 401') {
        this.unauthorized = true;
        return;
      }
      throw error;
    }
  }

  public async refresh() {
    this.collections = null;
    this.collections = await this.directusService.fetchCollections(true);
    if (this.node.collection) {
      this.collection = this.findByCollection(this.node.collection);
    }
  }

  public select(collection: string) {
    this.collection = this.findByCollection(collection);
  }

  public selectFilterField($event, filter: Filter) {
    filter._field = this.fields.find((field) => field.field === filter.field);
  }

  private findByCollection(collection: string) {
    return this.collections.find((item) => item.collection === collection);
  }

  public get fields(): DirectusFieldDto[] {
    return this.collection ? Object.values(this.collection.fields) : [];
  }

  public get statusOptions(): { name: string; value: string }[] {
    const fields = this.fields;
    const statusField = fields.find((field) => field.field === 'status');
    if (!statusField) return [];
    return Object.values(statusField.options.status_mapping);
  }

  public addFilter() {
    this.node.filter.push({ field: '', op: '', value: '' });
  }

  public removeFilter(index: number) {
    this.node.filter.splice(index, 1);
  }
}
