import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { secondButton } from '../basic-dialog.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';

@Component({
    selector: 'dl-custom-dialog',
    templateUrl: './custom.dialog.html',
    styleUrls: ['./custom.dialog.scss'],
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        MatIcon,
        MatButton,
        NgIf,
    ],
})
export class CustomDialog {
  @Input()
  public type: 'success' | 'failure' | 'alert' | 'warning' = 'success';

  @Input()
  public title: string = $localize`成功`;

  @Input()
  public confirm: string = $localize`確定`;

  @Input()
  public content: string = '';

  @Input()
  public icon: string = '';

  @Input()
  public isIconLeft: boolean = false;

  @Input()
  public secondButton: secondButton = null;

  public constructor(
    private readonly _matDialogRef: MatDialogRef<CustomDialog>,
  ) {}

  public close() {
    this._matDialogRef.close();
  }
}
