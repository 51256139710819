<div class="header w-full align-center">
  {{ name }}
</div>

<ms-preview
  *asyncJob="package$; let pkg"
  [package]="pkg"
  mode="READ"
  class="w-full mt-8 !bg-transparent h-[400px]"
>
</ms-preview>

<div class="footer w-full flat flex-row gap-3 !justify-end">
  <button mat-button color="primary" tabindex="-1" (click)="close()" i18n>
    取消
  </button>
  <button mat-flat-button color="primary" (click)="use()" i18n>插入</button>
</div>
