// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { TagDto } from './dto/tag.dto';

export class TagModel {
  static create(tag: TagDto): Promise<number> {
    return wsc.execute('/ws/tag/create', tag) as any;
  }

  static get(id: number): Promise<TagDto> {
    return wsc.execute('/ws/tag/get', id) as any;
  }

  static getAll(isParanoid: boolean = true): Promise<TagDto[]> {
    return wsc.execute('/ws/tag/getAll', isParanoid) as any;
  }

  static update(
    /** 標籤編號 */
    id: number,
    /** 更新項目 */
    tag: TagDto,
  ): Promise<any> {
    return wsc.execute('/ws/tag/update', id, tag) as any;
  }

  static delete(
    /** 標籤編號 */
    id: number,
  ): Promise<any> {
    return wsc.execute('/ws/tag/delete', id) as any;
  }
}
// 6ceb18a71c08b806558b5c5cee98d274a7d1021a0dabbf3d737f33459cfe7141
