import { A11yModule, FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  FlexibleConnectedPositionStrategy,
  GlobalPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayModule,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgModule,
  OnDestroy,
  Optional,
  Self,
  ViewContainerRef,
} from '@angular/core';
import {
  MatMenu,
  MatMenuItem,
  MatMenuModule,
  MatMenuPanel,
  MenuPositionX,
  MenuPositionY,
} from '@angular/material/menu';
import {
  merge as Merge,
  of as Of,
  Subscription,
  asapScheduler,
  from,
  fromEvent,
} from 'rxjs';
import { delay, filter, first, map, takeUntil } from 'rxjs/operators';

export const MENU_PANEL_TOP_PADDING = 8;

@Directive({
  selector: '[matContextMenu]',
  standalone: true,
})
export class MatContextMenuDirective implements AfterContentInit, OnDestroy {
  private _portal: TemplatePortal;
  public positionStrategy: GlobalPositionStrategy;

  private _config: OverlayConfig;

  //#region @Two-way() position
  private _position: number = 0;

  public get position(): number {
    return this._position;
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('matContextMenuCustomPosition')
  public set position(position: number) {
    if (this._position === position) return;
    this._position = position;
  }
  //#endregion @Two-way() position

  //#region @Two-way() disable
  private _disable: boolean = false;

  public get disable(): boolean {
    return this._disable;
  }

  @Input('matContextMenuDisable')
  public set disable(disable: boolean) {
    disable = coerceBooleanProperty(disable);
    if (this._disable === disable) return;
    this._disable = disable;
  }
  //#endregion @Two-way() disable

  //#region @Two-way() menu
  private _menu: MatMenuPanel = null;

  public get menu(): MatMenuPanel {
    return this._menu;
  }

  @Input('matContextMenu')
  public set menu(menu: MatMenuPanel) {
    if (this._menu === menu) return;
    this._menu = menu;
    this._menuCloseSubscription.unsubscribe();

    if (menu) {
      this._menuCloseSubscription = menu.close
        .asObservable()
        .subscribe((reason) => {
          this.destroyMenu();

          // If a click closed the menu, we should close the entire chain of nested menus.
          if ((reason === 'click' || reason === 'tab') && this._parentMenu) {
            this._parentMenu.closed.emit(reason);
          }
        });
    }
  }
  //#endregion @Two-way() menu

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('matContextMenuData')
  public menuData: any;

  //#region @Two-way() withoutChildren
  private _withoutChildren: boolean = false;

  public get withoutChildren(): boolean {
    return this._withoutChildren;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('matWithoutChildren')
  public set withoutChildren(withoutChildren: boolean) {
    withoutChildren = coerceBooleanProperty(withoutChildren);
    if (this._withoutChildren === withoutChildren) return;
    this._withoutChildren = withoutChildren;
  }
  //#endregion @Two-way() withoutChildren

  private _menuOpen: boolean = false;
  private _overlayRef: OverlayRef = null;
  private _closeSubscription = Subscription.EMPTY;
  private _hoverSubscription = Subscription.EMPTY;
  private _menuCloseSubscription = Subscription.EMPTY;

  public constructor(
    private _overlay: Overlay,
    private _elementRef: ElementRef<HTMLElement>,
    private _viewContainerRef: ViewContainerRef,
    @Optional()
    private _parentMenu: MatMenu,
    @Optional()
    @Self()
    private _menuItemInstance: MatMenuItem,
    @Optional()
    private _dir: Directionality,
    private _focusMonitor: FocusMonitor,
  ) {}

  public ngAfterContentInit() {
    this.checkMenu();
    this.handleHover();
  }

  @HostListener('contextmenu', ['$event'])
  public onContextmenu($event: MouseEvent) {
    if (this.disable) {
      return true;
    }

    if (
      this.withoutChildren &&
      $event.target !== this._elementRef.nativeElement
    ) {
      return;
    }

    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.openMenu($event);
  }

  public openMenu($event: MouseEvent) {
    if (this._menuOpen) return;
    this.checkMenu();
    const overlayRef = this.createOverlay($event);
    const portal = this.getPortal();

    overlayRef.attach(portal);

    if (this.menu.lazyContent) {
      this.menu.lazyContent.attach({
        $implicit: $event,
        $event,
        ...this.menuData,
      });
    }

    this.setPosition(
      this._config.positionStrategy as FlexibleConnectedPositionStrategy,
      $event,
      overlayRef,
    );

    this._closeSubscription = this.menuClosingActions().subscribe(() =>
      this.closeMenu(),
    );

    this.menu.setPositionClasses('before', 'above');
    this.initMenu();
    if (this.menu instanceof MatMenu) {
      this.menu._startAnimation();
    }
  }
  private initMenu(): void {
    this.menu.parentMenu = this.triggersSubmenu()
      ? this._parentMenu
      : undefined;
    this.menu.direction = this._dir.value === 'rtl' ? 'rtl' : 'ltr';
    this.setMenuElevation();
    this.setIsMenuOpen(true);
    this.menu.focusFirstItem('mouse' || 'program');
  }

  private setIsMenuOpen(isOpen: boolean): void {
    this._menuOpen = isOpen;

    if (this.triggersSubmenu()) {
      this._menuItemInstance._highlighted = isOpen;
    }
  }

  private setMenuElevation(): void {
    if (this.menu.setElevation) {
      let depth = 0;
      let parentMenu = this.menu.parentMenu;

      while (parentMenu) {
        depth++;
        parentMenu = parentMenu.parentMenu;
      }

      this.menu.setElevation(depth);
    }
  }

  public closeMenu(): any {
    this.menu.close.emit();
  }

  private menuClosingActions(): any {
    const windowContextMenu = fromEvent(window, 'contextmenu').pipe(
      map(($event) => {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        $event.stopPropagation();
      }),
    );
    const backdrop = this._overlayRef!.backdropClick();
    const detachments = this._overlayRef!.detachments();
    const parentClose = this._parentMenu ? from(this._parentMenu.closed) : Of();
    const hover = this._parentMenu
      ? this._parentMenu._hovered().pipe(
          filter((active) => active !== this._menuItemInstance),
          filter(() => this._menuOpen),
        )
      : Of();

    return Merge(windowContextMenu, backdrop, parentClose, hover, detachments);
  }

  private getPortal(): TemplatePortal {
    if (!this._portal || this._portal.templateRef !== this.menu.templateRef) {
      this._portal = new TemplatePortal(
        this.menu.templateRef,
        this._viewContainerRef,
      );
    }

    return this._portal;
  }

  private createOverlay($event: MouseEvent): OverlayRef {
    if (!this._overlayRef) {
      const config = this.getOverlayConfig();
      this.subscribeToPositions(
        config.positionStrategy as FlexibleConnectedPositionStrategy,
      );
      this._overlayRef = this._overlay.create(config);

      this._overlayRef.keydownEvents().subscribe();
      this._config = config;
    }
    this.initPosition(
      this._config.positionStrategy as FlexibleConnectedPositionStrategy,
    );

    return this._overlayRef;
  }

  private initPosition(positionStrategy: FlexibleConnectedPositionStrategy) {
    positionStrategy.withPositions([
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 0,
        offsetY: 0,
      },
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: 0,
        offsetY: 0,
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: 0,
      },
    ]);
  }

  private setPosition(
    positionStrategy: FlexibleConnectedPositionStrategy,
    $event: MouseEvent,
    overlayRef: OverlayRef,
  ) {
    let rect =
      this._elementRef.nativeElement.getBoundingClientRect() as DOMRect;

    const overlayWidth = overlayRef.overlayElement.children.length
      ? overlayRef.overlayElement.children[0].clientWidth
      : overlayRef.overlayElement.clientWidth;

    const offsetX =
      $event.clientX - rect.x + overlayWidth > rect.width ? overlayWidth : 0;

    positionStrategy.withPositions([
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: $event.clientX - this.position - rect.x - offsetX,
        offsetY: $event.clientY - rect.y,
      },
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: $event.clientX - this.position - rect.x - offsetX,
        offsetY: $event.clientY - rect.y - rect.height / 2,
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX: $event.clientX - this.position - rect.x - offsetX,
        offsetY: $event.clientY - rect.y,
      },
    ]);
  }

  private subscribeToPositions(
    position: FlexibleConnectedPositionStrategy,
  ): void {
    if (this.menu.setPositionClasses) {
      position.positionChanges.subscribe((change) => {
        const posX: MenuPositionX =
          change.connectionPair.overlayX === 'start' ? 'after' : 'before';
        const posY: MenuPositionY =
          change.connectionPair.overlayY === 'top' ? 'below' : 'above';

        this.menu.setPositionClasses!(posX, posY);
      });
    }
  }

  private getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elementRef)
        .withLockedPosition()
        .withTransformOriginOn('.mat-menu-panel'),

      hasBackdrop:
        this.menu.hasBackdrop == null
          ? !this.triggersSubmenu()
          : this.menu.hasBackdrop,

      backdropClass:
        this.menu.backdropClass || 'cdk-overlay-transparent-backdrop',

      direction: this._dir,
    });
  }

  private triggersSubmenu(): any {
    return !!(this._menuItemInstance && this._parentMenu);
  }

  private checkMenu(): any {
    if (this.disable) return;
    if (!this.menu) {
      throw Error(`matContextMenu: must pass in an mat-menu instance.
      Example:
      <mat-menu #menu="matMenu"></mat-menu>
      <div [matContextMenu]="menu"></div>`);
    }
  }

  private destroyMenu(): any {
    if (!this._overlayRef || !this._menuOpen) {
      return;
    }

    const menu = this.menu;

    this._closeSubscription.unsubscribe();
    this._overlayRef.detach();

    if (menu instanceof MatMenu) {
      menu._resetAnimation();

      if (menu.lazyContent) {
        // Wait for the exit animation to finish before detaching the content.
        menu._animationDone
          .pipe(
            first((event) => event.toState === 'void'),
            // Interrupt if the content got re-attached.
            takeUntil(menu.lazyContent._attached),
          )
          .subscribe({
            next: () => menu.lazyContent!.detach(),
            complete: () => {
              // No matter whether the content got re-attached, reset the menu.
              this.resetMenu();
            },
          });
      } else {
        this.resetMenu();
      }
    } else {
      this.resetMenu();

      if (menu.lazyContent) {
        menu.lazyContent.detach();
      }
    }
  }

  private resetMenu(): void {
    this.setIsMenuOpen(false);

    // We should reset focus if the user is navigating using a keyboard or
    // if we have a top-level trigger which might cause focus to be lost
    // when clicking on the backdrop.
    if (!this.triggersSubmenu()) {
      this.focus('mouse');
    }
  }

  public focus(origin: FocusOrigin = 'program') {
    if (this._focusMonitor) {
      this._focusMonitor.focusVia(this._elementRef, origin);
    } else {
      this._elementRef.nativeElement.focus();
    }
  }

  /** Handles the cases where the user hovers over the trigger. */
  private handleHover() {
    // Subscribe to changes in the hovered item in order to toggle the panel.
    if (!this.triggersSubmenu()) {
      return;
    }

    this._hoverSubscription = this._parentMenu
      ._hovered()
      // Since we might have multiple competing triggers for the same menu (e.g. a sub-menu
      // with different data and triggers), we have to delay it by a tick to ensure that
      // it won't be closed immediately after it is opened.
      .pipe(
        filter(
          (active) => active === this._menuItemInstance && !active.disabled,
        ),
        delay(0, asapScheduler),
      )
      .subscribe(() => {
        // If the same menu is used between multiple triggers, it might still be animating
        // while the new trigger tries to re-open it. Wait for the animation to finish
        // before doing so. Also interrupt if the user moves to another item.
        if (this.menu instanceof MatMenu && this.menu._isAnimating) {
          // We need the `delay(0)` here in order to avoid
          // 'changed after checked' errors in some cases. See #12194.
          this.menu._animationDone
            .pipe(
              first(),
              delay(0, asapScheduler),
              takeUntil(this._parentMenu._hovered()),
            )
            .subscribe(() => this.openMenu(null));
        } else {
          this.openMenu(null);
        }
      });
  }

  public ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }

    this.cleanUpSubscriptions();
  }

  /** Cleans up the active subscriptions. */
  private cleanUpSubscriptions(): void {
    this._closeSubscription.unsubscribe();
    this._hoverSubscription.unsubscribe();
  }
}

@NgModule({
  imports: [MatMenuModule, A11yModule, OverlayModule, MatContextMenuDirective],
  exports: [MatContextMenuDirective],
})
export class MatContextMenuModule {}
