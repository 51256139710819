import { Injectable } from '@angular/core';
import { ProfileDto, ProfileModel } from '@ay-gosu/server-shared';
import { CacheableService } from './cacheable.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends CacheableService<ProfileDto> {
  protected async _get(ids: number[]): Promise<ProfileDto[]> {
    return ProfileModel.find({ id: ids }, false, {
      info: true,
      tag: true,
      property: true,
    }).then((rows) =>
      rows.map((profile) => ({
        ...profile,
        property: profile['propertyMap'],
        tag: profile['tagMap'],
      })),
    );
  }
}
