<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container">
    <mat-form-field legacyAppearance>
      <mat-label i18n>名稱</mat-label>
      <input matInput formControlName="name" />
      <mat-error
        *ngIf="nameInputControl.errors && nameInputControl.errors.required"
        i18n
      >
        必填欄位
      </mat-error>
    </mat-form-field>
  </div>
  <button
    class="!rounded-none"
    mat-raised-button
    type="submit"
    color="primary"
    [disabled]="form.invalid"
    i18n
  >
    建立副本
  </button>
</form>
