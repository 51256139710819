import { Bot } from './bot';
import { Company } from './company';
import { Profile } from './profile';

/** 平台類型 */
export type TargetType =
  | 'Facebook'
  | 'Line'
  | 'MDBS'
  | 'Gosu'
  | 'WebChat'
  | 'Instagram';

export type GosuTargetType = 'bot' | 'account' | 'profile' | 'company';

export type LineSourceType = 'user' | 'group' | 'room';

export namespace Target {
  export type Any = Line | Facebook | Instagram | MDBS | Gosu | WebChat;

  export class Base {
    public profile?: Profile;
    public bot?: Bot;
    public company?: Company;

    public constructor(
      /** 平台類型 */
      public platformType: TargetType,
      /** 使用者編號 */
      public platformId: string,
      /** 額外資訊 */
      public extra?: {
        commentId?: string;
      },
    ) {}
  }

  export class Line extends Base {
    public userId?: string;
    public user?: Profile;
    public groupId?: string;
    public group?: Profile;
    public roomId?: string;
    public room?: Profile;
    public replyToken?: string;
    public type?: LineSourceType;

    public constructor(
      /** 使用者編號 */
      platformId: string,
      /** 額外資訊 */
      extra?: any,
    ) {
      super('Line', platformId, extra);
    }
  }

  export class Facebook extends Base {
    public constructor(
      /** 使用者編號 */
      platformId: string,
      /** 額外資訊 */
      extra?: any,
    ) {
      super('Facebook', platformId, extra);
    }
  }

  export class Instagram extends Base {
    public constructor(
      /** 使用者編號 */
      platformId: string,
      /** 額外資訊 */
      extra?: any,
    ) {
      super('Instagram', platformId, extra);
    }
  }

  export class MDBS extends Base {
    public constructor(
      /** 使用者編號 */
      platformId: string,
      /** 額外資訊 */
      extra?: any,
    ) {
      super('MDBS', platformId, extra);
    }
  }

  export class Gosu extends Base {
    public constructor(
      /** platformId type */
      public type: GosuTargetType,
      platformId: string,
      /** 額外資訊 */
      extra?: any,
    ) {
      super('Gosu', platformId, extra);
    }
  }

  export class WebChat extends Base {
    public userId?: string;

    public constructor(
      /** 使用者編號 */
      platformId: string,
    ) {
      super('WebChat', platformId);
    }
  }
}
