import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormFieldControl } from '@angular/material/form-field';
import { DomSanitizer } from '@angular/platform-browser';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { ElementRefDirective } from '@ay-gosu/ui/common/element-ref';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmptyResponseError } from '../../../util/empty-response-error';
import { BasicDialog } from '../../dialog/basic';
import { ImagePickerDialog } from '../../dialog/image/picker/picker.dialog';
import { ImageTypePipe } from '../../pipe/imageType.pipe';

@Component({
  selector: 'gosu-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: ImagePickerComponent },
  ],
  standalone: true,
  imports: [NgIf, MatButton, ElementRefDirective, ImageTypePipe],
})
export class ImagePickerComponent
  implements MatFormFieldControl<string>, ControlValueAccessor, OnDestroy
{
  @Input()
  public onlyButton = false;

  //#region @Two-way() autoSize
  private _autoSize: boolean = false;

  private _isOpen: boolean = false;

  @Input()
  public mode = 'server';

  public get autoSize(): boolean {
    return this._autoSize;
  }

  @Input()
  public set autoSize(autoSize: boolean) {
    autoSize = coerceBooleanProperty(autoSize);
    if (this._autoSize === autoSize) return;
    this._autoSize = autoSize;
  }
  //#endregion @Two-way() autoSize

  @Input()
  public types: string[] = null;

  @Input()
  public width: number | [number, number] = null;

  @Input()
  public height: number | [number, number] = null;

  @Input()
  public size: number = null;

  @Input()
  public useCode = false;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    private readonly _matConnectedDialog: MatConnectedDialog,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _basicDialog: BasicDialog,
    private readonly _focusMonitor: FocusMonitor,
    private readonly _elementRef: ElementRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.stateChanges.next();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.valueChange.pipe(takeUntil(this._destroy$)).subscribe((e) => fn(e));
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  //#region @Two-way() value
  private _value: string = '';

  @Output()
  public valueChange = new EventEmitter<string>();

  public get value(): string {
    return this._value;
  }

  @Input()
  public set value(value: string) {
    if (this._value === value) return;
    this._value = value;
    this.valueChange.emit(this._value);
    this.stateChanges.next();
  }
  //#endregion @Two-way() value

  public stateChanges = new Subject<void>();

  public static nextId = 0;

  @HostBinding()
  public id = `gosu-image-picker-${ImagePickerComponent.nextId++}`;

  //#region @Two-way() placeholder
  private _placeholder: string;

  public get placeholder() {
    return this._placeholder;
  }

  @Input()
  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  //#endregion @Two-way() placeholder

  public focused: boolean = false;

  public get empty() {
    return !this.value;
  }

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  //#region @Two-way() placeholder
  @Input()
  private _required = false;

  public get required() {
    return this._required;
  }

  public set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  //#endregion @Two-way() placeholder

  //#region @Two-way() disabled
  @Input()
  private _disabled = false;

  public get disabled() {
    return this._disabled;
  }

  public set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  //#endregion @Two-way() disabled

  public errorState: boolean = false;

  public controlType?: string = 'gosu-image-picker';

  public autofilled: boolean = false;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent): void {
    this.pickImage(null);
  }

  public backgroundImage(url: string) {
    let style = `url(${url})`;
    return this._domSanitizer.bypassSecurityTrustStyle(style);
  }

  public async pickImage(elementRef?: ElementRef) {
    if (this._isOpen) return;
    this._isOpen = true;
    try {
      const dialog = this._matConnectedDialog.open(ImagePickerDialog, {
        data: { url: this.value, useCode: this.useCode },
        elementRef,
      });
      const instance = dialog.componentInstance;
      instance.mode = this.mode;
      instance.types = this.types;
      instance.width = this.width;
      instance.height = this.height;
      instance.size = this.size;
      let res = await firstValueFrom(dialog.afterClosed());
      if (res === undefined) {
        this._isOpen = false;
        return null;
      }
      this.value = res;
    } catch (error) {
      if (error instanceof EmptyResponseError) {
        this._isOpen = false;
        return;
      }
      throw error;
    }
    this._isOpen = false;
  }

  public async deleteImage(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    let res = await this._basicDialog.confirm($localize`是否確定要刪除？`);
    if (res) this.value = null;
    return false;
  }
}
