<button
  mat-flat-button
  [style.backgroundImage]="backgroundImage$ | async"
  [ngClass]="{
    '!h-[24px]': !!matFormField,
    '!text-black': isLight
  }"
  (click)="open()"
>
  @if (matFormField) {
    {{ hex }}
  }
</button>
