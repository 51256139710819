import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';

@Component({
  selector: 'dl-confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    MatDialogClose,
    MatFormField,
    LegacyAppearanceDirective,
  ],
})
export class ConfirmDialog {
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      content?: string;
      ok?: string;
      cancel?: string;
      htmlContent?: string;
    },
  ) {}
}
