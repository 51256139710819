// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { UploadResponse } from '@ay-nestjs/share-client';
import { Observable } from 'rxjs';

export class StaticModel {
  static upload(
    /** 檔案名稱 */
    filename: string,
    /** 檔案 */
    file: File,
  ): Observable<UploadResponse<string>> {
    return wsc.upload('/ws/static/upload', filename, file) as any;
  }
}
// 5bfc9634390584d8e0d4158953701bb19bbb2cc92f66846577f5e261909a6a23
