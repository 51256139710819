import { ArrayDataSource, DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { TagDto, TagModel } from '@ay-gosu/server-shared';
import { Map, arrayToObject } from '@ay/util';
import { map, shareReplay } from 'rxjs/operators';
import { filterList } from '../../util/filter-list';
import { ReadableError } from '../../util/readable-error';
import {
  MatTreePickerNode,
  MatTreePickerNodeList,
} from '../material/tree-picker';
import { PreloadService } from './preload.service';
import { TokenService } from './token.service';

export type TagTargetType = 'profile' | 'package';

export interface NodeData {
  nodeDirective?: MatTreePickerNode<any>;
  nodeListDirective?: MatTreePickerNodeList<any>;
  getChildren(): DataSource<NodeData>;
  hasChildren(): boolean | Promise<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class TagService extends PreloadService<TagDto> {
  /* #region all */
  protected packageOrProfileList$ = this.all$.pipe(
    filterList(
      (tag) =>
        !tag.isSystemDefault && (tag.applyToPackage || tag.applyToProfile),
    ),
    shareReplay(1),
  );

  protected packageOrProfileTree$ = this.packageOrProfileList$.pipe(
    map((list) => this._covertToTreeNode(list)),
    shareReplay(1),
  );

  protected packageOrProfileMap$ = this.packageOrProfileList$.pipe(
    map((list) => this._convertToMap(list)),
    shareReplay(1),
  );
  /* #endregion all*/

  /* #region package */
  /* #region package all */
  protected packageAllList$ = this.all$.pipe(
    filterList((tag) => tag.applyToPackage),
    shareReplay(1),
  );

  protected packageAllTree$ = this.packageAllList$.pipe(
    map((list) => this._covertToTreeNode(list)),
    shareReplay(1),
  );

  protected packageAllMap$ = this.packageAllList$.pipe(
    map((list) => this._convertToMap(list)),
    shareReplay(1),
  );
  /* #endregion package all*/

  /* #region package showOnList */
  protected packageShowOnListList$ = this.packageAllTree$.pipe(
    filterList((tag) => tag.showOnPackageList),
    shareReplay(1),
  );

  protected packageShowOnListTree$ = this.packageShowOnListList$.pipe(
    map((list) => this._covertToTreeNode(list)),
    shareReplay(1),
  );
  /* #endregion */

  /* #region package showOnDetail */
  protected packageShowOnDetailList$ = this.packageAllList$.pipe(
    filterList((tag) => tag.showOnPackageDetail),
    shareReplay(1),
  );

  protected packageShowOnDetailTree$ = this.packageShowOnDetailList$.pipe(
    map((list) => this._covertToTreeNode(list)),
    shareReplay(1),
  );
  /* #endregion */
  /* #endregion */
  /* #endregion */

  /* #region profile */
  /* #region profile all */
  protected profileAllList$ = this.all$.pipe(
    filterList((tag) => tag.applyToProfile),
    shareReplay(1),
  );

  protected profileAllTree$ = this.profileAllList$.pipe(
    map((list) => this._covertToTreeNode(list)),
    shareReplay(1),
  );

  protected profileAllMap$ = this.profileAllList$.pipe(
    map((list) => this._convertToMap(list)),
    shareReplay(1),
  );
  /* #endregion */

  /* #region profile showOnList */
  protected profileShowOnListList$ = this.profileAllTree$.pipe(
    filterList((tag) => tag.showOnProfileList),
    shareReplay(1),
  );

  protected profileShowOnListTree$ = this.profileShowOnListList$.pipe(
    map((list) => this._covertToTreeNode(list)),
    shareReplay(1),
  );
  /* #endregion */

  /* #region profile showOnDetail */
  protected profileShowOnDetailList$ = this.profileAllList$.pipe(
    filterList((tag) => tag.showOnProfileDetail),
    shareReplay(1),
  );

  protected profileShowOnDetailTree$ = this.profileShowOnDetailList$.pipe(
    map((list) => this._covertToTreeNode(list)),
    shareReplay(1),
  );
  /* #endregion */
  /* #endregion */

  public packageOrProfile = {
    list$: this.packageOrProfileList$.pipe(
      map((tags) => tags.filter((tag) => !tag.deletedAt)),
    ),
    tree$: this.packageOrProfileTree$.pipe(
      map((tags) => this.removeNodeDeletedAt(tags)),
    ),
    map$: this.packageOrProfileMap$,
  };

  public package = {
    all: {
      list$: this.packageAllList$.pipe(
        map((tags) => tags.filter((tag) => !tag.deletedAt)),
      ),
      tree$: this.packageAllTree$.pipe(
        map((tags) => this.removeNodeDeletedAt(tags)),
      ),
      map$: this.packageAllMap$,
    },
    showAllWithDeleted: {
      list$: this.packageAllList$,
      tree$: this.packageAllTree$,
    },
    showOnList: {
      list$: this.packageShowOnListList$,
      tree$: this.packageShowOnListTree$,
    },
    showOnDetail: {
      list$: this.packageShowOnDetailList$,
      tree$: this.packageShowOnDetailTree$,
    },
  };

  public profile = {
    all: {
      list$: this.profileAllList$.pipe(
        map((tags) => tags.filter((tag) => !tag.deletedAt)),
      ),
      tree$: this.profileAllTree$.pipe(
        map((tags) => this.removeNodeDeletedAt(tags)),
      ),
      map$: this.profileAllMap$,
    },
    showAllWithDeleted: {
      list$: this.profileAllList$,
      tree$: this.profileAllTree$,
    },
    showOnList: {
      list$: this.profileShowOnListList$,
      tree$: this.profileShowOnListTree$,
    },
    showOnDetail: {
      list$: this.profileShowOnDetailList$,
      tree$: this.profileShowOnDetailTree$,
    },
  };

  private removeDtoDeletedAt(tags: TagDto[]): TagDto[] {
    tags.forEach((tag) => {
      if (tag.children && tag.children.length > 0) {
        this.removeDtoDeletedAt(tag.children);
        tag.children = this.removeDtoDeletedAt(tag.children);
      }
    });
    tags = tags.filter((tag) => !tag.deletedAt);
    return tags;
  }

  private removeNodeDeletedAt(tags: TagNode[]): TagNode[] {
    tags.forEach((tag) => {
      if (tag.children && tag.children.length > 0) {
        tag.children = this.removeNodeDeletedAt(tag.children);
      }
    });
    tags = tags.filter((tag) => !tag.deletedAt);
    return tags;
  }

  protected keywordList$ = this.all$.pipe(
    filterList((tag) => tag.applyToKeyword),
    shareReplay(1),
  );

  protected keywordTree$ = this.all$.pipe(
    map((tags) => this._covertToTreeNode(tags)),
    shareReplay(1),
  );

  public keyword = {
    list$: this.keywordList$.pipe(
      map((tags) => tags.filter((tag) => !tag.deletedAt)),
    ),
    tree$: this.keywordTree$.pipe(
      map((tags) => this.removeNodeDeletedAt(tags)),
    ),
  };

  public constructor(protected override readonly _tokenService: TokenService) {
    super(_tokenService);
  }

  protected get(id: number): Promise<TagDto> {
    return TagModel.get(id);
  }

  protected async load(): Promise<TagDto[]> {
    let data = await TagModel.getAll(false);
    return data;
  }

  private _convertToMap(tags: TagDto[]): {
    [key: string]: TagDto;
  } {
    if (tags === null) return null;
    let map = arrayToObject(tags, 'id');
    return map;
  }

  private _covertToTreeNode(tags: TagDto[]): TagNode[] {
    if (!tags) return null;

    let root = new TagNode();

    let nodes = tags.map((tag) => new TagNode(tag));
    let map = arrayToObject(nodes, 'id');

    nodes.map((node) => {
      node.children = [];
    });

    nodes.map((node) => {
      let parent: TagNode = null;
      if (node.tagId && map[node.tagId]) {
        parent = map[node.tagId];
      } else {
        parent = root;
      }
      parent.children.push(node);
    });

    return root.children;
  }

  public getTagListByType(targetType: TagTargetType) {
    switch (targetType) {
      case 'profile':
        return this.profile.all.list$;

      case 'package':
        return this.package.all.list$;

      default:
        throw $localize`不支援的 target type: ${targetType}`;
    }
  }

  public getTagTreeByType(targetType: TagTargetType) {
    switch (targetType) {
      case 'profile':
        return this.profile.all.tree$.pipe(
          map((tags) => this._filterTags(tags)),
        );

      case 'package':
        return this.package.all.tree$.pipe(
          map((tags) => this._filterTags(tags)),
        );

      default:
        throw $localize`不支援的 target type: ${targetType}`;
    }
  }

  private _filterTags(tags: TagNode[]) {
    return tags.filter((tag) => !this.noShowTags.includes(tag.name));
  }

  public async create(tag: TagDto) {
    try {
      tag.showOnProfileDetail = true;
      tag.showOnProfileList = true;
      tag.applyToProfile = true;
      let id = await TagModel.create(tag);
      tag.id = id;
      let all = await this.load();
      this._all$.next(all);
      return id;
    } catch (error) {
      throw new ReadableError($localize`建立標籤時發生錯誤`, { error, tag });
    }
  }

  public async update(id: number, tag: TagDto) {
    try {
      await TagModel.update(id, tag);
      let all = await this.load();
      this._all$.next(all);
    } catch (error) {
      console.log(error);
      throw new ReadableError($localize`編輯標籤時發生錯誤`, { error, tag });
    }
  }

  public async bulkUpdate(ids: number[], tag: TagDto) {
    try {
      for (let id of ids) {
        await TagModel.update(id, tag);
      }
      let all = await this.load();
      this._all$.next(all);
    } catch (error) {
      console.log(error);
      throw new ReadableError($localize`批次更新標籤時發生錯誤`, {
        error,
        tag,
      });
    }
  }

  public async delete(id: number) {
    try {
      await TagModel.delete(id);
      let all = await this.load();
      this._all$.next(all);
    } catch (error) {
      throw new ReadableError($localize`刪除標籤時發生錯誤`, { error, id });
    }
  }

  public toTreePickerNodes(tags: TagDto[]): (TagDto & NodeData)[] {
    return tags.map((tag) => ({
      ...tag,
      hasChildren: () => tag.children && tag.children.length !== 0,
      getChildren: () =>
        new ArrayDataSource(this.toTreePickerNodes(tag.children)),
    }));
  }

  public noShowTags = [
    `*關鍵字`,
    `*關鍵字同義詞`,
    `*專案`,
    `*文章`,
    `*發送狀態`,
  ];
}

export class TagNode extends TagDto implements NodeData {
  public override children: TagNode[] = [];

  public constructor(tag?: TagDto) {
    super();
    if (tag) {
      for (let property in tag) {
        this[property] = tag[property];
      }
    }
  }

  public getChildren(): DataSource<NodeData> {
    return new ArrayDataSource(this.children);
  }

  public hasChildren(): boolean | Promise<boolean> {
    return this.children.length !== 0;
  }
}

export class UnknownTagError extends ReadableError {
  public constructor(id: number, tags: TagDto[] | Map<TagDto>) {
    super($localize`未知的標籤'${id}'`, { id, tags });
  }
}
