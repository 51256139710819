import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { BasicDialog } from './dialog/basic';
import { PageService } from './service/page.service';
import { TokenService } from './service/token.service';
import { VerifyToken } from './verify-token';

@Injectable({
  providedIn: 'root',
})
export class VerifyTokenAndAuth extends VerifyToken {
  public tempRouterUrlState = '';

  public constructor(
    protected readonly _tokenService: TokenService,
    protected readonly _router: Router,
    protected readonly _basicDialog: BasicDialog,
    protected readonly _pageService: PageService,
  ) {
    super(_tokenService, _router, _basicDialog, _pageService);
  }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (!super.canActivate(route, state)) {
      return false;
    }

    if (this.tempRouterUrlState !== '/') {
      let allowPaths = await firstValueFrom(this._pageService.allowPaths$);

      allowPaths = allowPaths.map((path) =>
        path.split('/').slice(0, 2).join('/'),
      );

      let check = !!allowPaths.find(
        (path) => this.tempRouterUrlState.indexOf(path) == 0,
      );

      if (!check) {
        this._basicDialog.error($localize`沒有權限使用此功能`);
        this.tempRouterUrlState = '';
        this._router.navigate(['']);
        return false;
      }
    }
    return true;
  }
}
