<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container">
    <p>{{ title }}</p>
    <mat-form-field legacyAppearance>
      <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
      <input matInput formControlName="value" />
      <mat-error class="fw text-xs">
        <ng-container *ngFor="let error of extraValidatorError">
          <ng-container
            *ngIf="valueInputControl.errors && valueInputControl.errors[error.name]"
          >
            {{error.text}}
          </ng-container>
        </ng-container>
      </mat-error>
    </mat-form-field>
  </div>
  <button
    class="!rounded-none"
    mat-raised-button
    type="submit"
    color="primary"
    [disabled]="form.invalid"
  >
    {{ confirm }}
  </button>
</form>
