import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'gosu-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class LogoComponent {
  @Input()
  public theme: 'light' | 'dark' | 'primary' | 'yellow' = 'light';

  @Input()
  public size = 240;
}
