import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { QuickReply } from '@ay/bot';
import { createUniqueName } from '@ay/util';
import { delay } from 'bluebird';
import { EmptyResponseError } from '../../../util/empty-response-error';
import { MatContextMenuDirective } from '../../material/context-menu';
import { PackageAffectedComponent } from '../package-affected.component';
import { QuickReplyDialog } from './quick-reply-dialog/quick-reply.dialog';

@Component({
  selector: 'ms-quick-replies',
  templateUrl: './quick-replies.component.html',
  styleUrls: ['./quick-replies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package'],
  standalone: true,
  imports: [
    NgIf,
    CdkDropList,
    NgFor,
    CdkDrag,
    MatContextMenuDirective,
    MatIcon,
    MatMenu,
    MatMenuItem,
  ],
})
export class QuickRepliesComponent extends PackageAffectedComponent {
  @Input()
  public mode: 'EDIT' | 'READ' = 'EDIT';

  public selected: QuickReply.Any;

  @ViewChildren('quickReplies')
  public quickReplies: QueryList<ElementRef>;

  public get index() {
    if (!this.package) return -1;
    return this.package.quickReplies.indexOf(this.selected);
  }

  public get isFirstQuickReply() {
    return this.index === 0;
  }

  public get isLastQuickReply() {
    if (!this.package) return false;
    return this.index == this.package.quickReplies.length - 1;
  }

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly _matConnectedDialog: MatConnectedDialog,
  ) {
    super(changeDetectorRef);
  }

  public add() {
    let labels = this.package.quickReplies.map((q) => q.label);
    let label = createUniqueName($localize`未命名按鈕`, labels);
    this.package.quickReplies.push(new QuickReply.Message(label, label));
    this.markForCheck();
  }

  public edit(quickReply: QuickReply) {
    this._matConnectedDialog.open(QuickReplyDialog, {
      data: {
        quickReply,
        package: this.package,
      },
    });
  }

  public async delete(quickReply: QuickReply.Any) {
    try {
      let index = this.package.quickReplies.indexOf(quickReply);
      this.package.quickReplies.splice(index, 1);
      this.markForCheck();
    } catch (error) {
      if (error instanceof EmptyResponseError) return;
      throw error;
    }
  }

  public dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.package.quickReplies,
      event.previousIndex,
      event.currentIndex,
    );
    this.package.changed();
  }

  public async leftSwap(quickReply: QuickReply.Any) {
    await delay(1); //避免畫面重繪
    let index = this.package.quickReplies.indexOf(quickReply);
    this.swap(index, index - 1);
  }

  public rightSwap(quickReply: QuickReply.Any) {
    let index = this.package.quickReplies.indexOf(quickReply);
    this.swap(index, index + 1);
  }

  public async swap(origin: number, destination: number) {
    let quickReplies = this.package.quickReplies;
    let tmp = quickReplies[destination];
    quickReplies[destination] = quickReplies[origin];
    quickReplies[origin] = tmp;
    this.markForCheck();
  }
}
