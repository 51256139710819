import { Content } from '@ay/bot';
import { firstValueFrom } from 'rxjs';
import {
  CouponColumnWithStatus,
  CouponService,
} from '../../service/coupon.service';
import { Message } from '../base/base.message';

export class CouponMessage extends Message<Content.Coupon> {
  public type = 'coupon';

  public coupon: CouponColumnWithStatus;

  public couponService: CouponService;

  public constructor() {
    super();
    this.content = new Content.Coupon(0);
  }

  public async loadFromContent(record: Content.Coupon) {
    this.coupon = await firstValueFrom(
      this.couponService.getById(record.couponId),
    );

    this.content = record;
    this.coupon.no = record['no'];
  }

  public toString() {
    return $localize`一張優惠券: ${this.coupon.title}`;
  }
}
