<mat-menu #matMenu [overlapTrigger]="false" class="tiny-menu">
  <ng-container *ngFor="let toolbar of toolbars">
    <ng-container [ngSwitch]="toolbar.mode">
      <ng-template ngSwitchCase="CLICK">
        <button
          mat-menu-item
          element-ref
          #elementRef="elementRef"
          (click)="package.insert(toolbar.onClick(package, elementRef))"
        >
          @if (toolbar.icon) {
            <mat-icon>{{ toolbar.icon }}</mat-icon>
          }
          <span>{{ toolbar.label }}</span>
        </button>
      </ng-template>

      <ng-template ngSwitchCase="UPLOAD_FILE">
        <button
          mat-menu-item
          (file)="package.insert(toolbar.onUpload($event))"
          [accept]="toolbar.accept"
          [matTooltip]="toolbar.tooltip"
          matTooltipPosition="after"
        >
          @if (toolbar.icon) {
            <mat-icon>{{ toolbar.icon }}</mat-icon>
          }
          <span>{{ toolbar.label }}</span>
        </button>
      </ng-template>

      <ng-template ngSwitchCase="SUBMENU">
        <button
          mat-menu-item
          color="primary"
          [matMenuTriggerFor]="menu.matMenu"
          (mouseenter)="loadSubmenu(toolbar)"
        >
          @if (toolbar.icon) {
            <mat-icon>{{ toolbar.icon }}</mat-icon>
          }
          <span>{{ toolbar.label }}</span>
        </button>
        <ms-submenu
          #menu
          [package]="package"
          [toolbars]="toolbar.submenu"
        ></ms-submenu>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-menu>
