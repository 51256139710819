<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container">
    <p i18n>重新命名 <span class="name">{{ name }}</span></p>

    <mat-form-field class="fw" legacyAppearance>
      <mat-label i18n>新名稱</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngFor="let error of extraValidatorError">
        <ng-container
          *ngIf="nameInputControl.errors && nameInputControl.errors[error.name]"
        >
          {{error.text}}
        </ng-container>
      </mat-error>
    </mat-form-field>
  </div>
  <button
    class="!rounded-none"
    mat-raised-button
    type="submit"
    color="primary"
    [disabled]="form.invalid"
    i18n
  >
    確定
  </button>
</form>
