import { AsyncNode } from '../async/async-node.class';

export class CustomerServiceNode extends AsyncNode {
  public icon = 'support_agent';

  public processed: boolean = true;
  public responseVar: string = 'customerService';
  public action: 'ENTER' | 'LEAVE' = 'ENTER';
  public waitingTooLongSeconds = '60';
  public customerIdleSeconds = '240,300';

  public readonly extraProperties = [
    'isAsync',
    'action',
    'processed',
    'responseVar',
    'waitingTooLongSeconds',
    'customerIdleSeconds',
  ];
}
