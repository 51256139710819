<gosu-page-loading id="common"></gosu-page-loading>

<!-- 連線中 -->
@if (isConnecting$ | async) {
  <div class="top-0 left-0 w-dvw h-dvh z-2 absolute bg-black flex flex-col">
    <div class="flex-1"></div>
    <div class="text-xs text-center w-full" i18n>
      <mat-spinner class="mb-10 m-auto"></mat-spinner>
      正在嘗試連線到伺服器
    </div>
    <div class="flex-1"></div>
  </div>

  <!-- 登入中 -->
} @else if (showLogging()) {
  <div class="top-0 left-0 w-dvw h-dvh z-2 absolute bg-black flex flex-col">
    <div class="flex-1"></div>
    <div class="text-xs text-center w-full" i18n>
      <mat-spinner class="mb-10 m-auto"></mat-spinner>
      正在登入系統
    </div>
    <div class="flex-1"></div>
  </div>

  <!-- 已登入 -->
} @else {
  <mat-drawer-container
    class="min-w-[1023px] h-full"
    [class.!min-w-full]="isMobile$ | async"
  >
    <mat-drawer class="bg-gray-33" mode="over" #drawer>
      <nav class="flex flex-col flex-nowrap !items-center h-full">
        <div class="h-18 flex flex-row !items-center !justify-center">
          <button class="menu" mat-icon-button (click)="drawer.toggle()">
            <gosu-icon class="m-0 -mt-1" [size]="24"></gosu-icon>
          </button>
          <gosu-logo class="m-1 mt-2" [size]="164" theme="dark"></gosu-logo>
          <div class="flex-1">
            <button mat-icon-button (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
            </button>
          </div>
        </div>

        <mat-divider></mat-divider>

        @if (pageService.list$ | async; as pages) {
          <div class="overflow-auto flex-1 w-full">
            @for (item of pages; track item.id) {
              @switch (item.type) {
                @case ('Page') {
                  <ng-template
                    [ngTemplateOutlet]="pageItem"
                    [ngTemplateOutletContext]="{ $implicit: item }"
                  >
                  </ng-template>
                }

                @case ('Redirect') {
                  <ng-template
                    [ngTemplateOutlet]="redirectItem"
                    [ngTemplateOutletContext]="{ $implicit: item }"
                  >
                  </ng-template>
                }

                @case ('Tab') {
                  <ng-template
                    [ngTemplateOutlet]="tabItem"
                    [ngTemplateOutletContext]="{ $implicit: item }"
                  >
                  </ng-template>
                }

                @case ('Group') {
                  <ng-template
                    [ngTemplateOutlet]="groupItem"
                    [ngTemplateOutletContext]="{ $implicit: item }"
                  >
                  </ng-template>
                }
              }
            }
          </div>
        }

        <mat-divider></mat-divider>

        <div class="footer">
          <div
            class="h-6 text-xs text-body-color text-center leading-6"
            [innerHTML]="copyright | trustHtml"
          ></div>
          <div class="h-6 text-xs text-body-color text-center leading-6" i18n>
            版本 : {{ backstageVersion }}
          </div>
        </div>
      </nav>
    </mat-drawer>

    <mat-drawer-content class="h-full">
      @if (enableToolbar$ | async) {
        @if ((isMobile$ | async) === false) {
          <mat-toolbar color="primary flex flex-row sticky top-0 z-[1000]">
            <gosu-toolbar class="flex-1" (iconClick)="drawer.toggle()">
            </gosu-toolbar>
          </mat-toolbar>
        } @else {
          <mat-toolbar
            color="dark flex !flex-row justify-center p-0 z-[1000] !h-16"
          >
            @if (enableGoBackButton$ | async) {
              <button mat-icon-button (click)="back()" class="!absolute left-3">
                <mat-icon>arrow_back_ios</mat-icon>
              </button>
            } @else {
              <button
                mat-icon-button
                (click)="drawer.toggle()"
                class="!absolute left-3"
              >
                <mat-icon>menu</mat-icon>
              </button>
            }
            <gosu-logo theme="yellow" [size]="164"></gosu-logo>
          </mat-toolbar>
        }
      }

      <div class="relative" [style.height]="routerOutletContainerHeight()">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
}

<!-- Page -->
<ng-template #pageItem let-page>
  @if (page.path) {
    <button
      mat-button
      class="inherit item !flex flex-row w-full !h-12 !leading-12 !text-left"
      routerLinkActive="active"
      [routerLink]="'/' + page.path.replace('/app', '')"
      (click)="drawer.close()"
    >
      <icon class="!text-xl mx-2" [icon]="page.icon"></icon>
      <p class="flex-1">{{ page.label | translate }}</p>
    </button>
  }
</ng-template>

<!-- Tab -->
<ng-template #tabItem let-tab>
  <a
    mat-button
    class="inherit item !flex flex-row w-full !h-12 !leading-12 !text-left"
    [href]="tab.path"
    target="_blank"
    (click)="drawer.close()"
  >
    <icon class="!text-xl mx-2" [icon]="tab.icon"></icon>
    <p class="flex-1">{{ tab.label | translate }}</p>
  </a>
</ng-template>

<!-- Redirect -->
<ng-template #redirectItem let-redirect>
  <a
    mat-button
    class="inherit item !flex flex-row w-full !h-12 !leading-12 !text-left"
    [href]="redirect.path"
    (click)="drawer.close()"
  >
    <icon class="!text-xl mx-2" [icon]="redirect.icon"></icon>
    <p class="flex-1">{{ redirect.label | translate }}</p>
  </a>
</ng-template>

<!-- Group -->
<ng-template #groupItem let-group>
  <div class="item group">
    <mat-divider></mat-divider>

    <button
      mat-button
      class="inherit item !flex flex-row w-full !h-12 !leading-12 !text-left"
      (click)="group.open = !group.open"
    >
      <icon class="!text-xl mx-2" [icon]="group.icon"></icon>
      <p class="flex-1">{{ group.label | translate }}</p>
      <icon
        class="!text-xl mx-2"
        [icon]="group.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
      >
      </icon>
    </button>

    <ul [@expansion]="group.open" class="overflow-hidden">
      @for (item of group.children; track item.id) {
        <div class="wrap">
          @switch (item.type) {
            @case ('Page') {
              <ng-template
                [ngTemplateOutlet]="pageItem"
                [ngTemplateOutletContext]="{ $implicit: item }"
              >
              </ng-template>
            }

            @case ('Redirect') {
              <ng-template
                [ngTemplateOutlet]="redirectItem"
                [ngTemplateOutletContext]="{ $implicit: item }"
              >
              </ng-template>
            }

            @case ('Tab') {
              <ng-template
                [ngTemplateOutlet]="tabItem"
                [ngTemplateOutletContext]="{ $implicit: item }"
              >
              </ng-template>
            }
          }
        </div>
      }
    </ul>

    <mat-divider></mat-divider>
  </div>
</ng-template>

<div *asyncJobDefaultLoading class="text-center text-sm px-auto py-12">
  資料讀取中...
</div>

<div *asyncJobDefaultEmpty class="text-center text-sm px-auto py-12">
  資料是空的...
</div>

<div *asyncJobDefaultError class="text-center text-sm px-auto py-12">
  資料讀取失敗...
</div>
