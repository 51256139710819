import { Component, Input } from '@angular/core';
import {
  MatProgressSpinner,
  ProgressSpinnerMode,
} from '@angular/material/progress-spinner';

@Component({
  selector: 'dl-loading-dialog',
  templateUrl: './loading.dialog.html',
  styleUrls: ['./loading.dialog.scss'],
  standalone: true,
  imports: [MatProgressSpinner],
})
export class LoadingDialog {
  @Input()
  public title: string;

  @Input()
  public text: string;

  @Input()
  public mode: ProgressSpinnerMode = 'indeterminate';

  @Input()
  public value = 0;

  @Input()
  public color = 'primary';
}
