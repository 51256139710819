import { APP_BASE_HREF, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { Color, DMU } from '@ay/util';
import { delay } from 'bluebird';
import range from 'lodash/range';
import urljoin from 'url-join';
import { LegacyAppearanceDirective } from '../../legacy/mat-form-field/legacy-appearance.directive';
import { ColorPickerSliderComponent } from '../color-picker-slider/color-picker-slider.component';

export type Mode =
  | 'red'
  | 'green'
  | 'blue'
  | 'hue'
  | 'saturation'
  | 'brightness';

export type ColorResponse = {
  red: number;
  green: number;
  blue: number;
  hue: number;
  saturation: number;
  brightness: number;
  hsla: number;
  rgba: number;
  hex: string;
  alpha: number;
};

@Component({
  selector: 'mat-color-picker',
  templateUrl: './color-picker-dialog.component.html',
  styleUrls: ['./color-picker-dialog.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ColorPickerSliderComponent,
    NgIf,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSuffix,
    NgFor,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class ColorPickerDialog implements AfterViewInit {
  // #region red
  private _red: number = 0;
  public get red() {
    return this._red;
  }
  public set red(r: number) {
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    if (this._red === r) return;
    this._red = r;
    this._updateRgb();
  }
  // #endregion

  // #region green
  private _green: number = 0;
  public get green() {
    return this._green;
  }
  public set green(g: number) {
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    if (this._green === g) return;
    this._green = g;
    this._updateRgb();
  }
  // #endregion

  // #region blue
  private _blue: number = 0;
  public get blue() {
    return this._blue;
  }
  public set blue(b: number) {
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    if (this._blue === b) return;
    this._blue = b;
    this._updateRgb();
  }
  // #endregion

  // #region hue
  private _hue: number = 0;
  public get hue() {
    return this._hue;
  }
  public set hue(h: number) {
    if (h > 360) h = 360;
    else if (h < 0) h = 0;
    if (this._hue === h) return;
    this._hue = h;
    this._updateHSL();
  }
  // #endregion

  // #region saturation
  private _saturation: number = 0;
  public get saturation() {
    return this._saturation;
  }
  public set saturation(s: number) {
    if (s > 100) s = 100;
    else if (s < 0) s = 0;
    if (this._saturation === s) return;
    this._saturation = s;
    this._updateHSL();
  }
  // #endregion

  // #region brightness
  private _brightness: number = 0;
  public get brightness() {
    return this._brightness;
  }
  public set brightness(b2: number) {
    if (b2 > 100) b2 = 100;
    else if (b2 < 0) b2 = 0;
    if (this._brightness === b2) return;
    this._brightness = b2;
    this._updateHSL();
  }
  // #endregion

  // #region hex
  private _hex: string = '#000000';
  public hexChange = new EventEmitter();
  public get hex() {
    return this._hex;
  }
  public set hex(hex: string) {
    if (!hex.startsWith('#')) hex = '#' + hex;
    if (this._hex === hex) return;

    this._hex = hex;
    this._updateHEX();
  }
  // #endregion

  //#region mode
  private _mode: Mode = 'red';

  public get mode(): Mode {
    return this._mode;
  }

  @Input()
  public set mode(mode: Mode) {
    if (this._mode === mode) return;
    this._mode = mode;
    this._updateMode();
  }
  //#endregion

  // #region alpha
  /** 透明度，0 ~ 100% */
  private _alphaPercentage = 100;

  public get alphaPercentage() {
    return this._alphaPercentage;
  }
  public set alphaPercentage(alphaPercentage: number) {
    this._alphaPercentage = alphaPercentage;
    this._updateRgb();
  }

  // #endregion

  public pickerBackground = '';
  public sliderBackground = '';
  public thumbBorderColor = '#FFFFFF';
  public transparentBackgroundImage = urljoin(
    this._baseHref,
    '/assets/transparent.png',
  );
  public alphaBackground = ` -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%), url(${this.transparentBackgroundImage})`;
  public depth = [];
  public palette = [
    ...['#FFFFFF', '#000000'],
    ...['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'],
    ...['#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50'],
    ...['#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800'],
    ...['#FF5722', '#795548', '#9E9E9E', '#607D8B'],
  ];
  public history = [];
  public oriColor = '';

  public x = 0;
  public y = 0;
  public enableAlpha: boolean = false;

  @ViewChild('picker', { static: true })
  public picker: ElementRef;

  @ViewChild('thumb', { static: true })
  public thumb: ElementRef;

  @ViewChild('slider', { static: true })
  public slider: ColorPickerSliderComponent;

  public constructor(
    public domSanitizer: DomSanitizer,
    public elementRef: ElementRef,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      enableAlpha: boolean;
      alpha: number /** 透明度，0 ~ 1 */;
      hex: string;
    },
    @Inject(APP_BASE_HREF)
    private _baseHref: string,
  ) {
    if (!data) return;
    this.enableAlpha = data.enableAlpha;
    this._alphaPercentage = data.enableAlpha ? data.alpha * 100 : 100;
    this.oriColor = data.hex;
    this.hex = data.hex;
    console.log(data.hex, data.alpha);
  }

  public async ngAfterViewInit() {
    await delay(100);
    try {
      this.history =
        JSON.parse(window.localStorage.getItem('gosu-history-color')) || [];
    } catch (err) {
      console.error($localize`從 LocalStorage 讀取歷史紀錄時，發生錯誤`, err);
    }
    this._updateMode();
    this._updateView();
    DMU(
      this.picker.nativeElement,
      () => true,
      (down) => this._afterThumbMove(down),
      (down, move) => this._afterThumbMove(move),
      (down, up) => this._afterThumbMove(up),
    ).subscribe();
  }

  private _afterThumbMove(event: MouseEvent) {
    let rect = (
      this.picker.nativeElement as HTMLElement
    ).getBoundingClientRect();
    let x = (event.pageX - rect.left) / rect.width;
    let y = 1 - (event.pageY - rect.top) / rect.height;

    switch (this.mode) {
      case 'red':
        this.blue = Math.round(x * 255);
        this.green = Math.round(y * 255);
        break;

      case 'green':
        this.red = Math.round(y * 255);
        this.blue = Math.round(x * 255);
        break;

      case 'blue':
        this.green = Math.round(y * 255);
        this.red = Math.round(x * 255);
        break;

      case 'hue':
        this.saturation = Math.round(x * 100);
        this.brightness = Math.round(y * 100);
        break;

      case 'saturation':
        this.hue = Math.round(x * 360);
        this.brightness = Math.round(y * 100);
        break;

      case 'brightness':
        this.hue = Math.round(x * 360);
        this.saturation = Math.round(y * 100);
        break;
    }
  }

  private _updateRgb() {
    const rgba = {
      red: this.red,
      green: this.green,
      blue: this.blue,
      alpha: this.enableAlpha ? this._alphaPercentage / 100 : null,
    };

    if (rgba.alpha != null) {
      this._alphaPercentage = rgba.alpha * 100;
    } else {
      this._alphaPercentage = 100;
    }

    const hsb = Color.rgbToHsb(rgba);
    if (Color.isLight(rgba, 200)) {
      this.thumbBorderColor = '#242424';
    } else {
      this.thumbBorderColor = '#FFFFFF';
    }
    this._hue = hsb.hue;
    this._saturation = hsb.saturation;
    this._brightness = hsb.brightness;
    this._updateView();
  }

  private _updateHEX() {
    let rgba = Color.hexToRgba(this.hex);
    this._red = rgba.red;
    this._green = rgba.green;
    this._blue = rgba.blue;
    if (rgba.alpha != null) {
      this._alphaPercentage = rgba.alpha * 100;
    } else {
      this._alphaPercentage = 100;
    }
    let { hue, saturation, brightness } = Color.rgbToHsb(rgba);
    this._hue = Math.round(hue);
    this._saturation = Math.round(saturation);
    this._brightness = Math.round(brightness);
    this._updateView();
  }

  private _updateHSL() {
    let rgba = Color.hsbToRgba({
      hue: this.hue,
      saturation: this.saturation,
      brightness: this.brightness,
      alpha: this.enableAlpha ? this._alphaPercentage / 100 : null,
    });

    if (rgba.alpha != null) {
      this._alphaPercentage = rgba.alpha * 100;
    } else {
      this._alphaPercentage = 100;
    }

    this._red = rgba.red;
    this._green = rgba.green;
    this._blue = rgba.blue;
    this._hex = Color.rgbaToHex(rgba);

    this._updateView();
  }

  private _updateView() {
    this._updatePickerBackground();
    this._updateSliderBackground();
    this._updateThumbPosition();
    this._updateSliderValue();
    this._updateDepth();
  }

  private _updateThumbPosition() {
    switch (this.mode) {
      case 'red':
        this.x = (this.blue / 255) * 100;
        this.y = 100 - (this.green / 255) * 100;
        break;

      case 'green':
        this.x = (this.blue / 255) * 100;
        this.y = 100 - (this.red / 255) * 100;
        break;

      case 'blue':
        this.x = (this.red / 255) * 100;
        this.y = 100 - (this.green / 255) * 100;
        break;

      case 'hue':
        this.x = this.saturation;
        this.y = 100 - this.brightness;
        break;

      case 'saturation':
        this.x = (this.hue / 360) * 100;
        this.y = 100 - this.brightness;
        break;

      case 'brightness':
        this.x = (this.hue / 360) * 100;
        this.y = 100 - this.saturation;
        break;
    }
  }

  private _updatePickerBackground() {
    switch (this.mode) {
      case 'red': {
        let red = Math.round(this.red / 2);

        this.pickerBackground = `
          -webkit-linear-gradient(bottom,
            rgb(${red}, 0, 0) 0%,
            rgb(${red}, 255, 0) 100%
          ),
          -webkit-linear-gradient(left,
            rgb(${red}, 0, 0) 0%,
            rgb(${red}, 0, 255) 100%
          )`;
        break;
      }

      case 'green': {
        let green = Math.round(this.green / 2);
        this.pickerBackground = `
          -webkit-linear-gradient(bottom,
            rgb(0, ${green}, 0) 0%,
            rgb(255, ${green}, 0) 100%
          ),
          -webkit-linear-gradient(left,
            rgb(0, ${green}, 0) 0%,
            rgb(0, ${green}, 255) 100%
          )`;
        break;
      }

      case 'blue': {
        let blue = Math.round(this.blue / 2);
        this.pickerBackground = `
          -webkit-linear-gradient(bottom,
            rgb(0, 0, ${blue}) 0%,
            rgb(0, 255, ${blue}) 100%
          ),
          -webkit-linear-gradient(left,
            rgb(0, 0, ${blue}) 0%,
            rgb(255, 0, ${blue}) 100%
          )`;
        break;
      }

      case 'hue': {
        let hue = this.hue;
        this.pickerBackground = `
          -webkit-linear-gradient(top,
            ${this._HSLA(hue, 0, 0, 0)},
            ${this._HSLA(hue, 100, 0, 1)}
          ),
          -webkit-linear-gradient(left,
            ${this._HSLA(hue, 100, 0, 0)},
            ${this._HSLA(hue, 100, 100, 1)}
          )
        `.replace(/\s+/gi, ' ');
        break;
      }

      case 'saturation': {
        let saturation = this.saturation;
        this.pickerBackground = `
          -webkit-linear-gradient(top,
            ${this._HSLA(0, saturation, 100, 0)},
            ${this._HSLA(0, saturation, 0, 1)}
          ),
          -webkit-linear-gradient(left,
            ${range(0, 11, 1)
              .map((i) => this._HSLA(36 * i, saturation, 100, 1))
              .join(',')}
          )
        `.replace(/\s+/gi, ' ');
        break;
      }

      case 'brightness': {
        let brightness = this.brightness;
        this.pickerBackground = `
          -webkit-linear-gradient(left,
            ${range(0, 11, 1)
              .map((i) => this._HSLA(36 * i, 100, brightness, 1))
              .join(',')}
          ),
          -webkit-linear-gradient(top,
            ${this._HSLA(0, 100, brightness, 0)},
            ${this._HSLA(0, 0, brightness, 1)}
          )
        `.replace(/\s+/gi, ' ');
        break;
      }
    }
  }

  private _updateSliderBackground() {
    switch (this.mode) {
      case 'red': {
        let green = this.green;
        let blue = this.blue;
        this.sliderBackground = `
          -webkit-linear-gradient(left,
            rgb(0, ${green}, ${blue}) 0%,
            rgb(255, ${green}, ${blue}) 100%
          )
        `.replace(/\s+/gi, ' ');
        break;
      }

      case 'green': {
        let red = this.red;
        let blue = this.blue;
        this.sliderBackground = `
          -webkit-linear-gradient(left,
            rgb(${red}, 0, ${blue}) 0%,
            rgb(${red}, 255, ${blue}) 100%
          )
        `.replace(/\s+/gi, ' ');
        break;
      }

      case 'blue': {
        let red = this.red;
        let green = this.green;
        this.sliderBackground = `
          -webkit-linear-gradient(left,
            rgb(${red}, ${green}, 0) 0%,
            rgb(${red}, ${green}, 255) 100%
          )
        `.replace(/\s+/gi, ' ');
        break;
      }

      case 'hue': {
        let saturation = this.saturation;
        let brightness = this.brightness;
        this.sliderBackground = `
          -webkit-linear-gradient(left,
            ${range(0, 11, 1)
              .map((i) => this._HSLA(36 * i, saturation, brightness))
              .join(',')}
          )
        `.replace(/\s+/gi, ' ');
        break;
      }

      case 'saturation': {
        let hue = this.hue;
        let brightness = this.brightness;
        this.sliderBackground = `
          -webkit-linear-gradient(left,
            ${this._HSLA(hue, 0, brightness)},
            ${this._HSLA(hue, 100, brightness)}
          )
        `.replace(/\s+/gi, ' ');
        break;
      }

      case 'brightness': {
        let hue = this.hue;
        let saturation = this.saturation;
        this.sliderBackground = `
          -webkit-linear-gradient(left,
            ${this._HSLA(hue, saturation, 0)},
            ${this._HSLA(hue, saturation, 100)}
          )
        `.replace(/\s+/gi, ' ');
        break;
      }
    }

    let { red, green, blue } = this;
    this.alphaBackground = `-webkit-linear-gradient(left, rgba(${red}, ${green}, ${blue}, 0) 0%, rgba(${red}, ${green}, ${blue}, 1) 100%), url(${this.transparentBackgroundImage})`;
  }

  private _updateMode() {
    switch (this.mode) {
      case 'red':
      case 'green':
      case 'blue':
        this.slider.max = 255;
        break;

      case 'hue':
        this.slider.max = 360;
        break;

      case 'saturation':
      case 'brightness':
        this.slider.max = 100;
        break;
    }
    this._updateView();
  }

  private _updateSliderValue() {
    if (!this.slider) return;

    switch (this.mode) {
      case 'red':
        this.slider.data = this.red;
        break;

      case 'green':
        this.slider.data = this.green;
        break;

      case 'blue':
        this.slider.data = this.blue;
        break;

      case 'hue':
        this.slider.data = this.hue;
        break;

      case 'saturation':
        this.slider.data = this.saturation;
        break;

      case 'brightness':
        this.slider.data = this.brightness;
        break;
    }
  }

  public afterSliderValueChange() {
    switch (this.mode) {
      case 'red':
        this.red = this.slider.data;
        break;

      case 'green':
        this.green = this.slider.data;
        break;

      case 'blue':
        this.blue = this.slider.data;
        break;

      case 'hue':
        this.hue = this.slider.data;
        break;

      case 'saturation':
        this.saturation = this.slider.data;
        break;

      case 'brightness':
        this.brightness = this.slider.data;
        break;
    }
  }

  public _updateDepth() {
    let numberOfRows = 20;
    let step = 100 / Math.ceil(numberOfRows / 2);
    var start_val = step * Math.floor(numberOfRows / 2);

    this.depth = [];
    const rgba = {
      red: this.red,
      green: this.green,
      blue: this.blue,
      alpha: this.enableAlpha ? this._alphaPercentage / 100 : null,
    };
    for (let i = -6; i <= 6; i += 2) {
      let level = i + 10;
      var E = Color.setBrightness(rgba, start_val - level * step);
      this.depth.push(Color.rgbaToHex(E));
    }
  }

  private _HSLA(
    hue: number,
    saturation: number,
    brightness: number,
    alpha: number = 1,
  ) {
    const hsb = { hue, saturation, brightness, alpha };
    let { lightness } = Color.hsbToHsl(hsb);
    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
  }

  public submit() {
    if (!this.history.includes(this.hex)) {
      this.history.unshift(this.hex);
      this.history = this.history.slice(0, 32);
      window.localStorage.setItem(
        'gosu-history-color',
        JSON.stringify(this.history),
      );
    }
    const alpha = this._alphaPercentage / 100;
    this.dialogRef.close({
      red: this.red,
      green: this.green,
      blue: this.blue,
      hue: this.hue,
      saturation: this.saturation,
      brightness: this.brightness,
      hsla: this._HSLA(this.hue, this.saturation, this.brightness, alpha),
      rgba: `rgba(${this.red}, ${this.green}, ${this.blue}, ${alpha})`,
      hex: this.hex,
      alpha: alpha,
    });
  }

  public cancel() {
    this.dialogRef.close();
  }
}
