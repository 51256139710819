import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { ExtraValidatorError } from '../basic-dialog.service';

@Component({
  selector: 'dl-prompt-dialog',
  templateUrl: './prompt.dialog.html',
  styleUrls: ['./prompt.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgFor,
    MatError,
    NgIf,
    MatButton,
    LegacyAppearanceDirective,
    MatLabel,
  ],
})
export class PromptDialog implements OnDestroy, OnInit {
  @Input()
  public confirm: string = $localize`確認`;

  @Input()
  public title: string = $localize`請輸入`;

  @Input()
  public placeholder: string = '';

  @Input()
  public required: string = $localize`必填項目`;

  // 預設false
  @Input()
  public hasExtraValidator: boolean = false;

  @Input()
  public extraValidator: ValidatorFn[] = [];

  @Input()
  public extraValidatorError: ExtraValidatorError[] = [];

  //#region value
  private _value: string = '';

  public value$ = new Subject<string>();

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    if (this._value === value) return;
    this._value = value;
    this.value$.next(this._value);
  }
  //#endregion value

  public valueInputControl = new FormControl(this.value, Validators.required);

  public form = new FormGroup({ value: this.valueInputControl });

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _matDialogRef: MatDialogRef<PromptDialog>,
  ) {}

  public ngOnInit(): void {
    if (this.hasExtraValidator) {
      this.valueInputControl.addValidators(this.extraValidator);
    }

    this.extraValidatorError.push({ name: 'required', text: this.required });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _updateInputControlWhenOriNameChanged = this.value$
    .pipe(takeUntil(this._destroy$))
    .subscribe((value) => this.valueInputControl.setValue(value));

  public onSubmit() {
    this._matDialogRef.close(this.valueInputControl.value);
  }
}
