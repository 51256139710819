// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { NodeColumn } from './dto/node.dto';

export class NodeModel {
  static getGenerateFormList(): Promise<NodeColumn[]> {
    return wsc.execute('/ws/node/getGenerateFormList') as any;
  }

  static isExistWebhookEventNode(
    type: string,
    path: string,
    flowId: number,
    botIds: number[],
  ): Promise<
    {
      id: number;
      flowId: number;
    }[]
  > {
    return wsc.execute(
      '/ws/node/isExistWebhookEventNode',
      type,
      path,
      flowId,
      botIds,
    ) as any;
  }
}
// 1518c6b7e0ebd5b67d80ec916662ca632954cfe0041588308b701ae01bddf32d
