import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
  pure: true,
  standalone: true,
})

// 如果輸入是字串陣列，則直接返回
// 如果輸入是字串，則將字串以 keyword 分割成陣列，並過濾掉空字串
// 如果輸入是其他類型，則先將該值轉換為字串後，在依據上述規則處理
export class SplitPipe implements PipeTransform {
  public transform(input: string | string[], keyword = ','): string[] {
    if (Array.isArray(input)) return input;
    return input
      .toString()
      .split(keyword)
      .filter((s) => s.trim() !== '');
  }
}
