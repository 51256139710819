<div class="colors">
  @for (color of colors; track color) {
    <button
      class="color"
      mat-icon-button
      [style.background-color]="color"
      (click)="setColor(color)"
    ></button>
  }
</div>

<button
  mat-button
  elementRef
  #elementRef="elementRef"
  (click)="openAdvancedPalette(elementRef)"
>
  <div>
    <mat-icon>color_lens</mat-icon>
    <span i18n>自訂顏色</span>
  </div>
</button>
