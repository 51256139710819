import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { APP_BASE_HREF, AsyncPipe, NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { DomSanitizer } from '@angular/platform-browser';
import { MatConnectedDialog } from '@ay-gosu/ui/common/connected-dialog';
import { Color, Rgb } from '@ay/util';
import { Subject } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';
import urlJoin from 'url-join';
import { ColorPickerDialog } from '../color-picker-dialog/color-picker-dialog.component';

@Component({
  selector: 'gosu-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: ColorPickerComponent },
  ],
  standalone: true,
  imports: [MatButton, AsyncPipe, NgClass],
})
export class ColorPickerComponent
  implements
    MatFormFieldControl<string>,
    ControlValueAccessor,
    AfterViewInit,
    OnDestroy
{
  //#region @Two-way() rgba
  private _rgba: string = '';

  @Input()
  public format: 'rgba' | 'hex' = 'rgba';

  @Output()
  public rgbaChange = new EventEmitter<string>();

  public get rgba(): string {
    return this._rgba;
  }

  @Input()
  public set rgba(rgba: string) {
    if (this._rgba === rgba) return;
    this._rgba = rgba;
    this.rgbaChange.emit(this._rgba);
  }
  //#endregion @Two-way() rgba

  //#region @Two-way() value
  private _value: string = '';

  @Output()
  public valueChange = new EventEmitter<string>();

  public get value(): string {
    return this._value;
  }

  @Input()
  public set value(value: string) {
    if (this._value === value) return;
    this._value = value;
    this.valueChange.emit(this._value);
    this.stateChanges.next();
  }
  //#endregion @Two-way() value

  public stateChanges = new Subject<void>();

  public static nextId = 0;

  @HostBinding()
  public id = `gosu-color-picker-${ColorPickerComponent.nextId++}`;

  //#region @Two-way() placeholder
  private _placeholder: string;

  public get placeholder() {
    return this._placeholder;
  }

  @Input()
  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  //#endregion @Two-way() placeholder

  public focused: boolean = false;

  public get empty() {
    return !this.value;
  }

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  //#region @Two-way() placeholder
  @Input()
  private _required = false;

  public get required() {
    return this._required;
  }

  public set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  //#endregion @Two-way() placeholder

  //#region @Two-way() disabled
  @Input()
  private _disabled = false;

  public get disabled() {
    return this._disabled;
  }

  public set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  //#endregion @Two-way() disabled

  public errorState = false;

  public controlType = 'gosu-color-picker';

  public autofilled = false;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent): void {}

  @Input()
  public enableAlpha: boolean = false;

  //#region @Two-way() alpha
  private _alpha: number = 100;

  @Output()
  public alphaChange = new EventEmitter<number>();

  public get alpha(): number {
    return this._alpha;
  }

  @Input()
  /** 透明度，0 ~ 1 */
  public set alpha(alpha: number) {
    if (this._alpha === alpha) return;
    this._alpha = alpha;
    this.alphaChange.emit(this._alpha);
  }
  //#endregion @Two-way() alpha

  public backgroundImage$ = this.stateChanges.pipe(
    delay(1),
    map((e) => {
      const url = urlJoin(this._baseHref, '/assets/transparent.png');
      return this.sanitizer.bypassSecurityTrustStyle(
        `-webkit-linear-gradient(left, ${this.value}, ${this.value}),
        url('${url}')`,
      );
    }),
  );

  public hex: string = '';

  public isLight: boolean = false;

  public constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    @Optional()
    @Inject(MAT_FORM_FIELD)
    public matFormField: MatFormField,
    private fm: FocusMonitor,
    public dialog: MatConnectedDialog,
    public elementRef: ElementRef,
    public sanitizer: DomSanitizer,
    @Inject(APP_BASE_HREF)
    private readonly _baseHref: string,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elementRef.nativeElement, true)
      .pipe(takeUntil(this._destroy$))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });

    this.valueChange.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      if (!value) return;

      this.hex = Color.toHex(value);
      const rgba = Color.hexToRgba(this.hex);
      this.isLight = Color.isLight(rgba);
    });
  }

  private readonly _destroy$ = new Subject<void>();

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.valueChange.pipe(takeUntil(this._destroy$)).subscribe((e) => fn(e));
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  public ngAfterViewInit(): void {
    this.stateChanges.next();
  }

  public open() {
    let value = this.value || '';
    let alpha = this.alpha;

    const dialogRef = this.dialog.open(ColorPickerDialog, {
      elementRef: this.elementRef,
      panelClass: 'dialog-container-p12',
      data: {
        enableAlpha: this.enableAlpha,
        alpha,
        hex: this.hex,
      },
    });

    if (this.value) {
      if (this.format == 'rgba' && this.enableAlpha) {
        let rgba: Rgb;

        if (value == 'transparent') {
          rgba = { red: 0, green: 0, blue: 0, alpha: 0 };
        } else if (value.includes('#')) {
          rgba = Color.hexToRgba(value);
        } else {
          rgba = Color.rgbaStringToRgba(value);
        }

        value = Color.rgbaToHex(rgba);
        if (rgba.alpha != null) alpha = rgba.alpha;
      } else {
        value = this.value.toUpperCase().substring(0, 8);
        const alphaCode = this.value.toUpperCase().substring(7, 9);
        alpha = Number(alphaCode) / 255;
      }
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.alpha = result.alpha;
      if (this.format == 'rgba' && this.enableAlpha) {
        this.value = result.rgba;
      } else if (this.enableAlpha) {
        this.value = result.hex;
      } else {
        this.value = result.hex;
      }
      this.stateChanges.next();
    });
  }

  public ngOnDestroy() {
    this.stateChanges.next();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elementRef.nativeElement);
    this._destroy$.next();
    this._destroy$.complete();
  }
}
