import { Component } from '@angular/core';
import { faFacebook, faLine } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { ActivityRecordService } from './activity-record.service';
import { ProfilePipe } from '../../pipe/profile.pipe';
import { NodePipe } from '../../pipe/node.pipe';
import { FromNowPipe } from '../../pipe/from-now.pipe';
import { FlowPipe } from '../../pipe/flow.pipe';
import { AccountPipe } from '../../pipe/account.pipe';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '../../material/tooltip/tooltip';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatList, MatListItem } from '@angular/material/list';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'gosu-activity-record',
    templateUrl: './activity-record.component.html',
    styleUrls: ['./activity-record.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatList,
        NgFor,
        MatListItem,
        FlexModule,
        MatIcon,
        NgSwitch,
        NgSwitchCase,
        FaIconComponent,
        RouterLink,
        MatTooltip,
        MatButton,
        AsyncPipe,
        DatePipe,
        AccountPipe,
        FlowPipe,
        FromNowPipe,
        NodePipe,
        ProfilePipe,
    ],
})
export class ActivityRecordComponent {
  public faFacebook = faFacebook;
  public faLine = faLine;
  public faStar = faStar;

  public levels = {
    debug: $localize`除錯`,
    info: $localize`訊息`,
    warning: $localize`警告`,
    error: $localize`錯誤`,
  };

  public colors = {
    debug: '#00796B',
    info: '#0288D1',
    warning: '#F57C00',
    error: '#E64A19',
  };

  public constructor(public service: ActivityRecordService) {}
}
