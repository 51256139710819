// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { KeywordColumn } from './dto/keyword.dto';

export class KeywordModel {
  static getKeywordsById(id: number): Promise<KeywordColumn> {
    return wsc.execute('/ws/keyword/getKeywordsById', id) as any;
  }

  static getKeywordsByMainTagId(mainTagId: number): Promise<KeywordColumn[]> {
    return wsc.execute('/ws/keyword/getKeywordsByMainTagId', mainTagId) as any;
  }

  static getKeywordList(): Promise<KeywordColumn[]> {
    return wsc.execute('/ws/keyword/getKeywordList') as any;
  }

  static update(
    id: number,
    option: {
      keyword?: string;
      mainTagId?: number;
      synonyms?: string;
    },
  ): Promise<boolean> {
    return wsc.execute('/ws/keyword/update', id, option) as any;
  }

  static create(
    keyword: string,
    id: number,
    synonyms: any = '',
  ): Promise<number> {
    return wsc.execute('/ws/keyword/create', keyword, id, synonyms) as any;
  }

  static delete(id: number): Promise<boolean> {
    return wsc.execute('/ws/keyword/delete', id) as any;
  }
}
// 519de4035acfb622bdf7de9c7cb97da375d5f6ff9f8a7a07e80293de7724e827
