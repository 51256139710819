import { InjectionToken, Signal } from '@angular/core';
import {
  AccountDto,
  CompanyOverviewDto,
  GosuLoginConfigDto,
} from '@ay-gosu/server-shared';
import { ExposedPromise } from '@ay/util/exposed-promise';
import { Observable, ReplaySubject, Subject } from 'rxjs';

export enum LoginStatus {
  // 還沒有處理自動登入
  INIT,
  // 未登入(已經處理完自動登入)
  NOT_LOGGED_IN,
  // 已登入，但尚未選擇組織
  NOT_CHOSEN_COMPANY,
  // 已登入，且已選擇組織
  LOGGED,
}

export const INIT = LoginStatus.INIT;
export const NOT_LOGGED_IN = LoginStatus.NOT_LOGGED_IN;
export const NOT_CHOSEN_COMPANY = LoginStatus.NOT_CHOSEN_COMPANY;
export const LOGGED = LoginStatus.LOGGED;
export const LOGIN_SERVICE = new InjectionToken<IBackstageLoginService>(
  'LOGIN_SERVICE',
);

export const BASE_NAVIGATE_PATH = new InjectionToken<string>(
  'BASE_NAVIGATE_PATH',
);

export const ENABLE_THIRD_PARTY_LOGIN = new InjectionToken<string>(
  'ENABLE_THIRD_PARTY_LOGIN',
);

export const SERVICE_NAME = new InjectionToken<string>('SERVICE_NAME');

export interface IBackstageLoginService {
  // 儲存登入後的 JWT
  // 如果是 null 則尚未登入
  // 儘管有值時，有可能還沒選擇組織
  token$: ReplaySubject<string | null>;

  isLoading$: Subject<boolean>;

  // 如果需要沒有組織的 JWT，可以使用這個
  payload$: Observable<AccountDto | null>;

  // 登入後 JWT 被解開的內容
  payload: Signal<AccountDto | null>;

  // 登入後 JWT 被解開的內容
  // 儘管有值時，一定會是已經選擇組織的狀態
  account$: Observable<AccountDto | null>;

  // 帳號的 signal 版本，有可能是 null
  account: Signal<AccountDto | null>;

  // 登入的狀態
  status$: Observable<LoginStatus>;

  // 確認是否已經登入的 signal
  isLogged: Signal<boolean>;

  // 與 GOSU 登入相關的設定
  config$: Observable<GosuLoginConfigDto>;

  config: Signal<GosuLoginConfigDto>;

  companyList$: Observable<CompanyOverviewDto[]>;

  companyList: Signal<CompanyOverviewDto[]>;

  afterAutoLogin: ExposedPromise<void>;

  fetchConfig(): Promise<GosuLoginConfigDto>;

  login(email: string, password: string): Promise<void>;

  loginViaToken(loginToken: string): Promise<void>;

  verifyInviteToken(token: string): Promise<string>;

  fetchPasswordDefaultRules(): Promise<{ type: string; args: any }[]>;

  register(name: string, mail: string, password: string): Promise<void>;

  registerViaInvite(
    token: string,
    name: string,
    password: string,
  ): Promise<void>;

  sendVerifyMail(): Promise<void>;

  changePassword(oldPassword: string, password: string): Promise<void>;

  changePasswordByToken(token: string, password: string): Promise<void>;

  verifyResetPasswordToken(token: string, password: string): Promise<void>;

  afterLogin(): Promise<void>;

  selectCompany(companyId: number): Promise<void>;

  logout(): Promise<void>;

  refresh(): Promise<void>;

  _processAutoLogin(): Promise<void>;

  _storeTokenToLocalStorage(): void;

  sendVerifySms(
    countryCode: string,
    phone: string,
  ): Promise<{ result: boolean; remainingCount: number }>;

  verifySms(countryCode: string, phone: string, code: string): Promise<string>;

  updateName(name: string): Promise<void>;
}
