import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import unescape from 'lodash/unescape';
import { BaseComponent } from '../base/base.component';
import { FileMessage } from './file.message';

@Component({
  selector: 'ms-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['package', 'mode', 'message'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.margin-right.px]': "mode == 'EDIT' ? 24 : 0",
  },
  standalone: true,
  imports: [NgClass, ExtendedModule],
})
export class FileComponent extends BaseComponent<FileMessage> {
  @ViewChild('textarea')
  public textarea: ElementRef<HTMLDivElement>;

  public constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public mousedown($event: MouseEvent) {
    this.package.inTextEditMode = true;
  }

  public focus($event: Event) {
    this.changeDetectorRef.markForCheck();
  }

  public blur($event: Event) {
    let content = this.message.content;
    content.content = unescape(this.textarea.nativeElement.innerHTML);
    this.package.inTextEditMode = false;
    this.changeDetectorRef.markForCheck();
  }

  public stop($event: MouseEvent) {
    $event.stopPropagation();
  }
}
