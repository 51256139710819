export const ExecutorDTS = `
/** 跨節點存取的暫存變數 */
declare const tmp: {
  [key: string]: any;
};

/** 自定義事件請求 **/
declare const request: {
  method: string;
  protocol: string;
  hostname: string;
  get: { [key: string]: string };
  post: { [key: string]: string };
  headers: { [key: string]: string };
  cookies: { [key: string]: string };
  originalUrl: string;
};


/** 收到的訊息記錄 */
declare const record: {
  /** 文字訊息的內容 */
  content: string;
};

/** 使用者名稱(等同於from.profile.name) **/
declare const name: string;

/** 使用者大頭貼(等同於from.profile.picture) **/
declare const picture: string;

/** 屬性(等同於from.profile.propertyMap) */
declare const property: {
  [key: string]: string
};

/** 標籤(等同於from.profile.tagMap) */
declare const tag: {
  [id: number]: boolean
};

/** 發送對象(發送訊息節點內有效) */
declare const to: {
  profile: Profile;
};

/** 觸發對象的資訊 */
declare const from: {
  /** 使用者 / 聊天室 / 群組 */
  profile: Profile;
  /** 使用者 */
  user: Profile;
  /** 聊天室 */
  room: Profile;
  /** 群組 */
  group: Profile;
};

declare class Profile {
  /** 編號 */
  id: number;
  /** 名稱 */
  name: string;
  /** 大頭貼網址 */
  picture: string;
  /** 狀態消息 */
  message: string;
  /** 類型 */
  type: "User" | "Group" | "Room";
  /** 追蹤狀態 Follow / Unfollow */
  status: string;
  /** 平台編號 */
  platformId: string;
  /** 平台類型 */
  platformType: "Line" | "Facebook";
  /** 屬性列表 **/
  properties: { key: string; value: string }[];
  /** 屬性 **/
  propertyMap: { [key: string]: string };
  /** 標籤列表 **/
  tags: { id: number; name: string }[];
  /** 標籤 **/
  tagMap: { [id: string]: boolean };
}

interface HashIdConfig {
  /** hash 時使用的 salt */
  salt: string;
  /** hash 後的 id 前綴 */
  prefix?: string;
  /** hash 後的 id 後綴 */
  suffix?: string;
  /** hash 後的 id 最小長度 */
  minHashLength?: number;
  /** 允許的字符 */
  alphabet?: string;
}

/** SurveyCake 問卷的結果-原始資料（起始點為「填完 SurveyCake 問權」時有效） **/
declare const surveyCakeOriginal: ExecutorSurveyCakeForm;

/** SurveyCake 問卷的結果（起始點為「填完 SurveyCake 問權」時有效） **/
declare const surveyCake: {
  [question: string]: (string | number)[]
};

declare const RECOGNIZABLE_ALPHABET = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";

declare class HashId {
  private _config;
  private _hashId;
  constructor(_config: HashIdConfig);
  private readonly _prefix;
  private readonly _suffix;
  private _removeFixString;
  encode(id: number): string;
  decode(code: string): number;
}

declare type ExecutorSurveyCakeFieldType =
  | 'TXTSHORT'
  | 'TXTLONG'
  | 'CHOICEONE'
  | 'CHOICEMULTI'
  | 'NEST'
  | 'NESTCHILD'
  | 'DIGITINPUT'
  | 'DIGITSLIDE'
  | 'ITEMSORT'
  | 'RATINGBAR'
  | 'PICKFROM'
  | 'QUOTE'
  | 'STATEMENT'
  | 'DATEPICKER'
  | 'DIVIDER';

interface ExecutorSurveyCakeField {
  /** 題號 */
  sn: number;

  /** 題目 */
  subject: string;

  /** 類型 */
  type: ExecutorSurveyCakeFieldType;

  /** 答案 */
  answer: any[] | string;

  /** 其他答案 */
  otherAnswer: any[];
}

declare type ExecutorSurveyCakeForm = ExecutorSurveyCakeField[];
`;
