import { Component, Input } from '@angular/core';
import {
  MatProgressSpinner,
  ProgressSpinnerMode,
} from '@angular/material/progress-spinner';

@Component({
  selector: 'gosu-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [MatProgressSpinner],
})
export class LoadingComponent {
  @Input()
  public text: string = '';

  @Input()
  public mode: ProgressSpinnerMode = 'indeterminate';

  @Input()
  public value: number = 0;
}
