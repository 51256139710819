import { NgFor, NgIf } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { JunctionComponent } from '../../junction/junction.component';
import { ConditionComponent } from '../condition/condition.component';
import { host, NodeComponent } from '../node.component';
import { SwitchNode } from './class';

@Component({
  selector: ':svg:g.switch.node',
  templateUrl: './node.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['node'],
  providers: [
    {
      provide: NodeComponent,
      useExisting: forwardRef(() => SwitchNodeComponent),
    },
  ],
  standalone: true,
  imports: [NgIf, JunctionComponent, NgFor, ConditionComponent],
})
export class SwitchNodeComponent extends NodeComponent<SwitchNode> {
  @Input()
  public cases = [];
}
