import { CommonModule } from '@angular/common';
import {
  Component,
  Inject,
  Injector,
  Optional,
  booleanAttribute,
  effect,
  input,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Button, ButtonsComponent } from '../buttons';
import { convertToArray } from '../convert-to-array';

/** 使用方法和 gosu-success 一樣 */
@Component({
  selector: 'gosu-failure',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogClose,
    ButtonsComponent,
  ],
  templateUrl: './failure.component.html',
})
export class FailureDialog {
  public iconInput = input('priority_high', { alias: 'icon' });

  public icon = signal('priority_high');

  public iconOutlinedInput = input(false, {
    alias: 'iconOutlined',
    transform: booleanAttribute,
  });

  public iconOutlined = signal(false);

  public titleInput = input.required<string>({ alias: 'title' });

  public title = signal('');

  public messageInput = input<string[], string | string[]>([], {
    alias: 'message',
    transform: convertToArray,
  });

  public messages = signal<string[]>([]);

  public isDialog = signal(false);

  // 只有在 dialog 模式下顯示，否則顯示 ng-content
  public buttons = signal<Button[]>([]);

  public constructor(
    @Optional()
    public readonly dialogRef: MatDialogRef<any>,

    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: {
      icon: string;
      iconOutlined: boolean;
      title: string;
      messages: string[] | string;
      buttons: Button[];
    },
    private readonly _injector: Injector,
  ) {}

  public ngAfterContentInit() {
    if (this.dialogRef) {
      const data = this._data;
      this.isDialog.set(true);

      this.icon.set(data?.icon ?? 'priority_high');
      this.iconOutlined.set(data?.iconOutlined ?? false);
      this.title.set(data?.title);
      this.messages.set(data?.messages ? convertToArray(data.messages) : []);

      const defaultButton: Button = {
        type: 'flat',
        color: 'warn',
        text: '確定',
        click: () => this.dialogRef.close(),
      };

      this.buttons.set(data?.buttons ?? [defaultButton]);
    } else {
      const injector = this._injector;
      const option = {
        injector,
        allowSignalWrites: true,
      };
      effect(() => this.icon.set(this.iconInput()), option);
      effect(() => this.iconOutlined.set(this.iconOutlinedInput()), option);
      effect(() => this.title.set(this.titleInput()), option);
      effect(() => this.messages.set(this.messageInput()), option);
    }
  }
}
