import {
  NgFor,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

export type Button = {
  label: string;
  type?: string;
  color?: string;
  result?: string;
};

@Component({
  selector: 'dl-error-dialog',
  templateUrl: './error.dialog.html',
  styleUrls: ['./error.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatIcon,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    MatButton,
    NgSwitchDefault,
  ],
})
export class ErrorDialog {
  @Input() public content: string;

  @Input() public buttons: Button[] = [
    { label: 'OK', type: 'raised', color: 'primary', result: 'OK' },
  ];

  public constructor(private readonly _matDialogRef: MatDialogRef<any>) {}

  public close(result: string) {
    this._matDialogRef.close(result);
  }
}
