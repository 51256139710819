@if (!message) {
  <!-- empty message -->
} @else if (message.type === 'announcement') {
  <ms-factory-container #container> </ms-factory-container>
} @else {
  @if (message.userProfileId) {
    <gosu-avatar class="m-2" [id]="message.userProfileId"> </gosu-avatar>
  }

  <div
    class="flex-1 w-full"
    [ngClass]="{
      '!text-left': message.direction === 'Receive',
      '!text-right': message.direction === 'Send',
      'animate-pulse':
        message.status === logStatus.PROCESSING ||
        message.status === logStatus.SENDING
    }"
  >
    <ms-factory-container #container> </ms-factory-container>

    @if (message.createdAt || message.sender) {
      <div
        class="flex flex-row gap-1 !items-center text-xs leading-4 opacity-50 group-hover:opacity-100 transition-opacity duration-300 animate-pulse"
        [ngClass]="{
          '!justify-start': message.direction === 'Receive',
          '!justify-end': message.direction === 'Send',
          'text-red-600': message.status === logStatus.FAILED,
          '!opacity-100': message.status === logStatus.FAILED
        }"
      >
        @if (message.isEdited) {
          <span i18n> 已編輯，</span>
        }

        @if (message.createdAt) {
          <span
            [matTooltip]="message.createdAt | moment: 'YYYY/MM/DD HH:mm:ss'"
          >
            {{ message.createdAt | fromNow }}
          </span>
        }

        @if (message.sender) {
          <span i18n> 由 {{ message.sender }} 發送 </span>
        }

        @switch (message.status) {
          @case (logStatus.FAILED) {
            <mat-icon
              class="!w-4 !h-4 !text-xs text-red-600"
              [matTooltip]="message.reason"
            >
              error
            </mat-icon>
          }

          @case (logStatus.SUCCESS) {
            <mat-icon
              class="!w-4 !h-4 !text-xs text-white"
              matTooltip="發送成功"
              i18n-matTooltip="發送成功"
            >
              done
            </mat-icon>
          }

          @case (logStatus.PROCESSING) {
            <mat-spinner
              class="!w-4 !h-4 !text-xs text-gray-500"
              matTooltip="處理中"
              i18n-matTooltip="處理中"
            >
              pending
            </mat-spinner>
          }

          @case (logStatus.SENDING) {
            <mat-spinner
              class="!w-4 !h-4 !text-xs text-gray-500"
              matTooltip="發送中"
              i18n-matTooltip="發送中"
            >
              send
            </mat-spinner>
          }

          @default {
            <span class="inline-block w-5"></span>
          }
        }
      </div>
    }
  </div>
}
