import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    value: string,
    keyword: string,
    color: string = '#000000',
    backgroundColor: string = '#ffff00',
  ): SafeHtml {
    if (!keyword || !value) {
      return value;
    }

    const regex = new RegExp(`(${keyword})`, 'gi');
    const highlightedValue = value.replace(
      regex,
      `<span class="highlight-text" style="color: ${color}; background-color: ${backgroundColor};">$1</span>`,
    );

    return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
  }
}
