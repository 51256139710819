import { Observer } from 'rxjs';
import { Job } from '../util/job';
import { DownloadResponse } from './response';

export class DownloadJob extends Job {
  public arrayBuffers: ArrayBuffer[] = [];
  public fileName: string = '';
  public fileSize: number = 0;
  public downloadedSize: number = 0;
  public fileType: string = '';

  public constructor(
    public path: string,
    public args: any[],
    public observer: Observer<DownloadResponse>,
  ) {
    super(path, args);
  }
}
