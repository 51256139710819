<div
  *ngIf="mode === 'EDIT'; else read"
  contenteditable
  class="textarea edit"
  [ngClass]="{ receive: message.direction === 'Receive' }"
  #textarea
  [innerHTML]="message.content.content | escape"
  (blur)="blur($event)"
  (focus)="focus($event)"
  (mousedown)="mousedown($event)"
  (contextmenu)="stop($event)"
></div>

<ng-template #read>
  <div
    class="textarea"
    [ngClass]="{ receive: message.direction === 'Receive' }"
    #textarea
    [innerHTML]="
      message.content.content
        | nl2br
        | formatTextMessage
        | highlight: message.content.extra.highlightText
    "
  ></div>
</ng-template>

<div
  class="arrow"
  [ngClass]="{ receive: message.direction === 'Receive' }"
></div>
