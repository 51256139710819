// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class CouponRecordModel {
  static updateCouponRecordStatus(
    id: number,
    status: 'used' | 'unused',
  ): Promise<any> {
    return wsc.execute(
      '/ws/coupon-record/updateCouponRecordStatus',
      id,
      status,
    ) as any;
  }

  static updateCouponRecordRemark(id: number, remark: string): Promise<any> {
    return wsc.execute(
      '/ws/coupon-record/updateCouponRecordRemark',
      id,
      remark,
    ) as any;
  }
}
// aa73deabb57a93ac500b1307b059d0528dc5df482dcf8f51b4da93811e7bad41
