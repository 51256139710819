import { InjectionToken } from '@angular/core';
import { CompanyDto } from '@ay-gosu/server-shared';

export interface UTM {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export const COMPANY_SERVICE = new InjectionToken<ICompanyService>(
  'COMPANY_SERVICE',
);

export interface ICompanyService {
  updateCompany$;

  company$;

  createCompany(name: string): Promise<string>;

  getUTM(): UTM;

  _fetch(companyId: number): Promise<CompanyDto>;

  fetchCompaniesPasswordRules(): Promise<{ type: string; args: any }[]>;

  fetchPasswordDefaultRules(): Promise<{ type: string; args: any }[]>;
}
