import { Content, LogStatus } from '@ay/bot';
import { Affecter } from '../affected.component';

export type CreateType = 'click' | 'menu' | 'none';

export abstract class Message<
  CONTENT extends Content.Any = Content.Any,
> extends Affecter {
  public type: string;

  private _content: CONTENT;

  public get content() {
    return this._content;
  }

  public set content(content: CONTENT) {
    this._content = content;
  }

  public createdAt: Date = null;

  public sender: string = null;

  public logId?: number;

  // 發話的 GosuAccountId
  public accountId?: number;

  // 群組中下發話的使用者
  public userProfileId?: number;

  public direction: 'Send' | 'Receive' = 'Send';

  public status: LogStatus;

  public reason: string;

  // 已編輯
  public isEdited = false;

  public abstract toString();

  public toJSON(): CONTENT | Content.Template {
    return this.content;
  }

  public async loadFromContent(
    content: CONTENT | Content.Template,
  ): Promise<void> {
    if (!(content instanceof Content.Template)) {
      this.content = content;
    }
  }
}
