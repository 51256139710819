// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { GosuLoginConfigDto } from './dto/gosu-login-config.dto';

export class GosuLoginModel {
  // #endregion
  static fetchConfig(): Promise<GosuLoginConfigDto> {
    return wsc.execute('/ws/gosu-login/fetchConfig') as any;
  }
}
// 037d4bf77030a0b3229962ae71b175e05224e1231ceb0dd66ae188881ad8ac85
