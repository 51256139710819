// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { AccountCompanyRelationFetchDto } from './dto/fetch.dto';
import { AccountCompanyRelationLevel } from './dto/level.type';

export class AccountCompanyRelationModel {
  static deleteAccountCompanyRelation(accountId: number): Promise<boolean> {
    return wsc.execute(
      '/ws/account-company-relation/deleteAccountCompanyRelation',
      accountId,
    ) as any;
  }

  static addRelationByAccount(
    email: string,
    level: AccountCompanyRelationLevel = '',
  ): Promise<any> {
    return wsc.execute(
      '/ws/account-company-relation/addRelationByAccount',
      email,
      level,
    ) as any;
  }

  static getAccountList(): Promise<AccountCompanyRelationFetchDto[]> {
    return wsc.execute('/ws/account-company-relation/getAccountList') as any;
  }

  static updateRelationByAccount(
    accountId: number,
    level: AccountCompanyRelationLevel = '',
  ): Promise<any> {
    return wsc.execute(
      '/ws/account-company-relation/updateRelationByAccount',
      accountId,
      level,
    ) as any;
  }
}
// 5179cfd30b15fb2dd231b2b48dc4282b41d49cc1a5e8b875c7cd6232ece926e8
