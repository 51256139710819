// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Moment } from 'moment';
import { BroadcastReportDownloadDto } from './dto/broadcast-report-download.dto';
import { FetchBroadcastReportClickDetailDto } from './dto/fetch-broadcast-report-click-detail.dto';
import { FetchBroadcastReportListDto } from './dto/fetch-broadcast-report-list.dto';
import { FetchBroadcastReportOverviewDetailDto } from './dto/fetch-broadcast-report-overview-detail.dto';
import { FetchBroadcastReportOverviewDto } from './dto/fetch-broadcast-report-overview.dto';
import { FetchBroadcastReportReadDetailDto } from './dto/fetch-broadcast-report-read-detail.dto';

export class BroadcastReportModel {
  static fetchList(
    botIds: number[],
    start: string | Moment,
    end: string | Moment,
    page: any = 1,
    pageSize: any = 20,
  ): Promise<FetchBroadcastReportListDto> {
    return wsc.execute(
      '/ws/broadcast-report/fetchList',
      botIds,
      start,
      end,
      page,
      pageSize,
    ) as any;
  }

  static fetchDetail(
    packageId: number,
    start: string | Moment,
    end: string | Moment,
    type: 'Time' | 'Day',
  ): Promise<FetchBroadcastReportReadDetailDto> {
    return wsc.execute(
      '/ws/broadcast-report/fetchDetail',
      packageId,
      start,
      end,
      type,
    ) as any;
  }

  static fetchClickDetail(
    packageId: number,
  ): Promise<FetchBroadcastReportClickDetailDto[]> {
    return wsc.execute(
      '/ws/broadcast-report/fetchClickDetail',
      packageId,
    ) as any;
  }

  static fetchOverview(): Promise<FetchBroadcastReportOverviewDto> {
    return wsc.execute('/ws/broadcast-report/fetchOverview') as any;
  }

  static fetchOverviewDetail(
    botId: number,
    start: string | Moment,
    end: string | Moment,
  ): Promise<FetchBroadcastReportOverviewDetailDto> {
    return wsc.execute(
      '/ws/broadcast-report/fetchOverviewDetail',
      botId,
      start,
      end,
    ) as any;
  }

  static downloadDetail(
    packageId: number,
  ): Promise<BroadcastReportDownloadDto> {
    return wsc.execute('/ws/broadcast-report/downloadDetail', packageId) as any;
  }
}
// 21ac5022e0a10e68c0a0ec8ba8cddfe9d67db68b62834281839e72b2d24a1105
