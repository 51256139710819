import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { FlowModel, NodeColumn } from '@ay-gosu/server-shared';
import { arrayToObject } from '@ay/util';
import { SubscriptionLike } from 'rxjs';

@Pipe({
    name: 'node',
    pure: false,
    standalone: true,
})
export class NodePipe implements PipeTransform, OnDestroy {
  private _latestArg: number | NodeColumn | (number | NodeColumn)[] | null =
    null;

  private _latestValue: any = null;
  private _latestReturnedValue: any = null;
  private _subscription: SubscriptionLike;
  private _length: number;

  public constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    this._dispose();
  }

  public transform(
    arg: NodeColumn | number | (number | NodeColumn)[],
    length: number = Infinity,
  ) {
    this._length = length;

    if (!this._latestArg) {
      if (!(arg instanceof Array)) {
        arg = [arg];
      }
      this._latestArg = arg;
      this._subscribe(arg);
      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (arg.toString() != this._latestArg.toString()) {
      this._dispose();
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  private async _subscribe(args: (number | NodeColumn)[]) {
    let ids = args.filter((arg) => typeof arg === 'number') as number[];
    let hasNumber = ids.length !== 0;

    if (!hasNumber) {
      return this._updateLatestValue(args as NodeColumn[]);
    }

    const nodes = await FlowModel.fetchNodeNames(ids);
    let grouped = arrayToObject(nodes, 'id');

    args = args.map((arg) => {
      if (typeof arg === 'number') return grouped[arg];
      else return arg;
    });

    this._updateLatestValue(args as NodeColumn[]);
  }

  private _dispose(): any {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
    this._latestValue = null;
    this._latestReturnedValue = null;
    this._latestArg = null;
  }

  private _updateLatestValue(nodes: NodeColumn[]) {
    nodes = nodes.filter((node) => node);
    let _latestValue = '';
    let remaining = $localize`⋯⋯，共${nodes.length}個結點`;

    for (let idx = 0; idx < nodes.length; idx++) {
      let node = nodes[idx];

      let needLength = this._length;
      if (idx != nodes.length - 1) {
        needLength -= remaining.length;
      }

      let afterLength = (_latestValue + node.name).length;

      if (afterLength > needLength) {
        if (_latestValue === '') {
          _latestValue = $localize`共${nodes.length}個結點`;
        } else {
          _latestValue += remaining;
        }
        break;
      }

      if (_latestValue === '') {
        _latestValue = node.name;
      } else {
        _latestValue += '、' + node.name;
      }
    }
    this._latestValue = _latestValue;

    this._changeDetectorRef.markForCheck();
  }
}
